/* eslint-disable */
import React from 'react'
import { getComputedValue } from 'common/utils/field'
import ReactHtmlParser from 'react-html-parser'
import { runHandler } from 'common/utils/handlers'
import { includes } from 'lodash'
import ToggleMaskLabel from './ToggleMaskLabel'
import InfoBadge from './InfoBadge'

const condPreventDefaultClick = (e, field) => {
  const { ui } = field
  // Disable default browser behavior for
  // certain components
  if (ui && ui.widget && (ui.widget === 'yesNo' || ui.widget === 'datepicker')) {
    e.preventDefault()
  }
}

// TODO: simple label tokens fn since we only have one token to start with
const applyLabelTokens = (field) => {
  let label = field.top_label || field.label
  if (field.has_tokens) {
    label = label.replace('%location_search%', window.location.search)
  }
  return label
}

const Label = ({
  type, field, fieldData, onChange, managerData, managerActions, state,
}) => {
  let value = field.value || getComputedValue(field, fieldData, managerData)
  let className = ''
  let style = ''
  if (field.type) {
    style = field.type
  }
  if (!type && fieldData[field.id] && fieldData[field.id].errors && fieldData[field.id].errors.length > 0) {
    type = 'error'
  }
  if (field.ui && field.ui.widget === 'label') {
    style = field.ui.widget
    if (field.ui.type) {
      style = field.ui.type
    }
  }
  if (field.options) {
    const opt = field.options.find(option => option.value === value)
    value = (opt ? opt.text : value)
  }

  if (field.ui && !!field.ui.class) {
    if (field.ui.class === 'error') {
      type = 'error'
    } else {
      className = `${className} ${field.ui.class}`
    }
  }

  const isReadonlyField = (field.ui && field.ui.readonly)
  const isAriaNoteField = (field.ui && field.ui.aria_note)
  const isSummaryView = field && field.ui && (field.ui.view_type === 'summary')
  const summaryViewType = field && field.ui && field.ui.summary_view_type
  const isRequiredMarkShown = (fieldData[field.id]
    && includes(fieldData[field.id].errors, 'required')) || (field.ui && field.ui.show_required)

  const classes = type
  if (!field.label && !isSummaryView) {
    return null
  }
  if (field.ui && field.ui.show_label === false) {
    return null
  }

  let labelValue = ''
  if (field.label_handler) {
    labelValue = runHandler({
      handler: field.label_handler.handler,
      fieldData,
      managerData,
      managerActions,
      state,
      field,
    })
  } else {
    labelValue = ReactHtmlParser(applyLabelTokens(field))
  }
  let superScriptLabel = ''
  let superScriptRefLabel = ''
  let superScriptAriaLabel = ''
  let superScriptClickHandler = () => {}
  if (field && field.ui && field.ui.super_script) {
    superScriptLabel = field.ui.super_script.label
    superScriptAriaLabel = field.ui.super_script.aria_label
    if (field.ui.super_script.on_click && field.ui.super_script.on_click.handler) {
      superScriptClickHandler = (e) => {
        runHandler({
          handler: field.ui.super_script.on_click.handler,
          fieldData,
          managerData,
          field,
          state,
        })
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }
  if (field && field.ui && field.ui.super_script_ref) {
    superScriptRefLabel = field.ui.super_script_ref.label
  }
  return (
    <div className={className}>
      { ((style !== 'title' && style !== 'subTitle' && style !== 'percent' && style !== 'currency')
        && (!field.ui || (field.ui && !field.ui.hide_label))) && !isAriaNoteField
        && (
        <label
          className={classes}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          htmlFor={field.id}
          onClick={e => condPreventDefaultClick(e, field)}
        >
          {(superScriptRefLabel) && <span className='field-superscript'>{superScriptRefLabel}</span>}
          {labelValue}
          {' '}
          {(field.required || isRequiredMarkShown)
          && <span className='field-required'>*</span>}
          {' '}
          {(superScriptLabel)
          && (
          <a
            href='#'
            aria-label={superScriptAriaLabel}
            className='field-superscript'
            onClick={e => superScriptClickHandler(e)}
          >
            {superScriptLabel}
          </a>
          )}
          {!!field.helptext
          && <InfoBadge helpText={field.helptext} field={field} fieldData={fieldData} onChange={onChange} />}
        </label>
        )
      }
      { ((style === 'title' || style === 'subTitle' || style === 'percent' || style === 'currency')
        && (!field.ui || (field.ui && !field.ui.hide_label))) && !isAriaNoteField && !summaryViewType
        && (
        <h4
          className={classes}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          htmlFor={field.id}
          onClick={e => condPreventDefaultClick(e, field)}
        >
          {(superScriptRefLabel) && <span className='field-superscript'>{superScriptRefLabel}</span>}
          {labelValue}
          {' '}
          {(field.required || isRequiredMarkShown)
          && <span className='field-required'>*</span>}
          {' '}
          {(superScriptLabel)
          && (
          <a
            href='#'
            aria-label={superScriptAriaLabel}
            className='field-superscript'
            onClick={e => superScriptClickHandler(e)}
          >
            {superScriptLabel}
          </a>
          )}
          {!!field.helptext
          && <InfoBadge helpText={field.helptext} field={field} fieldData={fieldData} onChange={onChange} />}
        </h4>
        )
      }
      { ((style === 'title') && summaryViewType)
        && (
        <h4
          className={classes}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          htmlFor={field.id}
        >
          {
            <span>
              <a href='#' aria-label={labelValue} onClick={e => summaryViewType.walkTo(summaryViewType.screen, e)}>
                {labelValue}
                {' '}

              </a>
              <span>
                <img className='ic_error' src='./img/ic-error.svg' alt={summaryViewType.errorText} />
              </span>
              <p>
                <span className='error-label'>
                  {summaryViewType.errorText}
                  {' '}
                </span>
              </p>
            </span>
          }
        </h4>
        )
      }
      { field.ui
        && field.ui.widget === 'label' && !field.ui.hide_value
        && !!value
        && <div dangerouslySetInnerHTML={{ __html: value }} />
      }
      { field.ui
        && field.ui.widget === 'label' && !!field.ui.hide_value
        && !!value && field.ui.original && !!field.ui.original.enable_unmask
          && <ToggleMaskLabel field={field} value={value} />
      }
      { field.ui
        && field.ui.widget === 'label' && !!field.ui.hide_value
        && !!value && field.ui.original && !field.ui.original.enable_unmask
        && <div dangerouslySetInnerHTML={{ __html: field.ui.mask_value }} />
      }
      {field.ui && field.ui.widget === 'label' && field.type !== 'null'
        && !isReadonlyField && !value && <div className='empty' />}
      {field.ui && (field.ui.widget === 'label' || field.label) && isAriaNoteField
        && <p className={classes} style={{ display: 'inline-block', verticalAlign: 'middle' }}>{labelValue}</p>}
    </div>
  )
}

export default Label
