import React from 'react'
import {
  runHandlerClick,
} from 'common/utils/handlers'
import DSButton from '@elliemae/ds-basic/Button'
import Label from './Label'

const Button = ({
  type = 'button',
  field,
  field: {
    id = '',
    label = '',
    disabled = false,
    container_props = {},
    ui: {
      button_type = 'primary',
      size = 'm',
      fluid_width = false,
    },
  },
  managerActions,
  fieldActions,
  fieldData,
  managerData,
  listInfo,
  state,
}) => {
  let clickHandler = () => {}
  if (field.on_click) {
    clickHandler = () => runHandlerClick(field, managerActions, fieldActions, fieldData, listInfo, managerData, state)
  }
  const classes = `${field.ui.class}`

  return (
    <React.Fragment>
      {field.top_label
        && (
        <Label
          field={field}
          fieldData={fieldData}
          managerData={managerData}
        />
        )}
      <DSButton
        additionalcssclass={classes}
        buttonType={button_type}
        containerProps={{
          id,
          ...container_props,
        }}
        disabled={disabled}
        fluidWidth={fluid_width}
        id={id}
        labelText={label}
        onClick={clickHandler}
        size={size}
        type={type}
      />
    </React.Fragment>
  )
}

export default Button
