/* eslint-disable max-lines */
import React from 'react'
import classNames from 'classnames'
import ScreenView from 'containers/ScreenContainer'
import { getValue } from 'common/utils/field'
import { runHandlerClick } from 'common/utils/handlers'
import Modal from '../fields/Modal'

const titleHandlers = {
  assetImportTitle: (field, title, fieldData, listInfo, id) => {
    if (fieldData[id] && fieldData[id].isImported === true) {
      return `Imported: ${title}`
    }
    return title
  },
}
const buttonHandlers = {
  fromAssetImport: (field, buttonOpts, fieldData, listInfo, id) => {
    if (fieldData[id] && fieldData[id].isImported === true) {
      return {
        clear: false,
        remove: true,
      }
    }
    return buttonOpts
  },
}

class ScreenGroupAccordionView extends React.Component {
  constructor(props) {
    super()
    const open = Array(props.size).fill(false)
    open[0] = true
    this.state = { open }
  }
  getButtons(buttonOpts, listInfo, screenGroup) {
    if (buttonOpts.handler) {
      const { fieldData } = this.props
      const id = `${buttonOpts.id}-list-${listInfo.id}-index-${listInfo.index}`
      const field = fieldData.definitions[buttonOpts.id]
      buttonOpts = buttonHandlers[buttonOpts.handler](field, buttonOpts, fieldData, listInfo, id)
    }

    return (
      <span className='pull-right'>
        {buttonOpts.clear && (
        <button onClick={e => this.confirmClearListItem(e, screenGroup, listInfo)} type='button'>
Clear
        </button>
        )}
        {(listInfo.index >= listInfo.min || !listInfo.min)
          && (
          <span>
            {buttonOpts.remove
            && <button onClick={e => this.confirmRemoveListItem(e, screenGroup, listInfo)} type='button'>Remove</button>}
          </span>
          )
        }
      </span>
    )
  }
  getTitle(titleOpts, listInfo) {
    const { fieldData } = this.props
    const id = `${titleOpts.id}-list-${listInfo.id}-index-${listInfo.index}`
    let title = getValue(fieldData, id)
    const field = fieldData.definitions[titleOpts.id]

    if (field.options) {
      const opt = field.options.find(option => option.value === title)
      title = (opt ? opt.text : title)
    }

    if (titleOpts.handler) {
      title = titleHandlers[titleOpts.handler](field, title, fieldData, listInfo, id)
    }

    if (titleOpts.id && title) {
      return title
    }
    return titleOpts.default.replace('%num%', listInfo.index + 1)
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { size } = this.props
    if (nextProps.size > size) {
      const { open } = this.state
      open[nextProps.size - 1] = true
      this.setState({ open })
    }
  }
  clearListItem(screenGroup, listInfo) {
    const {
      managerActions,
      fieldActions,
      fieldData,
    } = this.props
    screenGroup.on_click = {
      handler: 'clearListItem',
    }
    runHandlerClick(
      screenGroup,
      managerActions,
      fieldActions,
      fieldData,
      listInfo)
  }
  showModal() {
    const { clearModal } = this.state
    return clearModal
  }
  confirmClearListItem(e, screenGroup, listInfo) {
    e.stopPropagation()
    this.setState({
      clearModal: true,
      modalType: 'clearListElement',
      currentScreenGroup: screenGroup,
      currentListInfo: listInfo,
    })
  }
  confirmRemoveListItem(e, screenGroup, listInfo) {
    e.stopPropagation()
    this.setState({
      clearModal: true,
      modalType: 'removeListElement',
      currentScreenGroup: screenGroup,
      currentListInfo: listInfo,
    })
  }
  removeListItem(screenGroup, listInfo) {
    const {
      managerActions,
      fieldActions,
      fieldData,
    } = this.props
    screenGroup.on_click = {
      handler: 'removeListItem',
    }
    runHandlerClick(
      screenGroup,
      managerActions,
      fieldActions,
      fieldData,
      listInfo)
  }
  toggle(idx) {
    const { open: aux } = this.state
    aux[idx] = !aux[idx]
    this.setState({ open: aux })
  }
  isOpen(idx) {
    const { open } = this.state
    return open[idx]
  }
  render() {
    const {
      screen,
      size,
      sectionData,
      screenGroups,
      componentStructure,
      managerData,
      fieldData,
      state,
      pos,
    } = this.props
    const {
      currentScreenGroup,
      currentListInfo,
      modalType,
    } = this.state

    const listInfo = {
      ...screen.list,
      size,
      id: sectionData.id,
    }
    const [firstScreen] = screen.screens
    componentStructure[pos] = firstScreen

    const HeaderView = (
      <ScreenView
        componentStructure={componentStructure}
        fieldData={fieldData}
        listInfo={listInfo}
        managerData={managerData}
        pos={pos + 1}
        state={state}
      />
    )
    componentStructure[pos] = screen.screens[screen.screens.length - 1]
    const FooterView = (
      <ScreenView
        componentStructure={componentStructure}
        fieldData={fieldData}
        listInfo={listInfo}
        managerData={managerData}
        pos={pos + 1}
        state={state}
      />
    )
    const { options } = sectionData.ui

    return (
      <div>
        {HeaderView}
        {screenGroups.map((clonedScreenGroup, idx) => {
          const listInformation = {
            ...screen.list,
            size,
            index: idx,
            isLast: ((size - 1) === idx),
            isFirst: idx === 0,
            id: sectionData.id,
          }
          const title = this.getTitle(options.title, listInformation)
          const buttons = this.getButtons(options.buttons, listInformation, clonedScreenGroup)
          const isAccordionOpen = this.isOpen(idx)
          const toggleClass = classNames({
            expand: !isAccordionOpen,
            collapse: isAccordionOpen,
          })
          const toggle = (<span className={toggleClass}>&nbsp;</span>)
          return (
            <div key={idx} className='grid-x grid-margin-x'>
              <div className='cell'>
                <div
                  className='grid-x grid-margin-x'
                  onClick={() => { this.toggle(idx) }}
                  onKeyDown={() => { this.toggle(idx) }}
                  role='menuitem'
                  tabIndex='0'
                >
                  <div className='cell small-12 medium-8'>
                    {toggle}
                    {' '}
                    {title}
                  </div>
                  <div className='cell small-12" medium-4'>{buttons}</div>
                </div>
                {this.isOpen(idx)
                  && clonedScreenGroup.map((screenX, idX) => {
                    if (idX === 0 || idX === (clonedScreenGroup.length - 1)) {
                      return null
                    }
                    const key = `${screenX.id}-${idx}`
                    componentStructure[pos] = screenX
                    return (
                      <ScreenView
                        key={key}
                        componentStructure={componentStructure}
                        fieldData={fieldData}
                        listInformation={listInformation}
                        managerData={managerData}
                        pos={pos + 1}
                        state={state}
                      />
                    )
                  })
                }
              </div>
            </div>
          )
        })}
        {this.showModal()
          && (
          <Modal
            cancel={() => this.setState({ clearModal: false })}
            clear={() => {
              this.clearListItem(currentScreenGroup, currentListInfo)
              this.setState({ clearModal: false })
            }}
            dialog={modalType}
            isOpen
            remove={() => {
              this.removeListItem(currentScreenGroup, currentListInfo)
              this.setState({ clearModal: false })
            }}
            removeType={options.type || 'default'}
            title={this.getTitle(options.title, currentListInfo)}
          />
          )
        }
        {FooterView}
      </div>
    )
  }
}

export default ScreenGroupAccordionView
