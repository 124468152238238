import { addHandlers } from 'common/utils/handlers'
import { addComponents } from 'common/utils/handlers/components'
import { addModules } from 'common/utils/handlers/modules'
import { addMessages } from 'common/utils/handlers/messages'
import { addValidators } from 'common/utils/handlers/validators'
import { addIcons } from 'common/utils/handlers/icons'

class RegistrationService {
  add({
    handlers,
    messages,
    validators,
    components,
    modules,
    icons,
  }) {
    if (handlers) addHandlers(handlers)
    if (messages) addMessages(messages)
    if (validators) addValidators(validators)
    if (components) addComponents(components)
    if (modules) addModules(modules)
    if (icons) addIcons(icons)
  }
}

export default new RegistrationService()
