import { runHandlerVisibleCheck } from 'common/utils/handlers'
import { getBaseListInfo } from 'common/utils/list'

export const isEmptyScreen = (screen, state) => {
  let isEmpty = true
  const managerData = state.manager
  const fieldData = state.field
  if (screen.ui && screen.ui.no_skip_empty === true) {
    return false
  }

  const traverse = (obj, listInfo) => {
    if (isEmpty === false) {
      return
    }

    if (!listInfo && obj.list) {
      listInfo = getBaseListInfo(obj, managerData, fieldData)
    }
    if (obj.type === 'field_group' || obj.type === 'screen') {
      if (
        obj.visible === false
        || !runHandlerVisibleCheck(obj, managerData, fieldData, listInfo, state)
      ) {
        return
      }
      Object.values(obj.fields).forEach(field => traverse(field, listInfo))
    } else if (obj.type === 'screen_group') {
      if (obj.visible === false
        || !runHandlerVisibleCheck(obj, managerData, fieldData, listInfo, state)
      ) {
        return
      }
      Object.values(obj.screens).forEach(field => traverse(field, listInfo))
    } else if (
      obj.visible !== false
        && runHandlerVisibleCheck(obj, managerData, fieldData, listInfo, state)
    ) {
      isEmpty = false
    }
  }
  traverse(screen)
  return isEmpty
}
