/* eslint-disable import/no-cycle, max-lines */
// import moment from 'moment'
import { conformToMask } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { getCellId } from 'common/utils/list'
import { runHandlerVisibleCheck, runHandlerValue, runDecoratorValue } from 'common/utils/handlers'
import ManagerService from 'common/services/ManagerService'

// Custom func to add extra zero on decimal if needed
export const getCurrencyMask = (rawValue, integerLimit) => {
  const numberMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    requireDecimal: false,
    integerLimit,
  })
  const mask = numberMask(rawValue)
  if (/\.[0-9]{1}$/.test(rawValue)) {
    mask.push('0')
  }

  return mask
}

export const getCurrencyMaskWrapper = (integerLimit) => {
  return rawValue => getCurrencyMask(rawValue, integerLimit)
}

const masks = {
  currency: (v, readOnly) => {
    const mask = readOnly ? getCurrencyMask(v.toString(), null).filter(a => a !== '[]')
      : getCurrencyMask(v.toString(), 9).filter(b => b !== '[]')
    return conformToMask(
      v.toString(),
      mask,
      { guide: false },
    )
  },
  percent: v => conformToMask(
    v.toString(),
    createNumberMask({
      prefix: '',
      suffix: '%',
      allowDecimal: true,
      integerLimit: 3,
    })(v.toString()).filter(c => c !== '[]'),
    { guide: false },
  ),
}

export const getFieldListId = id => id.match(/-list-([a-zA-Z0-9-]+)-index-[0-9]+$/)[1]

export const getFieldIndex = id => id.match(/-list-[a-zA-Z0-9-]+-index-([0-9]+)$/)[1]

export const getFullId = (fromId, id) => {
  if (/-list-/.test(fromId)) {
    const index = getFieldIndex(fromId)
    const listId = getFieldListId(fromId)
    return `${id}-list-${listId}-index-${index}`
  }
  return id
}

export const getFieldFullId = (field, id) => getFullId(field.id, id)

export const getFieldId = id => id.replace(/-list-[a-zA-Z0-9-]+-index-[0-9]+$/, '')

export const isSet = (fieldData, id) => {
  return !!fieldData[id] && fieldData[id].value !== undefined
}

export const isFieldCreated = (fieldData, id) => {
  return !!fieldData[id]
}

export const getValue = (fieldData, id) => {
  const fieldId = getFieldId(id)
  const definition = fieldData.definitions && fieldData.definitions[fieldId]
  if (isSet(fieldData, id)) {
    return fieldData[id].value
  } if (definition && typeof definition.default !== 'undefined') {
    let defaultValue = definition.default
    if (Array.isArray(defaultValue) && /-list-/.test(id)) {
      const index = getFieldIndex(id)
      defaultValue = defaultValue[index]
      if (!defaultValue) {
        return ''
      }
    }
    return defaultValue
  }
  return ''
}

export const notEmptyString = (fieldData, id) => isSet(fieldData, id) && getValue(fieldData, id) !== ''

export const getVisibleFields = (fields, managerData, fieldData, listInfo, state) => {
  return fields.filter((field) => {
    if (!field.visible) {
      return false
    } if (!runHandlerVisibleCheck(field, managerData, fieldData, listInfo, state)) {
      return false
    }
    return true
  })
}

const calculateColumnsVariables = (fieldSize, fields) => {
  let numUnspecified = 0
  let totalSize = 12
  let customFieldCount = 0
  let avgSize = 4
  // eslint-disable-next-line no-loops/no-loops
  for (let i = 0; i < fieldSize; i += 1) {
    if (fields[i].addAfter && !(fields[i].ui && fields[i].ui.col_size)) {
      customFieldCount += 1
    }
    if (!(fields[i].ui && fields[i].ui.col_size)) {
      numUnspecified += 1
    } else {
      totalSize -= fields[i].ui.col_size
    }
  }
  totalSize = totalSize === 0 ? 12 : totalSize
  avgSize = (totalSize) / (numUnspecified - customFieldCount)
  const remainder = ((numUnspecified * avgSize) % 12) / avgSize
  return { remainder, totalSize, avgSize }
}

const getColumnClasses = (fieldSize, fields, remainder, totalSize, avgSize) => {
  const columnClasses = []
  // eslint-disable-next-line no-loops/no-loops
  for (let i = 0; i < fieldSize; i += 1) {
    if (fields[i].ui && fields[i].ui.col_size) {
      const fieldWidth = fields[i].ui.col_size
      columnClasses.push(`cell small-12 medium-${fieldWidth} large-${fieldWidth}`)
    } else {
      if (remainder > 0 && (i === fieldSize - remainder)) {
        totalSize = 12
        avgSize = totalSize / remainder
      }
      columnClasses.push(`cell small-12 medium-${avgSize} large-${avgSize}`)
    }
  }
  return columnClasses
}

export const computeFieldCols = (fields, parentField) => {
  /**
   * For example, for a number of 4 fields, the first one has col_size = 6 and the other three undefined,
   * then, the first field should have a size of 6 and the other three should have a size of
   * (12-6)/3=2
   * If not divisible, leave space of fileds as even as possible.
   * Assumptions: all field size added up no more than 12!
   */
  const fieldSize = fields.length

  if (fieldSize === 0) {
    return []
  }
  if (fieldSize === 1 && (!fields[0].ui || (fields[0].ui && !fields[0].ui.col_size))) {
    return ['cell']
  }
  if (parentField && parentField.ui && parentField.ui.fields_col_size) {
    return Array(fieldSize).fill('cell')
  }

  const {
    remainder,
    totalSize,
    avgSize,
  } = calculateColumnsVariables(fieldSize, fields)

  return getColumnClasses(fieldSize, fields, remainder, totalSize, avgSize)
}

export const getComputedValue = (field, fieldData, managerData) => {
  let value
  if (field.value_handler) {
    value = runHandlerValue(field, fieldData, managerData)
  } else {
    value = getValue(fieldData, field.id)
  }
  // Mask value if requested
  if (field.ui && field.ui.type && masks[field.ui.type]) {
    const readOnly = field.ui && field.ui.readonly
    value = masks[field.ui.type](value, readOnly).conformedValue
  }
  if (typeof value === 'boolean') {
    value = (value ? 'Yes' : 'No')
  }
  if (field.ui && field.ui.decorator) {
    value = runDecoratorValue(field, fieldData)
  }
  return value
}

export const getLabel = (fieldData, id) => {
  const fieldId = getFieldId(id)
  return fieldData.definitions[fieldId] ? fieldData.definitions[fieldId].label : ''
}

export const createDataGridInitialPayload = ({ rows, columns }) => {
  let payload = {}
  columns.forEach((column, columnIndex) => {
    const {
      customField,
      property,
    } = column
    if (customField) {
      rows.forEach((row, rowIndex) => {
        const id = getCellId(customField, { rowIndex, columnIndex })
        payload = {
          ...payload,
          [id]: {
            id,
            value: row[property],
          },
        }
      })
    }
  })
  return payload
}

export const getFieldsWithDefault = () => {
  const fieldsWithDefault = ManagerService.getFieldsWithDefault()
  const res = {}
  fieldsWithDefault.forEach((fieldWithDefault) => {
    const defVal = fieldWithDefault.default
    if (defVal !== undefined && defVal !== null) {
      if (Array.isArray(defVal)) {
        defVal.forEach((dv, dvIndex) => {
          if (dv !== undefined && dv !== null) {
            const fullId = `${fieldWithDefault.id}-list-${fieldWithDefault.listId}-index-${dvIndex}`
            res[fullId] = dv
          }
        })
      } else {
        res[fieldWithDefault.id] = defVal
      }
    }
  })
  return res
}
