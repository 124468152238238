function defaultNamespaceResolver(action) {
  return (action.meta && action.meta.namespace) || undefined
}

export default function namespaceable(reducer, namespaceResolver = defaultNamespaceResolver) {
  return function (state, action) {
    const namespace = namespaceResolver(action)
    if (!namespace) {
      return reducer(state, action)
    }
    const instanceState = reducer(state[namespace], action)
    const newState = Object.assign({}, state, { [namespace]: instanceState })
    return newState
  }
}
