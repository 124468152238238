const { authentication } = window.emAppConfig
const TOKEN_KEY = authentication.tokenKey

export const isActiveSession = () => {
  const tokenKey = TOKEN_KEY
  const session = JSON.parse(sessionStorage.getItem(tokenKey))
  if (session !== null && typeof session === 'object') {
    return !session.guest
  }
  return false
}
