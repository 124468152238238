/* eslint max-statements: ["error", 15, { "ignoreTopLevelFunctions": true }]*/
import React from 'react'
import { fill, cloneDeep } from 'lodash'
import { getModule } from 'common/utils/handlers/modules'
import { getListSize } from 'common/utils/list'
import ScreenView from 'containers/ScreenContainer'
import WidgetView from 'containers/WidgetContainer'
import ScreenGroupAccordionView from './ScreenGroupAccordionView'


const ScreenGroupView = ({
  pos, componentStructure, managerData, fieldData, state,
}) => {
  pos = +pos + 1
  const sectionData = componentStructure[pos - 1]

  let TheView
  let widgetType = 'screen'
  if (sectionData.ui && sectionData.ui.widget) {
    widgetType = sectionData.ui.widget
    TheView = getModule(widgetType)
  }

  if (sectionData.list) {
    const screen = sectionData
    const size = getListSize({ screen, managerData })
    const screenGroups = fill(Array(size), cloneDeep(sectionData.screens))
    switch (widgetType.toLowerCase()) {
      case 'accordion':
        TheView = (
          <WidgetView
            componentStructure={componentStructure}
            fieldData={fieldData}
            managerData={managerData}
            pos={pos}
            screen={screen}
            screenGroups={screenGroups}
            sectionData={sectionData}
            size={size}
            state={state}
            TheView={ScreenGroupAccordionView}
          />
        )
        break
      default:
        TheView = (
          <div>
            {screenGroups.map((clonedScreenGroup, idx) => {
              const listInfo = {
                ...screen.list,
                size,
                index: idx,
                isLast: ((size - 1) === idx),
                isFirst: idx === 0,
                id: sectionData.id,
              }
              return (
                clonedScreenGroup.map((scrn, index) => { // scrn - screen
                  const key = `${scrn.id}-${index}`
                  componentStructure[pos] = scrn
                  return (
                    <ScreenView
                      key={key}
                      componentStructure={componentStructure}
                      fieldData={fieldData}
                      listInfo={listInfo}
                      managerData={managerData}
                      pos={pos + 1}
                      state={state}
                    />
                  )
                })
              )
            })}
          </div>
        )
        break
    }
    return (TheView)
  }

  if (TheView) {
    TheView = (
      <TheView
        fieldData={fieldData}
        managerData={managerData}
        pos={pos}
        sectionData={sectionData}
        state={state}
      />
    )
  } else {
    TheView = (
      <div>
        {sectionData.screens.map((screen, idx) => {
          const key = `${screen.id}-${idx}`
          componentStructure[pos] = screen
          if (screen.type === 'screen_group') {
            return (
              <ScreenGroupView
                key={key}
                componentStructure={componentStructure}
                fieldData={fieldData}
                managerData={managerData}
                pos={pos + 1}
                state={state}
              />
            )
          }
          return (
            <ScreenView
              key={key}
              componentStructure={componentStructure}
              pos={pos + 1}
            />
          )
        })}
      </div>
    )
  }

  return (TheView)
}

export default ScreenGroupView
