import React from 'react'
import InfoCircle from '@elliemae/ds-icons/InfoCircle'
import { getIcon } from 'common/utils/handlers/icons'
import { DSIconSizes, DSIconColors } from '@elliemae/ds-basic/Icon'

const Icon = ({ field }) => {
  const {
    color = '',
    icon = '',
    size = '',
  } = field.ui
  const finalColor = DSIconColors[color] || DSIconColors.NEUTRAL
  const finalSize = DSIconSizes[size] || DSIconSizes.M
  const props = { color: finalColor, size: finalSize }
  const DSIcon = getIcon(icon)
  return DSIcon ? <DSIcon {...props} /> : <InfoCircle {...props} />
}

export default Icon
