class GoogleAnalyticsPlugin {
  constructor() {
    this.ga = this.fakeGa
    this.opts = {}
    this.actionsQueue = []
    this.checkGaIsLoadedInterval = setInterval(
      () => {
        this.checkGaIsLoaded()
      }, 30)
  }
  checkGaIsLoaded() {
    if (window.ga) {
      this.actionsQueue.forEach((a) => {
        if (a[2]) {
          window.ga(a[0], a[1], a[2])
        } else {
          window.ga(a[0], a[1])
        }
      })
      this.actionsQueue = []
    }
  }
  fakeGa(action, p2, p3) {
    this.actionsQueue.push([action, p2, p3])
  }
  init(opts) {
    const { id } = opts
    if (!id) {
      console.error('Google Analytics ID is not valid!') // eslint-disable-line no-console
      return
    }
    this.ga('create', id, 'auto', { siteSpeedSampleRate: 100 })
  }
  pageLoad(payload) {
    const { pageName } = payload
    this.ga('set', 'page', window.location.pathname)
    this.ga('set', 'title', pageName)
    this.ga('send', 'pageview')
  }
  apiError(payload) {
    this.event({
      type: 'api',
      action: 'success',
      message: payload,
    })
  }
  apiSuccess(payload) {
    this.event({
      type: 'api',
      action: 'success',
      message: payload,
    })
  }
  apiWhitelist(payload) {
    this.event({
      type: 'api',
      action: 'success',
      message: payload,
    })
  }
  log(payload) {
    this.event(payload)
  }
  event(payload) {
    const hitType = 'event'
    const { type: eventCategory, action: eventAction, message: eventLabel } = payload
    this.ga('send', {
      hitType,
      eventCategory,
      eventAction,
      eventLabel: JSON.stringify(eventLabel),
    })
  }
}

export default new GoogleAnalyticsPlugin()
