import { findIndex, find } from 'lodash'

const getState = (parts, short) => (
  (findIndex(parts, { type: 'administrative_area_level_1' }) > -1)
    ? find(parts, { type: 'administrative_area_level_1' })[short]
    : ''
)

const getLine1 = (parts, long) => {
  const line1 = find(parts, { type: 'route' })[long]
  return (findIndex(parts, { type: 'street_number' }) > -1)
    ? `${find(parts, { type: 'street_number' })[long]} ${line1}`
    : line1
}

const getZip = (parts, long) => {
  let zip = ''
  if (findIndex(parts, { type: 'postal_code' }) > -1) {
    zip += find(parts, { type: 'postal_code' })[long]
  }
  if (findIndex(parts, { type: 'postal_code_suffix' }) > -1) {
    zip += `-${find(parts, { type: 'postal_code_suffix' })[long]}`
  }
  return zip
}

const getCity = (parts, long) => {
  let city = ''
  if (findIndex(parts, { type: 'locality' }) > -1) {
    city = find(parts, { type: 'locality' })[long]
  } else if (findIndex(parts, { type: 'sublocality_level_1' }) > -1) {
    city = find(parts, { type: 'sublocality_level_1' })[long]
  }
  return city
}

const getPlace = (parts, long, short) => {
  const place = {}
  place.state = getState(parts, short)
  place.line1 = getLine1(parts, long)
  place.zip = getZip(parts, long)
  place.city = getCity(parts, long)
  return place
}

export default (placeData) => {
  const tranformTypes = data => data.map(item => item.types[0])

  const long = 'long_name'
  const short = 'short_name'
  const place = {}

  // Fallback in case address_components is missing
  if (!placeData.address_components) {
    const nameParts = placeData.name.split(', ')
    place.state = (nameParts[2] ? nameParts[2] : '')
    place.line1 = (nameParts[0] ? nameParts[0] : '')
    place.zip = ''
    place.city = (nameParts[1] ? nameParts[1] : '')
    return place
  }

  const parts = tranformTypes(placeData.address_components)
  return getPlace(parts, long, short)
}
