import React from 'react'
import { fill } from 'lodash'
import { getListSize } from 'common/utils/list'
import Field from '../fields/Field'

const ScreenList = ({
  screen, managerActions, fieldActions, managerData, fieldData, state,
}) => {
  const size = getListSize({ screen, managerData })
  const screens = fill(Array(size), { ...screen })

  return (
    <div>
      {screens.map((clonedScreen, idx) => {
        const listInfo = {
          ...screen.list,
          size,
          index: idx,
          isLast: ((size - 1) === idx),
          isFirst: idx === 0,
          id: screen.id,
        }
        return (
          <div key={idx}>
            {clonedScreen.fields.map((field, idX) => {
              return (
                <Field
                  key={idX}
                  field={field}
                  fieldActions={fieldActions}
                  fieldData={fieldData}
                  listInfo={listInfo}
                  managerActions={managerActions}
                  managerData={managerData}
                  state={state}
                />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default ScreenList
