import React from 'react'
import { Route as ReactRouterRoute } from 'react-router-dom'
import * as brum from '@elliemae/pui-user-monitoring'

export const Route = ({
  component: Component, pageTitle, render, ...rest
}) => (
  <ReactRouterRoute
    {...rest}
    render={(routeProps) => {
      try {
        if (brum && pageTitle) {
          brum.setCustomVirtualPageName(pageTitle)
          brum.startVirtualPageMonitoringWithAutoEnd(pageTitle)
        }
      } catch {
        // eslint-disable-next-line no-empty
      }
      return render ? render(routeProps) : <Component {...routeProps} />
    }}
  />
)
