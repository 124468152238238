import React from 'react'

const SectionView = ({ pos, componentStructure }) => {
  pos = +pos + 1
  const EmptyDiv = <div />
  const TheView = (componentStructure[pos] ? componentStructure[pos].component : () => EmptyDiv)

  return (
    <TheView componentStructure={componentStructure} pos={pos} />
  )
}

export default SectionView
