import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps';
import { getValue } from 'common/utils/field';
import { getErrorMessages } from 'common/utils/handlers/messages';
import { runHandlerValue } from 'common/utils/handlers';
import DSButton from '@elliemae/ds-basic/Button';
import Comments2 from '@elliemae/ds-icons/Comments2';
import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem';
import DSTextBox from '@elliemae/ds-basic/form/TextBox';
import DSInputMask from '@elliemae/ds-basic/form/InputMask';

const ConversationLog = ({
  field,
  fieldActions,
  fieldData,
  onBlur,
  onChange,
  onFocus,
  managerActions,
  listInfo,
}) => {
  const propValue = field.value_handler
    ? runHandlerValue(field, fieldData)
    : getValue(fieldData, field.id);
  const [value, setValue] = useDerivedStateFromProps(propValue);
  const {
    ui = {}, disabled = false, label = '', required = false } = field;
  const actualField = fieldData[field.id] || {};

  const { autoFocus } = ui;
  const name = ui.name || field.id;
  const errors = actualField.errors || [];
  const feedbackMessage = field.instructionText || '';
  const contacttype = field.conversationLogContactMethod;
  const fireHandler = useCallback(
    debounce((handler, newValue, isBlur) => {
      return handler({
        id: field.id,
        value: newValue,
        field,
        fieldActions,
        managerActions,
        listInfo,
        isBlur,
      });
    }, 300),
    [],
  );

  const onChangeInput = event => {
    const newValue = event.target.value;
    if (field.pattern) {
      const pattern = new RegExp(`^${field.pattern}*$`, 'g');
      if (newValue !== '' && !pattern.test(newValue)) {
        event.preventDefault();
        return;
      }
    }
    if (field.regex) {
      const regex = new RegExp(`${field.regex}`, 'g');
      if (newValue !== '' && !regex.test(newValue)) {
        event.preventDefault();
        return;
      }
    }
    setValue(newValue);

    fireHandler(onChange, newValue, true);
  };

  const onBlurInput = event => {
    const newValue = event.target.value;
    fireHandler(onBlur, newValue);
  };

  const onFocusInput = event => {
    const newValue = event.target.value;
    fireHandler(onFocus, newValue);
  };

  let inputComponent;
  let mask;
  if (contacttype !== 'Phone') {
    inputComponent = (
      <DSTextBox
        disabled={disabled}
        rightComponent={
          <DSButton
            disabled={disabled}
            buttonType="secondary"
            icon={<Comments2 />}
            iconsize="m"
          />
        }
      />
    );
  } else {
    mask = [
      /[1-9]/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
    inputComponent = (
      <DSInputMask
        mask={mask}
        disabled={disabled}
        rightComponent={
          <DSButton
            disabled={disabled}
            buttonType="secondary"
            icon={<Comments2 />}
            iconsize="m"
          />
        }
      />
    );
  }

  return (
    <DSFormItemLayout
      autoFocus={autoFocus}
      feedbackMessage={feedbackMessage}
      floatingLabel
      hasError={errors.length > 0}
      inputComponent={inputComponent}
      labelText={label}
      name={name}
      disabled={disabled}
      onBlur={onBlurInput}
      onChange={onChangeInput}
      onFocus={onFocusInput}
      onKeyUp={onChangeInput}
      required={required}
      mask={mask}
      validationMessage={getErrorMessages(field, errors, managerActions)}
      value={value}
    />
  );
};

export default ConversationLog;
