import React from 'react'
import { getComponent } from 'common/utils/handlers/components'

const ModalWrapper = ({ ...rest }) => {
  const Modal = getComponent('Modal')
  if (Modal) {
    return (<Modal {...rest} />)
  }
  return null
}

export default ModalWrapper
