/* eslint-disable max-lines */
/* eslint no-unused-expressions: [2, { allowShortCircuit: true }]*/

import { trim, isUndefined, isEqual } from 'lodash'
import moment from 'moment'
import { patterns } from '../patterns'
import { getFullId, getValue } from '../field'

const validationHandlers = {
  current_year: () => (new Date()).getFullYear(),
}

const validDateDiff = (startDate, endDate, handlePastDate) => {
  const splitStartDate = startDate.split('/')
  const comparisonstartDate = new Date(splitStartDate[2], splitStartDate[0] - 1, splitStartDate[1])
  const splitEndDate = endDate.split('/')
  const comparisonEndDate = new Date(splitEndDate[2], splitEndDate[0] - 1, splitEndDate[1])

  return handlePastDate ? comparisonstartDate >= comparisonEndDate : comparisonstartDate <= comparisonEndDate
}

const getTodaysDate = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1
  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return `${mm}/${dd}/${yyyy}`
}

const validateDateFormat = (dateValue) => {
  if (dateValue) {
    const dateValueMoment = moment(dateValue, 'MM/DD/YYYY', true)
    return (dateValueMoment && (Number.isNaN(moment(dateValueMoment)) || !dateValueMoment.isValid()))
  }

  return moment()
}

const getMinYearsValidationDates = (value) => {
  const valueMoment = moment(value)
  const startMoment = moment('01/01/1900', 'MM/DD/YYYY')
  const endMoment = moment().add(20, 'years')
  return { valueMoment, startMoment, endMoment }
}

const getDobMoment = (value) => {
  const dobMonth = value.split('/')[0] - 1
  const dobDay = value.split('/')[1]
  const dobYear = value.split('/')[2]
  return moment([dobYear, dobMonth, dobDay])
}

let validators = {
  checkRequired: (fieldDefinition = {}, value, errors) => {
    let isValueOk = false
    const uiObj = fieldDefinition.ui
    const isCheckBox = uiObj && uiObj.widget && uiObj.widget === 'checkbox'

    if (fieldDefinition.type === 'boolean' && typeof value !== 'string') {
      isValueOk = isCheckBox ? value !== false : typeof value !== 'undefined'
    } else {
      isValueOk = !!(trim(value))
    }
    if (fieldDefinition.required && !isValueOk) {
      errors.push('required')
    }
  },
  checkPattern: (fieldDefinition = {}, value, errors) => {
    let { pattern } = fieldDefinition
    let errorType = 'pattern'
    if (pattern) {
      const regex = new RegExp(`^${pattern}+$`, 'g')
      if (value !== '' && !regex.test(value)) {
        errors.push(errorType)
      }
    } else if (
      value !== null
      && value !== ''
      && !pattern
      && fieldDefinition.ui
      && patterns[fieldDefinition.ui.widget]) {
      pattern = patterns[fieldDefinition.ui.widget]
      errorType = fieldDefinition.ui.widget
      const regex = pattern
      if (!regex.test(value)) {
        errors.push(errorType)
      }
    }
  },
  checkMin: (fieldDefinition = {}, value, errors) => {
    if (fieldDefinition.min && !Number.isNaN(parseFloat(value)) && value < fieldDefinition.min) {
      errors.push('min')
    }
  },
  checkMax: (fieldDefinition = {}, value, errors) => {
    if (fieldDefinition.max) {
      const isString = typeof fieldDefinition.max === 'string'
      if (!isString && value > fieldDefinition.max) {
        errors.push('max')
      } else if (isString && value > validationHandlers[fieldDefinition.max](fieldDefinition, value)) {
        errors.push('max')
      }
    }
  },
  checkMinLength: (fieldDefinition = {}, value, errors) => {
    if (fieldDefinition.min_length && value.length < fieldDefinition.min_length) {
      errors.push('min_length')
    }
  },
  checkMaxLength: (fieldDefinition = {}, value, errors) => {
    if (fieldDefinition.max_length && value && value.length > fieldDefinition.max_length) {
      errors.push('max_length')
    }
  },
  belongsToRequired: (fieldDefinition, value, fieldData, setFieldId, errors) => {
    if (isUndefined(fieldData.definitions[setFieldId].defaultRequired)) {
      fieldData.definitions[setFieldId].defaultRequired = fieldData.definitions[setFieldId].required
    }
    if (!fieldData.definitions[setFieldId].defaultRequired) {
      const { params } = fieldDefinition.validation_handler
      const fieldId = params.id
      const amount = getValue(fieldData, fieldId)
      const belongsTo = value
      fieldData.definitions[setFieldId].required = (value === '' && amount)
      if (!belongsTo && amount) {
        errors.push('required')
      }
    }
  },
  isEqualWithField: (fieldDefinition, value, fieldData, setFieldId, errors) => {
    const { params } = fieldDefinition.validation_handler
    const compareWithValue = getValue(fieldData, params.id)
    if (!isEqual(value, compareWithValue)) {
      errors.push('value_not_match')
    }
  },
  isEqualWithFieldNoCase: (fieldDefinition, value, fieldData, setFieldId, errors) => {
    const { params } = fieldDefinition.validation_handler
    const compareWithValue = getValue(fieldData, params.id)
    if (!isEqual(value.toLowerCase(), compareWithValue.toLowerCase())) {
      errors.push('value_not_match')
    }
  },
  validatePasswordPolicy: (fieldDefinition, value, fieldData, setFieldId, errors) => {
    const rules = fieldDefinition.validation_patterns || []
    rules.some((rule) => {
      const regex = new RegExp(`^${rule.pattern}`)
      if (!regex.test(value)) {
        errors.push('invalid_password')
        return true
      }
      return false
    })
  },
  checkCustomHandlers: (fieldDefinition, value, fieldData, setFieldId, errors, skipRequired) => {
    if (fieldDefinition.validation_handler) {
      if (Array.isArray(fieldDefinition.validation_handler)) {
        fieldDefinition.validation_handler.every((validationHandler) => {
          const { handler } = validationHandler
          validators[handler](fieldDefinition, value, fieldData, setFieldId, errors, skipRequired)
          return errors.length === 0
        })
      } else {
        const { handler } = fieldDefinition.validation_handler
        validators[handler](fieldDefinition, value, fieldData, setFieldId, errors, skipRequired)
      }
    }
  },
  checkMinYears: (fieldDefinition = {}, value, errors) => {
    let bothErrors = false

    if (fieldDefinition.ui && fieldDefinition.ui.widget === 'datepicker' && value && value !== 'Invalid date') {
      const { valueMoment, startMoment, endMoment } = getMinYearsValidationDates(value)
      if (!valueMoment.isValid()
        || valueMoment.isBefore(startMoment) || valueMoment.isAfter(endMoment)) {
        errors.push('datepicker')
        bothErrors = true
      }
    }

    if (fieldDefinition.min_years && fieldDefinition.min_years > 0 && value && value !== 'Invalid date') {
      const dobMoment = getDobMoment(value)
      const years = moment().diff(dobMoment, 'years', true)

      if ((years < fieldDefinition.min_years) || moment().isBefore(dobMoment)) {
        if (bothErrors) {
          errors[errors.length - 1] = 'min_years'
        } else {
          errors.push('min_years')
        }
      }
    }
  },
  checkNotFutureDate: (fieldDefinition, value, errors) => {
    if (fieldDefinition.notFutureDate) {
      const todaysDate = getTodaysDate()
      if (value && !validDateDiff(value, todaysDate)) {
        errors.push('future_date')
      }
    }
  },
  checkNotPastDate: (fieldDefinition, value, errors) => {
    if (fieldDefinition.notPastDate) {
      const todaysDate = getTodaysDate()
      if (value && !validDateDiff(value, todaysDate, true)) {
        errors.push('past_date')
      }
    }
  },
  checkValidEndDate: (fieldDefinition = {}, value, fieldData, setFieldId, errors) => {
    if (fieldDefinition.validEndDate) {
      const startDateId = getFullId(setFieldId, fieldDefinition.validEndDate)
      const startDateValue = getValue(fieldData, startDateId)
      if (value && startDateValue && !validDateDiff(startDateValue, value) && !errors.includes('invalid_end_date')) {
        errors.push('invalid_end_date')
      } else {
        const todaysDate = getTodaysDate()
        if (startDateValue && fieldData[startDateId]
          && fieldData[startDateId].errors && validDateDiff(startDateValue, todaysDate)) {
          fieldData[startDateId].errors = []
          if (validateDateFormat(startDateValue)) {
            fieldData[startDateId].errors.push('datepicker')
          }
        }
      }
    }
  },
  checkValidStartDate: (fieldDefinition = {}, value, fieldData, setFieldId, errors) => {
    if (fieldDefinition.validStartDate) {
      const endDateId = getFullId(setFieldId, fieldDefinition.validStartDate.endDate)
      const endDateValue = getValue(fieldData, endDateId)

      const currentJobId = fieldDefinition.validStartDate.currentJob
        ? getFullId(setFieldId, fieldDefinition.validStartDate.currentJob)
        : null
      const currentJobValue = currentJobId ? getValue(fieldData, currentJobId) : false

      if (value
        && endDateValue
        && !validDateDiff(value, endDateValue)
        && !errors.includes('invalid_start_date')
        && !currentJobValue) {
        errors.push('invalid_start_date')
      } else if (fieldData[endDateId] && fieldData[endDateId].errors) {
        fieldData[endDateId].errors = []
        if (validateDateFormat(endDateValue)) {
          fieldData[endDateId].errors.push('datepicker')
        }
      }
    }
  },
}

export const addValidators = (newValidators) => {
  validators = { ...validators, ...newValidators }
}

export const runFieldValidators = (fieldDefinition = {}, value, skipRequired = false, fieldData = {}, setFieldId = '') => {
  const errors = []

  if (!skipRequired) {
    validators.checkRequired(fieldDefinition, value, errors)
  }
  errors.length === 0 && validators.checkPattern(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkMin(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkMax(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkMinLength(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkMaxLength(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkMinYears(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkNotFutureDate(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkNotPastDate(fieldDefinition, value, errors)
  errors.length === 0 && validators.checkValidStartDate(fieldDefinition, value, fieldData, setFieldId, errors)
  errors.length === 0 && validators.checkValidEndDate(fieldDefinition, value, fieldData, setFieldId, errors)
  errors.length === 0 && validators.checkCustomHandlers(fieldDefinition, value, fieldData, setFieldId, errors, skipRequired)
  return errors
}
