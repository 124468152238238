import { set } from 'lodash'
import getSavedState from 'utilities/getSavedState'

export const dataActions = {
  DATA_SET: 'DATA_SET',
}

const DEFAULT_STATE = {
}

const initialState = getSavedState('data', DEFAULT_STATE)

export default function (state = initialState, action) {
  switch (action.type) {
    case dataActions.DATA_SET:
      if (action.payload.hash) {
        const newState = { ...state }
        const { hash, data } = action.payload
        const [name] = hash.split('.')
        newState[name] = { ...newState[name] }
        return set(newState, hash, data)
      }
      return { ...state, ...action.payload }
    default:
      return state
  }
}
