import React from 'react'
import Tooltip from './Tooltip'

class InfoBadge extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }
  toggleModal() {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }))
  }
  render() {
    const { helpText } = this.props
    return (
      <div style={{ display: 'inline-block' }}>
        <Tooltip helpText={helpText} />
      </div>
    )
  }
}

export default InfoBadge
