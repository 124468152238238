import React, { Fragment } from 'react'
import { runHandlerVisibleCheck } from 'common/utils/handlers'
import { getModule } from 'common/utils/handlers/modules'
import Field from '../fields/Field'
import ScreenList from './ScreenList'

const ScreenView = ({
  pos,
  componentStructure,
  managerActions,
  fieldActions,
  managerData,
  fieldData,
  listInfo,
  state,
}) => {
  const screen = componentStructure[pos]

  if (!screen.visible) {
    return null
  } if (!runHandlerVisibleCheck(screen, managerData, fieldData, listInfo, state)) {
    return null
  }

  let TheView
  if (screen.list) {
    TheView = (
      <ScreenList
        fieldActions={fieldActions}
        fieldData={fieldData}
        listInfo={listInfo}
        managerActions={managerActions}
        managerData={managerData}
        screen={screen}
        state={state}
      />
    )
  } else if (screen.ui && screen.ui.widget) {
    TheView = getModule(screen.ui.widget)
    TheView = (
      <TheView
        fieldActions={fieldActions}
        fieldData={fieldData}
        listInfo={listInfo}
        managerActions={managerActions}
        managerData={managerData}
        screen={screen}
        state={state}
      />
    )
  } else {
    TheView = (
      <Fragment>
        {screen.fields.map((field, idx) => {
          return (
            <Field
              key={idx}
              field={field}
              fieldActions={fieldActions}
              fieldData={fieldData}
              listInfo={listInfo}
              managerActions={managerActions}
              managerData={managerData}
              state={state}
            />
          )
        })}
      </Fragment>
    )
  }

  return (TheView)
}

export default ScreenView
