class ConsolePlugin {
  init() {
  }
  pageLoad(payload) {
    const { pageName } = payload
    console.log( // eslint-disable-line no-console
      'pageLoad',
      pageName,
    )
  }
  apiError(payload) {
    console.log(payload) // eslint-disable-line no-console
  }
  apiSuccess(payload) {
    console.log(payload) // eslint-disable-line no-console
  }
  apiWhitelist(payload) {
    console.log(payload) // eslint-disable-line no-console
  }
  log(payload) {
    const { type, action, message } = payload
    console.log( // eslint-disable-line no-console
      type,
      action,
      message,
    )
  }
}

export default new ConsolePlugin()
