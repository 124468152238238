import ActionsService from 'actionsService/ActionsService'
import AuthenticationService from 'authenticationService/AuthenticationService'
import { getBeforeUnloadEvent } from 'utilities/device'
import { pageActions } from 'app/PageReducer'
import { store } from 'AppEngine'

const {
  emAppConfig: {
    session: {
      beforeUnload, timeout, tokenRefreshInterval,
    },
  },
} = window
const { stateItemName: STATE_ITEM_NAME, saveState, slicesToSave } = beforeUnload
const { isEnabled } = timeout

const SESSION_SECONDS_OK = timeout.secondsOk
const SESSION_SECONDS_WARN = timeout.secondsWarn
const SESSION_STATE_OK = 'SESSION_STATE_OK'
const SESSION_STATE_WARN = 'SESSION_STATE_WARN'
const SESSION_STATE_EXPIRE = 'SESSION_STATE_EXPIRE'
const SESSION_SECONDS_TOKEN_REFRESH_INTERVAL = tokenRefreshInterval
const TOUCH_ENABLED = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0))

class SessionService {
  constructor() {
    this.isSaveStateBeforeUnload = true
  }
  attachWindowUnloadEvent() {
    const eventName = getBeforeUnloadEvent()
    window.addEventListener(eventName, () => {
      if (this.saveStateBeforeUnload()) {
        this.saveState(this.filterSlices(store.getState()))
      }
    })
  }
  disableSaveStateBeforeUnload() {
    this.isSaveStateBeforeUnload = false
  }
  saveStateBeforeUnload() {
    return saveState && this.isSaveStateBeforeUnload
  }
  filterSlices(state) {
    Object.keys(state).forEach((k) => {
      if (slicesToSave[k] !== true) {
        delete state[k]
      }
    })
    return state
  }
  saveState(state) {
    sessionStorage.setItem(STATE_ITEM_NAME, JSON.stringify(state))
  }
  startInactivityState() {
    if (!isEnabled) {
      return
    }
    this.resetActivityState()
    this.startTimeoutInterval()
    this.startRefreshInterval()
    this.startDomInactivityHandler()
  }
  startDomInactivityHandler() {
    this.lastActivitySecond = Math.floor(Date.now() / 1000)
    const domInactivityHandler = () => {
      const { page: pageData } = store.getState()
      const { session } = pageData
      const { inactivityState } = session
      if (inactivityState === SESSION_STATE_OK) {
        this.lastActivitySecond = Math.floor(Date.now() / 1000)
      }
    }

    document.addEventListener('mousemove', domInactivityHandler)
    if (TOUCH_ENABLED) {
      document.addEventListener('touchstart', domInactivityHandler, false)
      document.addEventListener('touchmove', domInactivityHandler, false)
    }
  }
  startTimeoutInterval() {
    this.sessionInterval = setInterval(async () => {
      const { page: pageData } = store.getState()
      const { session } = pageData
      const { inactivityState } = session
      const inactivitySeconds = Math.floor(Date.now() / 1000) - this.lastActivitySecond
      const shouldTransition = this.SESSION_INACTIVITY_STATES[inactivityState].shouldTransition(inactivitySeconds)
      if (shouldTransition) {
        this.incrementState()
      }
    }, 1000)
  }
  startRefreshInterval() {
    setInterval(() => {
      this.refreshToken()
    }, SESSION_SECONDS_TOKEN_REFRESH_INTERVAL * 1000)
  }
  async refreshToken() {
    try {
      // const logMessage = 'OAPI Auth Token refreshed'
      await AuthenticationService.introspect()
    } catch (e) {
      console.error('Unable to refresh OAPI Auth token', e) // eslint-disable-line
    }
  }
  transitionToState(inactivityState) {
    if (inactivityState === SESSION_STATE_OK) {
      this.lastActivitySecond = Math.floor(Date.now() / 1000)
    }
    this.SESSION_INACTIVITY_STATES[inactivityState].action()
  }
  resetActivityState() {
    this.transitionToState(SESSION_STATE_OK)
    this.setState(SESSION_STATE_OK)
  }
  incrementState() {
    const { page: pageData } = store.getState()
    const { session } = pageData
    const { inactivityState } = session
    const { nextState } = this.SESSION_INACTIVITY_STATES[inactivityState]
    this.transitionToState(nextState)
    this.setState(nextState)
  }
  setState(inactivityState) {
    store.dispatch({
      type: pageActions.PAGE_SET,
      payload: {
        session: {
          inactivityState,
        },
      },
    })
  }
  redirectToLogout() {
    AuthenticationService.logout()
  }
  SESSION_INACTIVITY_STATES = {
    SESSION_STATE_OK: {
      nextState: SESSION_STATE_WARN,
      shouldTransition: (inactivitySeconds) => {
        return inactivitySeconds > this.timeoutSetting
      },
      action: () => {
        if (!this.timeoutSetting) {
          this.timeoutSetting = SESSION_SECONDS_OK
        }
        this.expireSeconds = this.timeoutSetting + SESSION_SECONDS_WARN
      },
    },
    SESSION_STATE_WARN: {
      nextState: SESSION_STATE_EXPIRE,
      shouldTransition: (inactivitySeconds) => {
        return inactivitySeconds > this.expireSeconds
      },
      action: () => {
        ActionsService.fetchAndRunGroup('showModal', {
          name: 'engineSessionExpireWarn',
          resetTimer: true,
        })
      },
    },
    SESSION_STATE_EXPIRE: {
      shouldTransition: () => false,
      action: () => {
        this.redirectToLogout()
      },
    },
  }
}

export default new SessionService()
