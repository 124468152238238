import React from 'react'
import RouteNavigationPrompt from 'react-router-navigation-prompt'
import ActionsService from 'actionsService/ActionsService'
import { store } from 'AppEngine'

let isShown = false

const NavigationPrompt = ({ ...props }) => {
  const {
    pageExtra, pageData, appData,
  } = props
  const { navigationPromptOpts } = pageExtra
  if (!navigationPromptOpts) {
    return null
  }
  const {
    when, onConfirm, onCancel, modalName,
  } = navigationPromptOpts
  if (!navigationPromptOpts || !when) {
    return null
  }
  return (
    <RouteNavigationPrompt
      when={
        () => {
          const { manager: managerData, field: fieldData } = store.getState()
          return when({
            managerData, pageData, fieldData, appData,
          })
        }
      }
    >
      {({ onConfirm: confirmCb, onCancel: cancelCb }) => {
        if (!isShown) {
          isShown = true
          ActionsService.fetchAndRunGroup('showModal', {
            name: modalName || 'engineNavPrompt',
            onConfirm: onConfirm || confirmCb,
            onCancel: onCancel || cancelCb,
            confirmCb: () => { isShown = false; confirmCb() },
            cancelCb: () => { isShown = false; cancelCb() },
          })
        }
        return null
      }}
    </RouteNavigationPrompt>
  )
}

export default NavigationPrompt
