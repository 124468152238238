/* eslint-disable max-lines,no-restricted-syntax,guard-for-in */
import { batchActions } from 'redux-batched-actions'
import {
  DEC_LIST,
  REMOVE_LIST_ITEM_FIELDS,
  CLEAR_LIST_ITEM_FIELDS,
  CLEAR_GROUPED_FIELDS,
  SET_FIELD,
  SCREEN_ERRORS_COUNT_INCDEC,
  SET_FORM_DIRTY_FLAG,
  TOGGLE_CLEAR_REMOVE_DIALOG,
  SET_NEW_FORM_FIELD_STATE,
  LOAD_DEFAULT_FIELDS,
} from 'common/constants/ActionConstants'
import ManagerService from 'common/services/ManagerService'
import { getFieldId, getFieldsWithDefault } from 'common/utils/field'
import { runHandler } from 'common/utils/handlers'
import { runFieldValidators } from 'common/utils/handlers/validators'
import { getNext } from 'common/actions/ManagerActions'

const getErrorCount = (getState, payload) => {
  const fields = getState().field
  const listInfo = payload
  const regex = new RegExp(`-list-${listInfo.id}-index-${listInfo.index}$`)
  let errorIncDec = 0
  Object.keys(fields).forEach((fieldKey) => {
    if (regex.test(fieldKey) && fields[fieldKey].errors) {
      errorIncDec -= (fields[fieldKey].errors.length)
    }
  })

  return errorIncDec
}

export const removeListItemFields = (payload) => {
  return (dispatch, getState) => {
    const errorIncDec = getErrorCount(getState, payload)

    dispatch(batchActions([
      {
        type: REMOVE_LIST_ITEM_FIELDS,
        payload,
      },
      {
        type: DEC_LIST,
        id: payload.id,
      },
      {
        type: SCREEN_ERRORS_COUNT_INCDEC,
        payload: { errorIncDec },
      },
      {
        type: SET_FORM_DIRTY_FLAG,
        payload: {
          isFormDirty: true,
        },
      },
    ]))
  }
}

export const clearListItemFields = (payload) => {
  return (dispatch, getState) => {
    const errorIncDec = getErrorCount(getState, payload)
    const listIdCache = ManagerService.getListIdCache()

    dispatch(batchActions([
      {
        type: CLEAR_LIST_ITEM_FIELDS,
        payload: {
          listInfo: payload,
          listIds: listIdCache,
        },
      },
      {
        type: SCREEN_ERRORS_COUNT_INCDEC,
        payload: { errorIncDec },
      },
      {
        type: SET_FORM_DIRTY_FLAG,
        payload: {
          isFormDirty: true,
        },
      },
    ]))
  }
}

export const toggleClearRemoveDialog = (clicked, listInfo, isConfirmRemoveDialog) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_CLEAR_REMOVE_DIALOG,
      payload: {
        clicked,
        listInfo,
        isConfirmRemoveDialog,
      },
    })
  }
}

export const clearGroupedFields = (payload) => {
  return {
    type: CLEAR_GROUPED_FIELDS,
    payload,
  }
}

export const setFieldTypeConversionToFieldType = (value, type) => {
  // TODO: type conversion needs cleanup
  if (type === 'boolean') {
    return value !== 'false'
  }
  return value
}

const setFieldErrorChecking = (errors, payload) => {
  const preErrorCount = (errors ? errors.length : 0)
  const postErrorCount = payload.errors.length
  return postErrorCount - preErrorCount
}

const setFieldActions = (definition, managerData, fieldData, payload, dispatch, getState, setFieldId, errorIncDec,
  isNotDirty) => {
  const actions = []
  // On change handlers
  if (definition.on_change) {
    const onChangeActions = runHandler({
      handler: definition.on_change.handler,
      managerData,
      fieldData,
      id: payload.id,
      value: payload.value,
      listInfo: payload.listInfo,
      managerActions: payload.managerActions,
      fieldActions: payload.fieldActions,
      dispatch,
      getState,
      isBlur: payload.isBlur,
    })
    if (Array.isArray(onChangeActions)) {
      onChangeActions.forEach((onChangeAction) => {
        actions.push(onChangeAction)
      })
    } else if (onChangeActions !== undefined) {
      actions.push(onChangeActions)
    }
  }
  // Error checking on any SET_FIELD(S)
  actions.forEach((action) => {
    if ({ SET_FIELDS: true, SET_FIELD: true }[action.type]) {
      let { payload: actionPayload } = action
      if (action.type === SET_FIELD) {
        actionPayload = {}
        actionPayload[action.payload.id] = action.payload
      }
      Object.keys(actionPayload).forEach((actionPayloadKey) => {
        if (setFieldId !== actionPayloadKey) {
          const fieldP = fieldData[actionPayloadKey]
          const definitionP = fieldData.definitions[getFieldId(actionPayloadKey)]
          const errorsP = (fieldP ? fieldP.errors : [])
          const preErrorCountP = (errorsP ? errorsP.length : 0)
          actionPayload[actionPayloadKey].errors = runFieldValidators(
            definitionP,
            actionPayload[actionPayloadKey].value,
            false,
            fieldData,
            actionPayload.id,
          )
          const postErrorCountP = actionPayload[actionPayloadKey].errors.length
          errorIncDec += postErrorCountP - preErrorCountP
        }
      })
    }
  })
  actions.push({
    type: SCREEN_ERRORS_COUNT_INCDEC,
    payload: { errorIncDec },
  })
  // We do this last so that the SET_FIELD takes precendent over
  // anything done in SET_FIELDS
  const {
    id, field, value, listInfo, errors,
  } = payload
  actions.push({
    type: SET_FIELD,
    payload: {
      id, value, field, listInfo, errors,
    },
  })
  // Set form to dirty
  actions.push({
    type: SET_FORM_DIRTY_FLAG,
    payload: {
      isFormDirty: !isNotDirty,
    },
  })
  return actions
}

export const runFieldChecks = opts => (dispatch, getState) => {
  const {
    clearHidden, fieldIds, skipRequired, isSubmitAction,
  } = { clearHidden: false, fieldIds: [], ...opts }

  // hack... since all appEngine actions are async...
  setTimeout(() => {
    const fieldErrors = ManagerService.runFieldChecks(getState, clearHidden, skipRequired, isSubmitAction, fieldIds)
    if (fieldErrors !== false) {
      dispatch(batchActions(fieldErrors))
    }
  }, 100)
}

export const setField = (payload) => {
  return (dispatch, getState) => {
    const setFieldId = payload.id
    const fieldData = getState().field
    const managerData = getState().manager
    const { errors = [] } = typeof fieldData[payload.id] !== 'undefined' ? fieldData[payload.id] : {}
    const definition = (payload.field ? payload.field : fieldData.definitions[getFieldId(payload.id)])

    payload.value = setFieldTypeConversionToFieldType(payload.value, definition.type)

    if (definition.default) {
      payload.hasDefault = true
    }

    // Error checking
    payload.errors = runFieldValidators(definition, payload.value, false, fieldData, setFieldId)
    const errorIncDec = setFieldErrorChecking(errors, payload)

    const actions = setFieldActions(definition, managerData, fieldData, payload,
      dispatch, getState, setFieldId, errorIncDec, payload.isNotDirty)
    dispatch(batchActions(actions))

    // Clear field errors on HIDDEN FIELDS only
    runFieldChecks({ clearHidden: true })(dispatch, getState)

    // We do this after the other dispatches to make sure the new value(s) are available to handlers, etc.
    if (payload.value !== undefined && managerData.theme.auto_nav_next && ManagerService.autoNavNext(definition)) {
      setTimeout(() => { getNext()(dispatch, getState) }, 0)
    }
  }
}

export const onFocus = (payload) => {
  return (dispatch, getState) => {
    const fieldData = getState().field
    const managerData = getState().manager
    const definition = (payload.field ? payload.field : fieldData.definitions[getFieldId(payload.id)])
    const actions = []

    if (definition.on_focus) {
      const onFocusActions = runHandler({
        handler: definition.on_focus.handler,
        managerData,
        fieldData,
        id: payload.id,
        value: payload.value,
        listInfo: payload.listInfo,
        managerActions: payload.managerActions,
        fieldActions: payload.fieldActions,
        dispatch,
        getState,
      })
      if (Array.isArray(onFocusActions)) {
        onFocusActions.forEach((onFocusAction) => {
          actions.push(onFocusAction)
        })
      } else if (onFocusActions !== undefined) {
        actions.push(onFocusActions)
      }
    }

    if (actions.length) {
      dispatch(batchActions(actions))
    }
  }
}

export const onBlur = (payload) => {
  return (dispatch, getState) => {
    const fieldData = getState().field
    const managerData = getState().manager
    const definition = (payload.field ? payload.field : fieldData.definitions[getFieldId(payload.id)])
    const actions = []

    if (definition.on_blur) {
      const onBlurActions = runHandler({
        handler: definition.on_blur.handler,
        managerData,
        fieldData,
        id: payload.id,
        value: payload.value,
        listInfo: payload.listInfo,
        managerActions: payload.managerActions,
        fieldActions: payload.fieldActions,
        dispatch,
        getState,
      })
      if (Array.isArray(onBlurActions)) {
        onBlurActions.forEach((onBlurAction) => {
          actions.push(onBlurAction)
        })
      } else if (onBlurActions !== undefined) {
        actions.push(onBlurActions)
      }
    }

    if (actions.length) {
      dispatch(batchActions(actions))
    }
  }
}

export const setNewFormFieldState = (payload) => {
  return {
    type: SET_NEW_FORM_FIELD_STATE,
    payload,
  }
}

export const loadDefaultFields = () => ({
  type: LOAD_DEFAULT_FIELDS,
  payload: getFieldsWithDefault(),
})
