/* eslint-disable no-restricted-syntax,no-continue,guard-for-in,no-use-before-define */
import { cloneDeep, isObject } from 'lodash'

const allowedArrayProps = { options: true }
const allowedObjectProps = { ui: true }
const primitiveTypes = { string: true, boolean: true, number: true }

const flattenUUIDs = (obj, ids = {}) => {
  ids[obj.id] = obj
  if (obj.type === 'field_group' || obj.type === 'screen') {
    obj.fields.forEach((field) => {
      flattenUUIDs(field, ids)
    })
  } else if (obj.type === 'screen_group') {
    // screen_group is not editable yet on lender admin
  }
  return ids
}

const diffObject = (itemMod, itemCur) => {
  let diff = null
  Object.keys(itemMod).forEach((itemModKey) => {
    const vMod = itemMod[itemModKey]
    const vCur = itemCur[itemModKey]
    if (primitiveTypes[typeof vMod] && vMod !== vCur) {
      diff = diff || {}
      diff[itemModKey] = vMod
    } else if (allowedArrayProps[itemModKey] && Array.isArray(vMod)) {
      const overlays = getOverlays(vMod, vCur)
      if (overlays.length) {
        diff = diff || {}
        diff[itemModKey] = overlays
      }
    } else if (allowedObjectProps[itemModKey] && isObject(vMod)) {
      const overlays = diffObject(vMod, vCur)
      if (overlays) {
        diff = diff || {}
        diff[itemModKey] = overlays
      }
    }
  })
  return diff
}

const getOverlays = (modifiedBaseIds, currentBaseIds) => {
  const idCache = {}
  Object.keys(modifiedBaseIds).forEach((modifiedBaseIdKey) => {
    if (modifiedBaseIdKey.toString() === 'undefined') return // some legacy fields do not have ids and we can ignore these
    const itemMod = modifiedBaseIds[modifiedBaseIdKey]
    const itemCur = currentBaseIds[modifiedBaseIdKey]
    if (!itemCur) {
      // eslint-disable-next-line no-console
      console.warn(`Field ${modifiedBaseIdKey} does not exist`)
      return
    }
    const diff = diffObject(itemMod, itemCur)
    if (diff) {
      idCache[modifiedBaseIdKey] = { modifiedBaseIdKey, ...diff }
    }
  })

  const overlays = []
  Object.values(idCache).forEach((idCacheValue) => {
    overlays.push(idCacheValue)
  })

  return overlays
}

const removeRequiredForLabels = (baseObject, overlayObject) => {
  if (baseObject.type === 'null' && overlayObject.required) {
    delete overlayObject.required
  }
}

function applyObjectDiff(baseObject, overlayObject) {
  Object.keys(overlayObject).forEach((overlayObjectKey) => {
    if (primitiveTypes[typeof overlayObject[overlayObjectKey]]) {
      baseObject[overlayObjectKey] = overlayObject[overlayObjectKey]
    } else if (Array.isArray(overlayObject[overlayObjectKey])) {
      applyOverlays(baseObject[overlayObjectKey], overlayObject[overlayObjectKey])
    } else if (isObject(overlayObject[overlayObjectKey])) {
      if (!baseObject[overlayObjectKey]) {
        baseObject[overlayObjectKey] = {}
      }
      applyObjectDiff(baseObject[overlayObjectKey], overlayObject[overlayObjectKey])
    }
  })
}

export const applyOverlays = (currentBaseIds, overlays) => {
  overlays.forEach((o) => {
    if (!currentBaseIds[o.id]) { // skipping the fields that do not exist anymore
      return
    }
    removeRequiredForLabels(currentBaseIds[o.id], o)
    applyObjectDiff(currentBaseIds[o.id], o)
  })
}

export const extractCustomizations = (currentBaseJson, modifiedBaseJson) => {
  // Summary: Take the changes from current base JSON and
  // return a customization object
  const modifiedBaseIds = {}
  const currentBaseIds = {}
  modifiedBaseJson.screens.forEach((screen) => {
    flattenUUIDs(screen, modifiedBaseIds)
  })
  currentBaseJson.screens.forEach((screen) => {
    flattenUUIDs(screen, currentBaseIds)
  })
  return getOverlays(modifiedBaseIds, currentBaseIds)
}

export const overlayCustomizations = (currentBaseJson, overlays = []) => {
  const currentBaseIds = {}
  const base = cloneDeep(currentBaseJson)
  const fieldObj = base.screens || base.fields
  fieldObj.forEach((field) => {
    flattenUUIDs(field, currentBaseIds)
  })
  applyOverlays(currentBaseIds, overlays)
  return base
}
