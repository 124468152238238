/* eslint-disable max-lines */
import { batchActions } from 'redux-batched-actions'
import {
  LOAD,
  LOAD_FIELDS,
  SET_FIELDS,
  WALKTO,
  GET_PREV,
  GET_NEXT,
  ZERO_LISTS,
  CLEAR_HIDDEN_FIELDS,
  INC_LIST,
  DEC_LIST,
  SET_NEXT_ENABLED,
  SET_SCREEN_LOADING,
  SCREEN_ERRORS_COUNT_INCDEC,
  SET_NEW_FORM_MANAGER_STATE,
} from '../constants/ActionConstants'
import ManagerService from '../services/ManagerService'
import EventService from '../services/EventService'
import RegistrationService from '../services/RegistrationService'
import { loadDefaultFields } from './FieldActions'

export const load = ({
  workflowObj,
  handlers,
  icons,
  messages,
  validators,
  components,
  modules,
}) => {
  return (dispatch, getState) => {
    if (ManagerService.loadWorkflowObj(workflowObj) === null) {
      throw Error('unable to load workflow')
    }

    const idCache = ManagerService.getIdCache()

    RegistrationService.add({
      handlers,
      icons,
      messages,
      validators,
      components,
      modules,
    })

    // first time loading app, set fields with default values
    dispatch(loadDefaultFields())

    // Auto set fields (auto_set)
    dispatch(batchActions([
      {
        type: SET_FIELDS,
        payload: ManagerService.getAutoSetFields(getState()),
      },
      {
        type: LOAD_FIELDS,
        payload: {
          idCache,
        },
      },
    ]))

    // Workflow goto (on refresh/return from login do a transition)
    // On start of a new application we just go to next step
    // and that will load the first step in the workflow
    let gotoAction
    const beginId = ManagerService.getNext(getState())
    // Screen already loaded from cache
    if (getState().manager.id) {
      gotoAction = {
        type: WALKTO,
        payload: {
          id: ManagerService.walkto(getState().manager.id, getState()),
          path: ManagerService.getPath(),
          beginId,
        },
      }
    } else {
      gotoAction = {
        type: GET_NEXT, // Go to start of the flow
        payload: {
          id: beginId,
          path: ManagerService.getPath(),
          beginId,
        },
      }
    }

    const actions = [
      {
        type: LOAD,
        payload: {
          loaded: true,
          theme: workflowObj.theme,
          screen: {
            showLoading: false,
          },
          workflowObj: ManagerService.getWorkflowObj(),
        },
      },
      { ...gotoAction },
    ]

    dispatch(batchActions(actions))
  }
}

// Update field errors for RID based fields
const updateFieldErrorsRidBased = (getState, dispatch) => {
  const fieldErrors = ManagerService.runFieldChecks(getState, false, true)
  if (fieldErrors !== false) {
    dispatch(batchActions(fieldErrors))
  }
}

export const getNext = (isSubmitAction = false) => {
  return async (dispatch, getState) => {
    if (await EventService.notify({ dispatch, getState }, GET_NEXT)) {
      // Check for required fields
      const fieldErrors = ManagerService.runFieldChecks(getState, isSubmitAction)
      if (fieldErrors !== false) {
        dispatch(batchActions(fieldErrors))
        if (fieldErrors[fieldErrors.length - 1].payload.errorIncDec > 0) {
          const errorEl = document.querySelector('.error')
          if (errorEl) {
            errorEl.focus()
          }
          return
        }
      }

      dispatch(batchActions([
        {
          type: SET_FIELDS,
          payload: ManagerService.getAutoSetFields(getState()),
        },
      ]))

      const clearValues = ManagerService.getFieldsToClearValues(getState)

      window.parent.scrollTo(0, 0)

      dispatch(batchActions([
        {
          type: ZERO_LISTS,
          payload: clearValues.listIdsToZero,
        },
        {
          type: CLEAR_HIDDEN_FIELDS,
          payload: clearValues.fieldIdsToClear,
        },
        {
          type: GET_NEXT,
          payload: {
            id: ManagerService.getNext(getState()),
            path: ManagerService.getPath(),
            lastAction: GET_NEXT,
          },
        },
      ]))

      updateFieldErrorsRidBased(getState, dispatch)
    }
  }
}

export const getPrev = () => {
  return async (dispatch, getState) => {
    if (await EventService.notify({ dispatch, getState }, GET_PREV)) {
      window.parent.scrollTo(0, 0)

      dispatch({
        type: GET_PREV,
        payload: {
          id: ManagerService.getPrev(getState()),
          path: ManagerService.getPath(),
          lastAction: GET_PREV,
        },
      })

      // decrease the number of nexts if the user clicks on prev
      updateFieldErrorsRidBased(getState, dispatch)
    }
  }
}

export const incList = id => ({
  type: INC_LIST,
  id,
})

export const decList = id => ({
  type: DEC_LIST,
  id,
})

export const setNextEnabled = payload => ({
  type: SET_NEXT_ENABLED,
  payload,
})

export const screenErrorsCountIncDec = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SCREEN_ERRORS_COUNT_INCDEC,
      payload,
    })
  }
}

export const setScreenLoading = payload => ({
  type: SET_SCREEN_LOADING,
  payload,
})


export const setNewFormManagerState = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_FORM_MANAGER_STATE,
      payload,
    })
  }
}
