export default function getMetaDispatch(dispatch, screen) {
  let metaDispatch = dispatch
  if (screen.id) {
    metaDispatch = (action) => {
      if (typeof action === 'function') {
        return dispatch(
          (dispatchInner, getState) => {
            const myGetState = () => {
              const state = getState()
              let manager = state.manager || {}
              let field = state.field || {}
              if (screen.id) {
                manager = manager[screen.id] || {}
                field = field[screen.id] || {}
              }
              return { ...state, manager, field }
            }
            return action((innerAction) => {
              const { meta, ...rest } = innerAction
              if (Array.isArray(innerAction.payload)) {
                innerAction.payload = innerAction.payload.map((batchAction) => {
                  const { meta1, ...rest1 } = batchAction
                  return {
                    ...rest1,
                    meta: {
                      ...meta1,
                      namespace: screen.id,
                    },
                  }
                })
                return dispatch(innerAction)
              }
              return dispatch({
                ...rest,
                meta: {
                  ...meta,
                  namespace: screen.id,
                },
              })
            }, myGetState)
          },
        )
      }
      const { meta, ...rest } = action
      return dispatch({
        ...rest,
        meta: {
          ...meta,
          namespace: screen.id,
        },
      })
    }
  }
  return metaDispatch
}
