import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as FieldActions from 'common/actions/FieldActions'
import * as ManagerActions from 'common/actions/ManagerActions'
import getMetaDispatch from 'common/utils/getMetaDispatch'
import Field from '../fields/Field'

const formStyle = { height: '100%' }

export function FormView({
  metaManagerActions,
  managerActions,
  fieldActions,
  managerData,
  fieldData,
  screen,
  state,
}) {
  let managerData2 = managerData || {}
  let fieldData2 = fieldData || {}
  if (screen.id) {
    managerData2 = managerData[screen.id] || {}
    fieldData2 = fieldData[screen.id] || {}
  }

  useEffect(() => {
    if (!fieldData.definitions) {
      fieldActions.setNewFormFieldState(screen)
    }
    if (!managerData.structure) {
      metaManagerActions.setNewFormManagerState(screen)
    }
    fieldActions.loadDefaultFields()
  }, [])

  return (
    <form style={formStyle}>
      {screen.fields.map((field, idx) => {
        return (
          <Field
            key={idx}
            field={field}
            fieldActions={fieldActions}
            fieldData={fieldData2}
            managerActions={{ ...managerActions, ...metaManagerActions }}
            managerData={managerData2}
            state={state}
          />
        )
      })}
    </form>
  )
}

const mapDispatchToProps = (dispatch, { screen }) => {
  return {
    fieldActions: bindActionCreators(FieldActions, getMetaDispatch(dispatch, screen)),
    metaManagerActions: bindActionCreators(ManagerActions, getMetaDispatch(dispatch, screen)),
  }
}

const mapStateToProps = (state) => {
  return {
    managerData: state.manager,
    fieldData: state.field,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView)
