import React from 'react'
import sortBy from 'lodash/sortBy'
import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import DSComboBox from '@elliemae/ds-basic/form/ComboBox'
import { runHandlerValue, runHandlerOptions } from 'common/utils/handlers'
import { getValue, getVisibleFields as getVisibleOptions } from 'common/utils/field'
import { getErrorMessages } from 'common/utils/handlers/messages'

const sorters = {
  alpha: opt => opt.text.toLowerCase(),
  beta: opt => opt.value,
}

const SelectDropdown = ({
  onChange,
  field,
  fieldData,
  managerActions,
  managerData,
  fieldActions,
  listInfo,
  state,
}) => {
  const actualFieldData = fieldData[field.id] || {}
  const errors = actualFieldData.errors || []
  const {
    id = '',
    container_props = {},
    disabled = actualFieldData.disabled,
    required = actualFieldData.required,
    clearable = actualFieldData.clearable,
    isMulti = actualFieldData.isMulti,
    readOnly = actualFieldData.readOnly,
    searchable = actualFieldData.searchable,
    maxOptions = actualFieldData.maxOptions || 10,
    ui: {
      use_truncated_text = false,
      item_size = 35,
    },
  } = field

  const getAvailableOptions = () => (field.options_handler
    ? runHandlerOptions(field, managerData, fieldData, [], state) || []
    : getVisibleOptions(field.options, managerData, fieldData, listInfo, state) || [])

  let opts = getAvailableOptions()
  const defaultValue = field.default

  if (field.sort_handler) {
    opts = sortBy(opts, sorters[field.sort_handler])
  }

  const handleChange = (value) => {
    onChange({
      id: field.id,
      value: value === null ? undefined : value,
      field,
      managerActions,
      fieldActions,
      listInfo,
    })
  }

  const value = (field.value_handler)
    ? runHandlerValue(field, fieldData)
    : getValue(fieldData, field.id)

  const safeValue = (value || value === '') ? value : defaultValue

  const inputComponent = (
    <DSComboBox
      clearable={clearable}
      customMenuItemOptions={{
        useTruncatedText: use_truncated_text,
        itemSize: item_size,
      }}
      isMulti={isMulti}
      labelProperty='text'
      maxOptions={maxOptions}
      options={opts}
      searchable={searchable}
    />
  )

  return (
    <div className='input'>
      <DSFormItemLayout
        disabled={disabled}
        extraInputProps={{
          containerProps: {
            id,
            ...container_props,
          },
        }}
        feedbackMessage={field.helptext}
        floatingLabel
        hasError={errors.length > 0}
        inputComponent={inputComponent}
        labelText={field.label || ''}
        onChange={e => handleChange(e)}
        readOnly={readOnly}
        required={required}
        validationMessage={getErrorMessages(field, errors)}
        value={safeValue}
      />
    </div>
  )
}

export default SelectDropdown
