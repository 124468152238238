/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react'

const errorForgotUsername = () => (
  <span className='error'>
It looks like you already have an account with us. Did you forget your
    <a href={`/borrower-app/forgot-username${window.location.search}`}>username</a>
?
  </span>
)

const errorVerifyAccount = ({ managerActions }) => (
  <span className='error'>
Your account is not verified. Click
    <a onClick={() => managerActions.resendVerification()}>here</a>
    {' '}
to request a new verification email.
  </span>
)

/* eslint-disable max-len */
export const formMessages = {
  min: 'Value must be greater than or equal to %min%.',
  max: 'Value must be less than or equal to %max%.',
  required: 'This field is required.',
  pattern: 'Invalid field pattern.',
  year: 'Please use the format YYYY.',
  phone: 'Please use the format xxx-xxx-xxxx.',
  workphone: 'Please use the format xxx-xxx-xxxx or xxx-xxx-xxxx xxxx.',
  ssn: 'Please use the format xxx-xx-xxxx.',
  email: 'This is not a valid email format.',
  zip: 'Please use the format xxxxx or xxxxx-xxxx.',
  max_length: 'This field must be shorter than %max_length%.',
  min_length: 'This field must contain at least %min_length% characters.',
  min_years: 'You must be %min_years% or older to apply for a loan.',
  invalid_start_date: 'Start Date must be before End Date.',
  invalid_end_date: 'End Date must be after Start Date.',
  future_date: 'You cannot enter a date in the future.',
  past_date: 'You cannot enter a date in the past.',
  datepicker: 'Please enter a valid date in the form of MM/DD/YYYY.',
  econsent_required_field: 'This field is required to accept or decline eConsent.',
  downpayment_greater_than_purchase_price: 'Down payment cannot be greater than or equal to the purchase price.',
  downpayment_greater_than_loan_amount: 'Down payment cannot be greater than or equal to the loan amount.',
  initialdrawamt_greater_than_maxlineofcredit: 'Initial draw amount must be equal to or less than the maximum line of credit amount.',
  value_not_match: '%name% does not match. Please try again.',
  invalid_password: 'Your password must meet these requirements.',
  not_username_available: 'This username is unavailable. Please try a new one.',
  not_fnlnemail_available: errorForgotUsername,
  invalid_username: 'Please enter a valid username.',
  not_validated_username: errorVerifyAccount,
  invalid_security_answer: 'Your answer is incorrect, please try again.',
  account_locked_security_answer: 'We\'re sorry. Due to multiple incorrect answers, your account has been locked. Please contact your loan officer for help.',
  account_suspended_15: 'We\'re sorry. Your account has been suspended. Please try again in 15 minutes.',
  account_suspended: 'We\'re sorry. Due to multiple incorrect login attempts, your account has been locked. Please contact your loan officer for help.',
  not_same_password: 'This password is the same as your existing password. Please provide a new one.',
  invalid_current_password: 'Your current password is incorrect.',
}
