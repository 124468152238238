import React from 'react'
import DSSeparator from '@elliemae/ds-basic/Separator'

const Separator = ({
  field: {
    id = '',
    container_props = {},
    ui: {
      dashed = false,
      margin = 's',
      orientation = 'horizontal',
      position = 'initial',
      separator_type: separatorType = 'category-level',
    },
  },
}) => {
  return (
    <DSSeparator
      containerProps={{
        id,
        ...container_props,
      }}
      dashed={dashed}
      margin={margin}
      orientation={orientation}
      position={position}
      type={separatorType}
    />
  )
}

export default Separator
