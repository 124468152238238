import React from 'react'
import { isSet, getValue } from 'common/utils/field'
import DSToggle from '@elliemae/ds-basic/form/Toggle'

const Toggle = ({
  onChange,
  field,
  field: {
    id = '',
    container_props = {},
  },
  fieldData,
  listInfo,
  fieldActions,
  managerActions,
}) => {
  const handleChange = (e) => {
    onChange({
      id: field.id,
      value: e.target.checked,
      listInfo,
      field,
      fieldActions,
      managerActions,
    })
  }

  let checked = !!getValue(fieldData, field.id)
  if (!isSet(fieldData, field.id)) {
    checked = false
  }
  return (
    <DSToggle
      checked={checked}
      containerProps={{
        id,
        ...container_props,
      }}
      id={field.id}
      labelOff='OFF'
      labelOn='ON'
      name={field.id}
      onChange={e => handleChange(e)}
      readOnly={false}
    />
  )
}

export default Toggle
