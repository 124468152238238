import React, { Component } from 'react'
import { getVisibleFields } from 'common/utils/field'
import { DSAccordion, DSAccordionItem } from '@elliemae/ds-basic/Accordion'
import Field from './Field'

export default class Accordion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [props.field.default || 0],
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(value) {
    const {
      onChange = () => {},
      field: {
        id,
      },
      field,
      listInfo,
      fieldActions,
      managerActions,
    } = this.props
    this.setState({
      selected: value,
    }, () => {
      onChange({
        id,
        value,
        listInfo,
        field,
        fieldActions,
        managerActions,
      })
    })
  }
  render() {
    const {
      props: {
        field: {
          id,
          items = [],
          allowMultipleOpen = false,
          container_props = {},
          contentExpandable = true,
        },
        managerActions,
        fieldActions,
        managerData,
        fieldData,
        listInfo,
        state,
      },
      state: {
        selected,
      },
    } = this

    const visibleItems = getVisibleFields(items, managerData, fieldData, listInfo, state)

    if (!items.length || !visibleItems.length) return null

    return (
      <DSAccordion
        activeValue={selected}
        allowMultipleOpen={allowMultipleOpen}
        containerProps={{
          id,
          ...container_props,
        }}
        contentExpandable={contentExpandable}
        onChange={this.handleChange}
      >
        {
          visibleItems.map((item, index) => {
            const { label = '' } = item
            return (
              <DSAccordionItem
                key={index}
                title={label}
                value={index}
              >
                {item.fields.map(aField => (
                  <Field
                    key={aField.id}
                    field={aField}
                    fieldActions={fieldActions}
                    fieldData={fieldData}
                    fieldGroup={aField}
                    listInfo={listInfo}
                    managerActions={managerActions}
                    managerData={managerData}
                    state={state}
                  />
                ))}
              </DSAccordionItem>
            )
          })
        }
      </DSAccordion>
    )
  }
}
