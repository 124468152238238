import ActionsService from 'actionsService/ActionsService'
import LoggerService from 'loggerService/LoggerService'
import RESTService from './RESTServices'

class ApiService {
  opts = {
    ...{
      fetchErrorActionGroup: {
        name: 'showModal',
        opts: {
          name: 'engineErrorModal',
          message: 'An error has occurred.',
          canClose: false,
        },
      },
      codeErrorActionGroup: {
        name: 'showModal',
        opts: {
          name: 'engineErrorModal',
          message: 'An error has occurred.',
          canClose: false,
        },
      },
    },
    ...window.emAppConfig.api.defaultOpts,
  }
  showError(result, config) {
    let { fetchErrorActionGroup, codeErrorActionGroup } = this.opts
    if (config.opts) {
      fetchErrorActionGroup = config.opts.fetchErrorActionGroup || fetchErrorActionGroup
      codeErrorActionGroup = config.opts.codeErrorActionGroup || codeErrorActionGroup
    }
    if (!result) {
      const { name, opts } = fetchErrorActionGroup
      ActionsService.fetchAndRunGroup(name, opts)
    } else {
      const { name, opts } = codeErrorActionGroup
      ActionsService.fetchAndRunGroup(name, opts)
    }
  }
  async processResult(result, config) {
    const { codesAllowed } = config.opts
    if (result && !result.ok && (!Array.isArray(codesAllowed) || codesAllowed.indexOf(result.status) === -1)) { // not whitelisted
      this.showError(result, config)
      LoggerService.dispatch({
        logType: 'apiError',
        result,
      })
      throw new Error(-1)
    } else if (!result) {
      // log already occurred in the RESTService
      this.showError(result, config)
      throw new Error(-1)
    } else if (!result.ok) { // whitelisted
      LoggerService.dispatch({
        logType: 'apiWhitelist',
        result,
      })
      throw new Error(result.status)
    }
    LoggerService.dispatch({
      logType: 'apiSuccess',
      result,
    })
    return this.getData(result)
  }
  async getData(result) {
    if (result && result.status === 204) {
      return ''
    }
    const data = await result.text()
    try {
      const jsonObj = JSON.parse(data)
      return jsonObj
    } catch (e) {
      try {
        return data
      } catch (esub) {
        return ''
      }
    }
  }
  async getResult(result, config) {
    try {
      const data = await this.processResult(result, config)
      if (config.opts.entireResult) {
        return { data, result }
      }
      return data
    } catch (e) {
      const errorData = await this.getData(result)
      const { message } = e
      if (parseInt(message, 10) !== -1) {
        const error = new Error(message)
        const data = config.opts.entireResult ? { data: errorData, result } : errorData
        error.result = { code: message, data }
        throw error
      }
    }
    if (config.opts.entireResult) {
      return { data: {}, result }
    }
    return {}
  }
  async run(nameOrArgs, opts, tokens, fetchInit = {}) {
    let name = nameOrArgs
    if (typeof nameOrArgs === 'object') {
      ({
        name, opts, tokens, fetchInit,
      } = nameOrArgs)
    }

    const { apis } = window.emAppConfig
    const config = apis[name]
    config.opts = opts || config.opts || {}
    config.tokens = tokens || config.tokens || {}
    config.fetchInit = {
      ...config.fetchInit,
      ...fetchInit,
    }

    const result = await RESTService.run(config)
    const data = this.getResult(result, config)
    return data
  }
}

export default new ApiService()
