import React from 'react'
import { callHandler } from 'common/utils/handlers'
import DSQueryBuilder from '@elliemae/ds-query-builder'

const QueryBuilder = (props) => {
  const {
    managerActions,
    fieldActions,
    fieldData,
    managerData,
    listInfo,
    state,
    field,
    onChange,
    field: {
      id = '',
      container_props = {},
      handlers,
      handlers: {
        on_handle_drop,
        on_handle_drop_filter,
        on_handle_drop_group,
        on_handle_field_change,
        on_handle_rule_container_group,
        on_handle_rule_container_ungroup,
        on_add_filter,
        on_add_rule_container,
        on_remove_filter,
      } = {},
      ui: {
        addFilterMethod = '',
        groupFilterMethod = '',
        fields = [],
        format = '',
        init = null,
        ruleContainerClassName = '',
      } = {},
    } = {},
  } = props

  const args = {
    field,
    managerActions,
    fieldActions,
    fieldData,
    listInfo,
    managerData,
    state,
  }

  const onChangeQueryBuilder = (value) => {
    const { id } = field
    onChange({
      id,
      value,
      ...args,
    })
  }

  const onHandleDrop = (handlers && on_handle_drop)
    ? ({ ...params }) => callHandler('on_handle_drop', { ...args, ...params })
    : () => {}

  const onHandleDropFilter = (handlers && on_handle_drop_filter)
    ? ({ ...params }) => callHandler('on_handle_drop_filter', { ...args, ...params })
    : () => {}

  const onHandleDropGroup = (handlers && on_handle_drop_group)
    ? () => callHandler('on_handle_drop_group', { ...args })
    : () => {}

  const onHandleFieldChange = (handlers && on_handle_field_change)
    ? ({ data }) => callHandler('on_handle_field_change', { ...args, data })
    : () => {}

  const onHandleRuleContainerGroup = (handlers && on_handle_rule_container_group)
    ? () => callHandler('on_handle_rule_container_group', { ...args })
    : () => {}

  const onHandleRuleContainerUnGroup = (handlers && on_handle_rule_container_ungroup)
    ? ({ idGroup }) => callHandler('on_handle_rule_container_ungroup', { ...args, idGroup })
    : () => {}

  const onAddFilter = (handlers && on_add_filter)
    ? ({ ...params }) => callHandler('on_add_filter', { ...args, ...params })
    : () => {}

  const onAddRuleContainer = (handlers && on_add_rule_container)
    ? ({ ...params }) => callHandler('on_add_rule_container', { ...args, ...params })
    : () => {}

  const onRemoveFilter = (handlers && on_remove_filter)
    ? ({ idFilter }) => callHandler('on_remove_filter', { ...args, idFilter })
    : () => {}

  return (
    <DSQueryBuilder
      addFilterMethod={addFilterMethod}
      containerProps={{
        id,
        ...container_props,
      }}
      fields={fields}
      format={format}
      groupFilterMethod={groupFilterMethod}
      handleDrop={onHandleDrop}
      handleDropFilter={onHandleDropFilter}
      handleDropGroup={onHandleDropGroup}
      handleFieldChange={onHandleFieldChange}
      handleRuleContainerOperator={onHandleRuleContainerGroup}
      handleRuleContainerUnGroup={onHandleRuleContainerUnGroup}
      init={init}
      onAddFilter={onAddFilter}
      onAddRuleContainer={onAddRuleContainer}
      onChange={onChangeQueryBuilder}
      onRemoveFilter={onRemoveFilter}
      ruleContainerClassName={ruleContainerClassName}
    />
  )
}

export default QueryBuilder
