import React from 'react'
import DSLoadingIndicator from '@elliemae/ds-loading-indicator'

const LoadingIndicator = ({
  isOpen = false,
  spinner = null,
}) => {
  return (
    <DSLoadingIndicator
      id='em-loading'
      loading={isOpen}
      size='m'
      spinner={spinner}
    />
  )
}

export default LoadingIndicator
