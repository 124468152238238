/* eslint-disable import/no-cycle, max-lines, no-prototype-builtins */
import React from 'react'
import { getListId } from 'common/utils/list'
import { getComponent as getCustomComponent } from 'common/utils/handlers/components'
import { computeFieldCols } from 'common/utils/field'
import { runHandlerVisibleCheck, runHandlerFieldTransform, runHandlerDisableCheck } from 'common/utils/handlers'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'
import * as Fields from './index'

const lookupTable = {
  password: 'TextInput',
  list_group: 'ListGroup',
  field_group: 'FieldGroup',
  input_group: 'InputGroup',
  text: 'TextInput',
  zip: 'TextInput',
  phone: 'TextInput',
  workphone: 'TextInput',
  ssn: 'TextInput',
  email: 'TextInput',
  string: 'TextInput',
  number: 'TextInput',
  integer: 'TextInput',
  unsignedInteger: 'TextInput',
  thousandSeparatorInteger: 'TextInput',
  unsignedThousandSeparatorInteger: 'TextInput',
  percent: 'TextInput',
  percent3: 'TextInput',
  year: 'TextInput',
  units: 'TextInput',
  currency: 'TextInput',
  currencyNoDecimal: 'TextInput',
  negativeCurrency: 'TextInput',
  negativeCurrencyNoDecimal: 'TextInput',
  creditScore: 'TextInput',
  accno: 'TextInput',
  decimal1: 'TextInput',
  decimal2: 'TextInput',
  decimal3: 'TextInput',
  icon: 'Icon',
  iconButton: 'IconButton',
  null: 'Label',
  textarea: 'TextArea',
  dropdown: 'SelectDropdown',
  enum: 'SelectDropdown',
  datepicker: 'DatePicker',
  querybuilder: 'QueryBuilder',
  datagrid: 'DataGrid',
  checkboxgroup: 'CheckboxGroup',
  timepicker: 'TimePicker',
  buttongroup: 'ButtonGroup',
  separator: 'Separator',
  pill: 'Pill',
  groupbox: 'GroupBox',
  conversationlog: 'ConversationLog',
  image: 'Image',
  zipcodesearch: 'ZipCodeSearch',
  combofreesolo: 'ComboBoxFreeSolo',
}

const groupItemsListLookup = {
  buttongroup: 'buttons',
  checkboxgroup: 'checkboxes',
  groupbox: 'fields',
  radioGroup: 'options',
  field_group: 'fields',
}

const GetUnknownComponent = (fieldType, widgetType) => {
  const Unknown = () => (
    <div className='grid-x grid-margin-x error'>
      Type not handled: &apos;
      {widgetType}
      {' '}
      {fieldType}
      &apos;
    </div>
  )
  return Unknown
}

const lookupComponent = (fieldType, widgetType) => {
  const capitalizedWidget = widgetType.charAt(0).toUpperCase() + widgetType.slice(1)
  const fieldComponent = Fields[lookupTable[widgetType]] || Fields[capitalizedWidget]
  return fieldComponent || GetUnknownComponent(fieldType, widgetType)
}

const getComponent = ({ type, list, ui } = {}, listInfo) => {
  // Determine the component to render
  let widgetType = type || ''
  if (!listInfo && list) {
    widgetType = 'list_group'
  } else if (ui && ui.widget) {
    widgetType = ui.style ? ui.style : ui.widget
  }

  const CustomComponent = getCustomComponent(widgetType)
  if (CustomComponent) {
    widgetType = 'custom'
  }

  const Component = widgetType === 'custom'
    ? CustomComponent
    : lookupComponent(type, widgetType)

  return { Component, widgetType }
}

const isFieldDisabled = (field, managerActions, managerData, fieldData, state) => {
  const currentFieldData = fieldData[field.id] || {}
  let isDisabled = !!field.disabled
  if (currentFieldData.hasOwnProperty('disabled')) {
    isDisabled = currentFieldData.disabled
  } else if (field.disable_check) {
    isDisabled = runHandlerDisableCheck(field, managerActions, managerData, fieldData, state)
  }
  return isDisabled
}

const setComponentsDisabledFlag = (field, managerActions, managerData, fieldData, state) => {
  const widgetType = field.ui && field.ui.widget ? field.ui.widget : field.type
  const groupItemsListKey = groupItemsListLookup[widgetType]

  field.disabled = isFieldDisabled(field, managerActions, managerData, fieldData, state) // Run disable check of field level
  if (groupItemsListKey) { // For groups like radiogroup, checkboxgroup, buttongroup loop through each item and run disable check
    const groupItems = field[groupItemsListKey]
    groupItems.forEach((item) => {
      item.disabled = isFieldDisabled(item, managerActions, managerData, fieldData, state) // Run disable check on each item
      item.disabled = field.disabled ? field.disabled : item.disabled // Override item disable flag if group component is disabled
    })
  }
}

const isFieldVisible = (field, managerData, fieldData, listInfo, state) => {
  let isVisible = field.hasOwnProperty('visible') ? field.visible : true
  const currentFieldData = fieldData[field.id] || {}
  if (currentFieldData.hasOwnProperty('visible')) {
    isVisible = currentFieldData.visible
  } else if (field.visible_check) {
    isVisible = runHandlerVisibleCheck(field, managerData, fieldData, listInfo, state)
  }
  return isVisible
}

/* eslint-disable-next-line max-statements */
const Field = ({
  field,
  managerActions,
  fieldActions,
  managerData,
  fieldData,
  listInfo,
  inFieldGroup,
  state,
  onChange: customOnChange,
}) => {
  if (!isFieldVisible(field, managerData, fieldData, listInfo, state)) {
    return null
  }
  // clone field object
  field = cloneDeep(field)

  // Set the list id on the field if this field is part of a list
  if (listInfo && !/-index-[0-9]+$/.test(field.id)) {
    field.id = getListId(field, listInfo)
  }

  // Apply a transformation on the field
  if (field.transform) {
    field = runHandlerFieldTransform(field, managerData, fieldData, listInfo, state)
  }

  // Set field disable property from field data or disable_check handler
  setComponentsDisabledFlag(field, managerActions, managerData, fieldData, state)

  const { Component, widgetType } = getComponent(field, listInfo)

  if (widgetType === 'hide') {
    return null
  }

  let composedOnChange = fieldActions.setField
  if (customOnChange) {
    composedOnChange = ({ ...rest }) => {
      customOnChange(rest.value)
      fieldActions.setField(rest)
    }
  }

  const TheView = (
    <Component
      field={field}
      fieldActions={fieldActions}
      fieldData={fieldData}
      fieldGroup={field}
      listInfo={listInfo}
      managerActions={managerActions}
      managerData={managerData}
      onBlur={fieldActions.onBlur}
      onChange={composedOnChange}
      onFocus={fieldActions.onFocus}
      state={state}
    />
  )

  if (!inFieldGroup && !{ field_group: true, list_group: true }[widgetType]) {
    const customClass = field.ui && field.ui.class
    const className = classNames(
      'row field', {
        [customClass || '']: customClass,
      },
    )
    return (
      <div className={className}>
        <div className={computeFieldCols([field])}>
          {TheView}
        </div>
      </div>
    )
  }

  return TheView
}

export default Field
