import React from 'react'
import {
  PillGroup as DSPillGroup,
  Pill,
  RemovablePill,
  DropdownPill,
} from '@elliemae/ds-basic/Pills'

const PillGroup = ({
  field: {
    id = '',
    container_props = {},
    ui: {
      onFocusNextGroup,
      onFocusPreviousGroup,
      onFocusGroupSet,
      pills = [],
    },
  },
}) => {
  return (
    <DSPillGroup
      containerProps={{
        id,
        ...container_props,
      }}
      onFocusGroupSet={onFocusGroupSet}
      onFocusNextGroup={onFocusNextGroup}
      onFocusPreviousGroup={onFocusPreviousGroup}
    >
      {
        pills.map((pill, index) => {
          const {
            widget,
            ...otherProps
          } = pill
          let TypeOfPill = Pill

          switch (widget) {
            case 'dropdownPill':
              TypeOfPill = DropdownPill
              break
            case 'pill':
              TypeOfPill = Pill
              break
            case 'removablePill':
              TypeOfPill = RemovablePill
              break
            default:
              break
          }

          return (
            <TypeOfPill
              key={id || index}
              {...otherProps}
            />
          )
        })
      }
    </DSPillGroup>
  )
}

export default PillGroup
