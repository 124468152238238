import React, { useCallback } from 'react'
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps'
import { getValue } from 'common/utils/field'
import { getErrorMessages } from 'common/utils/handlers/messages'
import { runHandlerValue } from 'common/utils/handlers'

import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import DSLargeInputText from '@elliemae/ds-basic/form/LargeInputText'
import { debounce } from 'lodash'

const TextArea = ({
  field,
  field: {
    id = '',
    container_props = {},
  },
  fieldActions,
  fieldData,
  onBlur,
  onChange,
  onFocus,
  managerActions,
  listInfo,
}) => {
  const {
    disabled = false,
    label = '',
    required = false,
  } = field

  const propValue = field.value_handler ? runHandlerValue(field, fieldData) : getValue(fieldData, field.id)
  const [value, setValue] = useDerivedStateFromProps(propValue)

  const ui = field.ui || {}
  const { auto_focus, disable_paste } = ui
  const actualField = fieldData[field.id] || {}
  const name = ui.name || field.id
  const errors = actualField.errors || []
  const feedbackMessage = field.feedback_message || ''

  const fireHandler = useCallback(debounce((handler, newValue, isBlur) => handler({
    id: field.id,
    value: newValue,
    field,
    fieldActions,
    managerActions,
    listInfo,
    isBlur,
  }), 300), [])

  const onChangeTextArea = (event) => {
    const newValue = event.target.value
    if (field.pattern) {
      const pattern = new RegExp(`^${field.pattern}*$`, 'g')
      if (newValue !== '' && !pattern.test(newValue)) {
        event.preventDefault()
        return
      }
    }
    setValue(newValue)
    fireHandler(onChange, newValue, true)
  }

  const onBlurTextArea = (event) => {
    fireHandler(onBlur, event.target.value)
  }

  const onFocusTextArea = (event) => {
    fireHandler(onFocus, event.target.value)
  }

  const onPaste = (event) => {
    if (disable_paste) {
      event.preventDefault(event)
    }
  }

  return (
    <DSFormItemLayout
      autoFocus={auto_focus}
      disabled={disabled}
      extraInputProps={{
        maxLength: field.max_length,
        autoFocus: auto_focus,
        name,
        onPaste,
        onChange: onChangeTextArea,
        onKeyUp: onChangeTextArea,
        containerProps: {
          id,
          ...container_props,
        },
      }}
      feedbackMessage={feedbackMessage}
      floatingLabel
      hasError={errors.length > 0}
      inputComponent={DSLargeInputText}
      labelText={label}
      name={name}
      onBlur={onBlurTextArea}
      onChange={onChangeTextArea}
      onFocus={onFocusTextArea}
      onKeyUp={onChangeTextArea}
      required={required}
      validationMessage={getErrorMessages(field, errors, managerActions)}
      value={value}
    />
  )
}

export default TextArea
