import React from 'react'

const WidgetView = ({
  sectionData,
  screenGroups,
  size,
  screen,
  pos,
  componentStructure,
  managerData,
  fieldData,
  fieldActions,
  managerActions,
  TheView,
  state,
}) => {
  return (
    <TheView
      componentStructure={componentStructure}
      fieldActions={fieldActions}
      fieldData={fieldData}
      managerActions={managerActions}
      managerData={managerData}
      pos={pos}
      screen={screen}
      screenGroups={screenGroups}
      sectionData={sectionData}
      size={size}
      state={state}
    />
  )
}

export default WidgetView
