export const SET_STATE_INIT = 'SET_STATE_INIT'
export const RESET_STATE_TO_INIT = 'RESET_STATE_TO_INIT'

// field
export const CLEAR_HIDDEN_FIELDS = 'CLEAR_HIDDEN_FIELDS'
export const REMOVE_LIST_ITEMS_FIELDS = 'REMOVE_LIST_ITEMS_FIELDS'
export const REMOVE_LIST_ITEM_FIELDS = 'REMOVE_LIST_ITEM_FIELDS'
export const CLEAR_LIST_ITEM_FIELDS = 'CLEAR_LIST_ITEM_FIELDS'
export const CLEAR_GROUPED_FIELDS = 'CLEAR_GROUPED_FIELDS'
export const SET_FIELD = 'SET_FIELD'
export const SET_FIELDS = 'SET_FIELDS'
export const LOAD_FIELDS = 'LOAD_FIELDS'
export const DISABLE_FIELDS = 'DISABLE_FIELDS'
export const CLEAR_FIELD = 'CLEAR_FIELD'
export const CLEAR_FIELDS = 'CLEAR_FIELDS'
export const ENABLE_FIELDS = 'ENABLE_FIELDS'
export const SET_FIELD_DEFINITION = 'SET_FIELD_DEFINITION'
export const FOCUS_FIELD = 'FOCUS_FIELD'
export const RESET = 'RESET'
export const LOAD_DEFAULT_FIELDS = 'LOAD_DEFAULT_FIELDS'
export const SET_NEW_FORM_FIELD_STATE = 'SET_NEW_FORM_FIELD_STATE'
export const CLEAR_FIELD_VALUE = 'CLEAR_FIELD_VALUE'
export const CLEAR_FIELDS_VALUES = 'CLEAR_FIELDS_VALUES'
export const UPDATE_OPTIONS = 'UPDATE_OPTIONS'

// manager
export const LOAD = 'LOAD'
export const WALKTO = 'WALKTO'
export const TRANSITIONTO = 'TRANSITIONTO'
export const INC_LIST = 'INC_LIST'
export const SET_LIST_SIZE = 'SET_LIST_SIZE'
export const ENSURE_LIST_SIZE_GTE = 'ENSURE_LIST_SIZE_GTE'
export const SET_NEXT_ENABLED = 'SET_NEXT_ENABLED'
export const GET_PREV = 'GET_PREV'
export const GET_NEXT = 'GET_NEXT'
export const ZERO_LISTS = 'ZERO_LISTS'
export const DEC_LIST = 'DEC_LIST'
export const SCREEN_ERRORS_COUNT_INCDEC = 'SCREEN_ERRORS_COUNT_INCDEC'
export const SET_FORM_DIRTY_FLAG = 'SET_FORM_DIRTY_FLAG'
export const TOGGLE_CLEAR_REMOVE_DIALOG = 'TOGGLE_CLEAR_REMOVE_DIALOG'
export const SET_SCREEN_LOADING = 'SET_SCREEN_LOADING'
export const TOGGLE_SECTION_VALIDATION = 'TOGGLE_SECTION_VALIDATION'
export const SET_SCREEN_ERRORS_COUNT = 'SET_SCREEN_ERRORS_COUNT'
export const SET_NEW_FORM_MANAGER_STATE = 'SET_NEW_FORM_MANAGER_STATE'
