/* eslint-disable import/no-cycle */
import { runHandler } from 'common/utils/handlers'

const getListSize = ({
  field, screen, managerData, fieldData,
}) => {
  const obj = field || screen
  const objData = managerData
  if (obj.list.size_handler) {
    return runHandler({
      handler: obj.list.size_handler,
      field,
      screen,
      managerData,
      fieldData,
    })
  }

  const offset = (objData.lists && objData.lists[obj.id]) ? objData.lists[obj.id] : 0
  const min = (obj.list.min ? obj.list.min : 0)
  return min + offset
}

// This will provide a listInfo object that does not
// include the isFirst, isLast, or index.. i.e. things
// that are contextual to a given field in the list
const getBaseListInfo = (definition, managerData, fieldData) => {
  const field = /^screen/.test(definition.type) && definition
  const screen = /^field/.test(definition.type) && definition
  const size = getListSize({
    field, screen, managerData, fieldData,
  })
  return {
    ...definition.list,
    size,
    id: definition.id,
  }
}

const getListId = (field, listInfo) => `${field.id}-list-${listInfo.id}-index-${listInfo.index}`

const getCellId = (field, cellInfo) => `${field.id}-row-${cellInfo.rowIndex}-column-${cellInfo.columnIndex}`

export {
  getListSize, getListId, getCellId, getBaseListInfo,
}
