const isIosDevice = (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i))
const isIPad = window.navigator.userAgent.match(/iPad/i)
const isIPhone = window.navigator.userAgent.match(/iPhone/i)

export {
  isIosDevice,
  isIPad,
  isIPhone,
}

// beforeunload is not supported in ios devices, use pagehide
export const getBeforeUnloadEvent = () => {
  return isIosDevice ? 'pagehide' : 'beforeunload'
}
