/* eslint-disable consistent-return,no-console */
import apiService from 'apiService/ApiService'
import sessionService from 'sessionService/SessionService'
import { isActiveSession } from 'utilities/auth'
import queryParams from 'utilities/queryParams'

const { authentication } = window.emAppConfig
const AUTH_TOKEN = authentication.authToken
const TOKEN_KEY = authentication.tokenKey
const CLIENT_ID = authentication.clientId
const SCOPE = authentication.scope
const RESPONSE_TYPE = authentication.responseType
const LOGIN_URL = authentication.loginUrl
const DEFAULT_LOGIN_DEST = authentication.defaultLoginDest

class AuthenticationService {
  constructor() {
    this.params = queryParams(window.location.search)
    this.tokenKey = TOKEN_KEY
  }
  getToken(key = this.tokenKey) {
    const token = sessionStorage.getItem(key)
    if (token) {
      return JSON.parse(token)
    }
  }
  setToken(key, token) {
    sessionStorage.setItem(key, token)
  }
  removeToken(key) {
    sessionStorage.removeItem(key)
  }
  async fetchToken() {
    try {
      const token = this.checkToken()
      if (token) {
        return token
      }
      return await this.fetchGuestToken()
    } catch (e) {
      console.error(e)
    }
  }
  setAuthToken(token) {
    this.setToken(this.tokenKey, token)
  }
  clearToken() {
    this.removeToken(this.tokenKey)
  }
  checkToken() {
    return this.getToken(this.tokenKey)
  }
  isGuestToken() {
    const token = this.getToken()
    return token && token.guest
  }
  async fetchGuestToken() {
    try {
      // fetch OAPI guest access token
      const tokenData = await apiService.run('getOpenAPIToken')
      const value = tokenData[AUTH_TOKEN].replace(/(bearer)(\s)*/ig, '')
      const token = JSON.stringify({
        value,
        guest: true,
      })
      this.setAuthToken(token)
      return { value, guest: true }
    } catch (e) {
      console.error('Failed to get guest token: ', e)
    }
  }
  // eslint-disable-next-line max-statements
  async login(_window = window) {
    if (isActiveSession()) {
      this.ignoreCode = true
      this.logout()
      return
    }
    if (!this.ignoreCode && this.params.code) {
      const { code } = this.params
      const redirectUri = encodeURIComponent(_window.location.href.replace(/(&|\?)code=.*$/g, ''))
      const formdata = `grant_type=authorization_code&client_id=${CLIENT_ID}&redirect_uri=${redirectUri}&code=${code}`
      const tokenData = await apiService.run('getOpenAPIToken', undefined, undefined, { body: formdata })
      const token = JSON.stringify({
        value: tokenData.access_token,
        guest: false,
      })
      this.setAuthToken(token)

      const dest = this.params.dest || DEFAULT_LOGIN_DEST
      const query = this.params.query
        ? `&${this.params.query}=${this.params[this.params.query]}` : ''
      _window.location.href = `${dest}?${query}`
      return
    }

    const hrefStr = `${_window.location.protocol}//${_window.location.host}/login/${_window.location.search}`
      .replace(/&?code=[A-Za-z0-9_-]+/, '')

    const redirectUri = encodeURIComponent(hrefStr)

    const query = `?client_id=${CLIENT_ID}&response_type=`
      + `${RESPONSE_TYPE}&redirect_uri=${redirectUri}&scope=${SCOPE}`

    const cacheBustRandomParam = `&bust=${Math.floor(Math.random() * 1000)}`
    _window.location.href = `${LOGIN_URL}${query}${cacheBustRandomParam}`
  }
  async introspect() {
    const formData = `client_id=${CLIENT_ID}&token=${this.getToken().value}`
    await apiService.run('introspectToken', undefined, undefined, { body: formData })
  }
  async logout() {
    let token = await this.fetchToken()
    if (typeof token === 'string') {
      token = JSON.parse(token)
    }
    const clientID = CLIENT_ID || 'srtrz0d2'
    if (token && token.value) {
      await apiService.run(
        'invalidateToken', undefined, undefined,
        {
          body: `token=${token.value}&client_id=${clientID}`,
        },
      )
    }

    sessionService.disableSaveStateBeforeUnload()
    this.clearToken()
    sessionStorage.clear()

    this.login()
  }
}

export default new AuthenticationService()
