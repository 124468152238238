import { set } from 'lodash'
import getSavedState from 'utilities/getSavedState'

export const pageActions = {
  PAGE_SET: 'PAGE_SET',
}

const DEFAULT_STATE = {
  session: {
  },
}

const initialState = getSavedState('page', DEFAULT_STATE)

export default function (state = initialState, action) {
  switch (action.type) {
    case pageActions.PAGE_SET:
      if (action.payload.hash) {
        const newState = { ...state }
        const { hash, data } = action.payload
        const [name] = hash.split('.')
        newState[name] = { ...newState[name] }
        return set(newState, hash, data)
      }
      return { ...state, ...action.payload }
    default:
      return state
  }
}
