/* eslint-disable guard-for-in,no-restricted-syntax */
import { GET_NEXT, GET_PREV } from '../constants/ActionConstants'

const subscriptionsClearPostEvent = {
  GET_NEXT: [GET_NEXT, GET_PREV],
  GET_PREV: [GET_NEXT, GET_PREV],
}

class EventService {
  constructor() {
    this.subscriptions = {}
  }
  subscribe(event, name, handler) {
    if (!Array.isArray(this.subscriptions[event])) {
      this.subscriptions[event] = []
    }
    const subscriberExists = !this.subscriptions[event].every((subscriber) => {
      return subscriber.name !== name
    })
    if (subscriberExists) {
      return
    }
    this.subscriptions[event].push({ name, handler })
  }
  async notify(store, event) {
    if (Array.isArray(this.subscriptions[event])) {
      // eslint-disable-next-line no-loops/no-loops
      for (const i in this.subscriptions[event]) {
        const subscriber = this.subscriptions[event][i]
        // eslint-disable-next-line no-await-in-loop
        const result = await subscriber.handler(store, event)
        if (!result) {
          return false
        }
      }
    }
    this.clearSubscriptions(event)
    return true
  }
  clearSubscriptions(event) {
    if (subscriptionsClearPostEvent[event]) {
      Object.values(subscriptionsClearPostEvent[event])
        .forEach(sub => delete this.subscriptions[sub])
    }
  }
}

export default new EventService()
