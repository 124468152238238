const routes = []
const buildRoutes = (route, path = '/') => {
  const segment = route.segment ? `${route.segment}/` : ''
  if (!route.routes) {
    routes.push({
      path: `${path}${segment}`,
      ...route,
    })
  } else {
    route.routes.forEach((c) => {
      buildRoutes(c, `${path}${segment}`)
    })
  }
}

export default function (menu) {
  menu.forEach((r) => {
    buildRoutes(r)
  })
  return routes
}
