/* eslint-disable max-statements, max-lines */
import React, { useState } from 'react'
import { getValue } from 'common/utils/field'
import { callHandler } from 'common/utils/handlers'
import { getCellId } from 'common/utils/list'
import { DSDataGrid } from '@elliemae/ds-datagrids'
import Field from './Field' // eslint-disable-line import/no-cycle

let fieldDataLatest = null

const DataGrid = (props) => {
  const {
    managerActions,
    fieldActions,
    fieldData,
    managerData,
    listInfo,
    state,
    field,
    field: {
      id: dataGridId = '',
      container_props = {},
      handlers,
      handlers: {
        on_column_row_edit,
        on_column_row_edited,
        on_reorder,
        get_data,
        get_initial_rows,
        on_row_select,
        custom_handlers = {},
      } = {},
      columns = [],
      ui: {
        rows = [],
        rowKey = 'id',
        schema = {},
        className = '',
        renderToolbar = undefined,
        fluidHeight = true,
        height = undefined,
        onChange = () => {},
        options: {
          infiniteScrolling = false,
          paginated = false,
          pageLength = 10,
          editable = false,
          sortable = 'disabled',
          dragAndDropRows = false,
          rowSpacing = 'tight',
          selectable = false,
          selectAll = false,
          selections = [],
          selectedRows = [],
          resizeableColumns = false,
          showHideColumns = false,
          dragAndDropColumns = false,
          swapColumns = false,
          searchGrid = false,
          searchFilters = false,
          scrollHeight = undefined,
          multiSelectComponent = undefined,
        } = {},
      },
    },
  } = props
  const args = {
    field,
    managerActions,
    fieldActions,
    fieldData,
    listInfo,
    managerData,
    state,
  }

  fieldDataLatest = fieldData

  const [displayColumns, setDisplayColumns] = useState([])

  const onColumRowEditHandler = (handlers && on_column_row_edit)
    ? rowData => callHandler('on_column_row_edit', { ...args, rowData })
    : () => {}

  const onColumRowEditedHandler = (handlers && on_column_row_edited)
    ? rowData => callHandler('on_column_row_edited', { ...args, rowData })
    : () => {}

  const onReorderHandler = (handlers && on_reorder)
    ? () => callHandler('on_reorder', { ...args })
    : () => {}

  const onGetDataHandler = (handlers && get_data)
    ? () => callHandler('get_data', { ...args })
    : () => {}

  const onRowSelectHandler = (handlers && on_row_select)
    ? rowData => callHandler('on_row_select', { ...args, rowData })
    : () => {}

  const createField = ({ customField, columnInfo }) => {
    const id = getCellId(customField, columnInfo)
    const newField = {
      ...customField,
      value: columnInfo.value,
      id,
    }
    const customChange = () => {
      const value = !getValue(fieldDataLatest, newField.id)
      columnInfo.onValue(value, columnInfo.rowData)
    }
    return (
      <Field
        field={newField}
        fieldActions={fieldActions}
        fieldData={fieldDataLatest}
        fieldGroup={newField}
        listInfo={listInfo}
        managerActions={managerActions}
        managerData={managerData}
        onChange={customChange}
        state={state}
      />
    )
  }

  const renderRows = () => {
    if (handlers && get_initial_rows) {
      const initialRows = callHandler('get_initial_rows', { ...args }) || []
      return initialRows
    }
    return rows
  }

  const getColumns = () => {
    if (!displayColumns.length && columns.length) {
      const parseColumns = columns.map((column) => {
        let parseColumn = { ...column }
        const { customField } = column
        if (customField) {
          parseColumn = {
            ...parseColumn,
            customEditor: columnInfo => createField({
              customField, columnInfo,
            }),
          }
        }
        return parseColumn
      })
      setDisplayColumns(parseColumns)
      return parseColumns
    }
    return displayColumns
  }

  if (!fieldDataLatest) return null

  return (
    <DSDataGrid
      className={className}
      columns={getColumns()}
      containerProps={{
        id: dataGridId,
        ...container_props,
      }}
      customHandlers={custom_handlers}
      dragAndDropColumns={dragAndDropColumns}
      dragAndDropRows={dragAndDropRows}
      editable={editable}
      fluidHeight={fluidHeight}
      getData={onGetDataHandler}
      handleSelect={onRowSelectHandler}
      height={height}
      infiniteScrolling={infiniteScrolling}
      multiSelectComponent={multiSelectComponent}
      onChange={onChange}
      onColumnRowEdit={onColumRowEditHandler}
      onColumnRowEdited={onColumRowEditedHandler}
      onReorder={onReorderHandler}
      pageLength={pageLength}
      paginated={paginated}
      renderToolbar={renderToolbar}
      resizeableColumns={resizeableColumns}
      rowKey={rowKey}
      rows={renderRows()}
      rowSpacing={rowSpacing}
      schema={schema}
      scrollHeight={scrollHeight}
      searchFilters={searchFilters}
      searchGrid={searchGrid}
      selectable={selectable}
      selectAll={selectAll}
      selectedRows={selectedRows}
      selections={selections}
      showHideColumns={showHideColumns}
      sortable={sortable}
      swapColumns={swapColumns}
    />
  )
}

export default DataGrid
