/* eslint-disable camelcase */
import React from 'react'
import DSTooltip from '@elliemae/ds-basic/Tooltip'
import { PopperPositions, PopperInteractions } from '@elliemae/ds-basic/Popper'
import {
  DEFAULT_DELAY_OPEN,
  DEFAULT_DELAY_CLOSE,
} from '@elliemae/ds-shared/constants'
import Field from './Field'

const containerStyle = {
  display: 'inline-block',
  position: 'relative',
}

const Tooltip = ({
  managerActions,
  fieldActions,
  managerData,
  fieldData,
  listInfo,
  state,
  field,
  helpText,
}) => {
  if (helpText) {
    return (
      <div style={containerStyle}>
        <DSTooltip
          title={helpText}
          triggerComponent={<div aria-label={helpText}>i</div>}
        />
      </div>
    )
  }

  const {
    trigger_component = null,
    id = '',
    container_props = {},
    ui: {
      title = '',
      placement = PopperPositions.TOP,
      delayClose = DEFAULT_DELAY_CLOSE,
      delayOpen = DEFAULT_DELAY_OPEN,
      interactionType = PopperInteractions.HOVER,
      springAnimationComponent = undefined,
      showArrow = true,
    },
  } = field


  const renderTriggerComponent = () => {
    if (!trigger_component) return null

    return (
      <div>
        <Field
          field={trigger_component}
          fieldActions={fieldActions}
          fieldData={fieldData}
          fieldGroup={trigger_component}
          listInfo={listInfo}
          managerActions={managerActions}
          managerData={managerData}
          state={state}
        />
      </div>
    )
  }

  return (
    <div style={containerStyle}>
      <DSTooltip
        containerProps={{
          id,
          ...container_props,
        }}
        delayClose={`${delayClose}`}
        delayOpen={`${delayOpen}`}
        interactionType={interactionType}
        placement={placement}
        showArrow={showArrow}
        springAnimationComponent={springAnimationComponent}
        title={title}
        triggerComponent={renderTriggerComponent()}
      />
    </div>
  )
}


export default Tooltip
