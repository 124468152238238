/* eslint-disable import/no-cycle */
import React from 'react'
import { fill } from 'lodash'
import { getListId, getListSize } from 'common/utils/list'
import { getValue, computeFieldCols, getVisibleFields } from 'common/utils/field'
import Field from './Field'

const getStringListGroup = (
  visibleFields,
  size,
  fieldData,
  field,
  TheView,
  wrapperClasses,
  columnClasses,
  fieldActions,
  managerActions,
  managerData,
) => {
  const stringValue = visibleFields.map((visibleField, idx) => {
    const visibleFieldListInfo = {
      ...visibleField.list,
      size,
      index: idx,
      isLast: ((size - 1) === idx),
      isFirst: idx === 0,
      id: visibleField.id,
    }
    visibleField = { ...visibleField }
    visibleField.id = getListId(visibleField, visibleFieldListInfo)
    return getValue(fieldData, visibleField.id)
  }).filter(item => item !== '').join(', ')
  field.type = 'label'
  delete field.list
  field.id = 'custom'
  field.value = stringValue
  return (
    <div className={wrapperClasses}>
      <div className={columnClasses[0]}>
        <Field
          field={field}
          fieldActions={fieldActions}
          fieldData={fieldData}
          managerActions={managerActions}
          managerData={managerData}
        />
      </div>
    </div>
  )
}

const getListGroup = (
  wrapperClasses,
  visibleFields,
  size,
  columnClasses,
  fieldActions,
  fieldData,
  inFieldGroup,
  managerActions,
  managerData,
  state,
) => (
  <div className={wrapperClasses}>
    {visibleFields.map((visibleField, idx) => {
      const visibleFieldListInfo = {
        ...visibleField.list,
        size,
        index: idx,
        isLast: ((size - 1) === idx),
        isFirst: idx === 0,
        id: visibleField.id,
      }
      return (
        <div key={idx} className={columnClasses[idx]}>
          <Field
            field={visibleField}
            fieldActions={fieldActions}
            fieldData={fieldData}
            inFieldGroup={inFieldGroup}
            listInfo={visibleFieldListInfo}
            managerActions={managerActions}
            managerData={managerData}
            state={state}
          />
        </div>
      )
    })}
  </div>
)

const ListGroup = ({
  field, managerActions, fieldActions, managerData, fieldData, listInfo, state,
}) => {
  const size = getListSize({ field, managerData, fieldData })
  const fields = fill(Array(size), { ...field })
  const visibleFields = getVisibleFields(fields, managerData, fieldData, listInfo, state)
  const columnClasses = computeFieldCols(visibleFields)

  if (visibleFields.length === 0) {
    return null
  }

  const inFieldGroup = true
  const wrapperClasses = (field.ui && field.ui.wrapper === false && ' ') || 'grid-x grid-margin-x'
  let widgetType = 'list'
  if (field.ui && field.ui.list_widget) {
    widgetType = field.ui.list_widget
  }
  return (widgetType.toLowerCase() === 'string_list')
    ? getStringListGroup(
      visibleFields,
      size,
      fieldData,
      field,
      wrapperClasses,
      columnClasses,
      fieldActions,
      managerActions,
      managerData)
    : getListGroup(
      wrapperClasses,
      visibleFields,
      size,
      columnClasses,
      fieldActions,
      fieldData,
      inFieldGroup,
      managerActions,
      managerData,
      state)
}

export default ListGroup
