import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { load, getPrev, getNext } from 'common/actions/ManagerActions'
import * as fieldActions from 'common/actions/FieldActions'
import WorkflowView from 'components/core/WorkflowView'

const managerActions = { load, getPrev, getNext }

const mapDispatchToProps = (dispatch) => {
  return {
    fieldActions: bindActionCreators(fieldActions, dispatch),
    managerActions: bindActionCreators(managerActions, dispatch),
  }
}

const mapStateToProps = (state) => {
  return {
    managerData: state.manager,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowView)
