import React from 'react'
import { getValue } from 'common/utils/field'
import { runHandlerValue } from 'common/utils/handlers'
import { cloneDeep, includes } from 'lodash'
import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import DSCheckbox from '@elliemae/ds-basic/form/Checkbox'

const renderParentLabelForCheckbox = (field, fieldData, onChange, type) => {
  if (field.ui && field.ui.type === 'consent') {
    const labelField = cloneDeep(field)
    labelField.label = field.ui.title
    if (!type && fieldData[field.id] && fieldData[field.id].errors && fieldData[field.id].errors.length > 0) {
      type = 'error'
    }
    const classes = `parent-label-checkbox ${type}`
    const isRequiredMarkShown = (fieldData[field.id]
      && includes(fieldData[field.id].errors, 'required')) || (field.ui && field.ui.show_required)
    return (
      <div className={classes}>
        {field.ui.title}
        {' '}
        {(field.required || isRequiredMarkShown)
        && <span className='field-required'>*</span>}
      </div>
    )
  }
  return null
}

const Checkbox = ({
  onChange,
  field,
  field: {
    id = '',
    container_props = {},
    required = false,
  },
  fieldData,
  listInfo,
}) => {
  const handleChange = (e) => {
    onChange({
      id: field.id,
      value: e.target.checked,
      listInfo,
      field,
    })
  }

  const Spacebar = ' '
  let className = ''

  if (field.ui && field.ui.class === 'credit-auth-checkbox') {
    className = `${className} ${field.ui.class}`
  }
  if (field.ui && field.ui.type) {
    className = `${className}`
  }

  const value = field.value_handler ? runHandlerValue(field, fieldData) : getValue(fieldData, field.id)

  return (
    <DSFormItemLayout
      required={required}
      inputComponent={<div />}
      labelText={
        <div
          className={className}
          onKeyUp={(e) => {
            if (e.key === Spacebar || e.key === 'Enter') {
              const radioElem = document.getElementById(field.id)
              const newE = {
                target: {
                  id: field.id,
                  checked: !radioElem.checked,
                },
              }
              handleChange(newE)
              setTimeout(() => radioElem.focus())
            }
          }}
          role='presentation'
        >
          { renderParentLabelForCheckbox(field, fieldData, onChange) }
          <DSCheckbox
            checked={!!value}
            containerProps={{
              id,
              ...container_props,
            }}
            disabled={field.disabled}
            id={field.id}
            labelText={field.label || ''}
            name={field.id}
            onChange={handleChange}
          />
        </div>
      }
    />
  )
}

export default Checkbox
