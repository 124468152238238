/* eslint-disable import/no-cycle */
import {
  REMOVE_LIST_ITEMS_FIELDS,
  ENSURE_LIST_SIZE_GTE,
  SET_LIST_SIZE,
} from '../../constants/ActionConstants'
import { getValue } from '../field'
import { getBaseListInfo } from '../list'

const genericListHandlers = {
  // Generic List
  isLastInList: ({ listInfo }) => (listInfo && listInfo.isLast),
  isFirstInList: ({ listInfo }) => (listInfo && listInfo.isFirst),
  notFirstInList: ({ listInfo }) => (listInfo && listInfo.index > 0),
  addListItem: ({ field, managerActions, listInfo }) => managerActions.incList(
    (!listInfo ? field.on_click.params.id : listInfo.id)),
  clearListItem: ({ fieldActions, listInfo, field }) => {
    if (listInfo) {
      return fieldActions.clearListItemFields(listInfo)
    }
    return fieldActions.clearGroupedFields(field.fields)
  },
  confirmClearListItem: ({ fieldActions, listInfo }) => {
    return fieldActions.toggleClearRemoveDialog(true, listInfo)
  },
  confirmRemoveListItem: ({ fieldActions, listInfo }) => {
    return fieldActions.toggleClearRemoveDialog(true, listInfo, true)
  },
  removeListItem: ({ fieldActions, listInfo }) => fieldActions.removeListItemFields(listInfo),
  notListAtMaxSize: ({
    definition, fieldData, managerData, listInfo,
  }) => {
    if (definition && definition.visible_check.params) {
      const [id] = definition.visible_check.params.id
      const listField = fieldData.definitions[id]
      listInfo = getBaseListInfo(listField, managerData, fieldData)
    }
    return listInfo.size < listInfo.max
  },
  ensureListSize: ({
    id, value, fieldData, /* fieldActions, */ listInfo,
  }) => {
    const definition = fieldData.definitions[id]
    const [size] = definition.on_change.params.size
    if (value === true) {
      return {
        type: ENSURE_LIST_SIZE_GTE,
        payload: {
          id: definition.on_change.params.id,
          size,
        },
      }
    }
    return [
      genericListHandlers.setListSize({
        id, value, fieldData, /* fieldActions, */ listInfo,
      }),
      {
        type: SET_LIST_SIZE,
        payload: {
          id: definition.on_change.params.id,
          size: size - 1,
        },
      },
    ]
  },
  setListSize: ({
    id, value, fieldData, /* fieldActions, */ listInfo,
  }) => {
    const size = getValue(fieldData, id)
    const newSize = value
    let removeIndex = size
    const itemsToRemove = []
    if (newSize > size) {
      return false
    }
    // eslint-disable-next-line no-loops/no-loops
    while (removeIndex > newSize) {
      itemsToRemove.push({
        id: (!listInfo ? fieldData.definitions[id].on_change.params.id : listInfo.id),
        index: removeIndex,
      })

      removeIndex -= 1
    }

    return {
      type: REMOVE_LIST_ITEMS_FIELDS,
      payload: itemsToRemove,
    }
  },
}

export default genericListHandlers
