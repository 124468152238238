import React from 'react'
import ScreenGroupView from 'containers/ScreenGroupContainer'
import ScreenView from 'containers/ScreenContainer'
import RootView from './RootView'
import SectionView from './SectionView'
import EndView from './EndView'

export const getComponentStructure = (path) => {
  return path.map((item) => {
    const { structure } = item
    switch (structure.type) {
      case 'root':
        structure.component = RootView
        break
      case 'section':
        structure.component = SectionView
        break
      case 'screen_group':
        structure.component = ScreenGroupView
        break
      case 'screen':
        structure.component = ScreenView
        break
      case 'end':
        structure.component = EndView
        break
      default:
        break
    }
    return structure
  })
}

class WorkflowView extends React.Component {
  componentDidMount() {
    const {
      managerData,
      managerActions,
      workflowObj,
      components,
      handlers,
      modules,
      messages,
      validators,
    } = this.props
    if (!managerData.loaded) {
      managerActions.load({
        workflowObj,
        components,
        handlers,
        modules,
        messages,
        validators,
      })
    }
  }
  render() {
    const { managerData } = this.props
    if (!managerData.loaded) {
      return null
    }

    const componentStructure = getComponentStructure(managerData.path)
    const TheView = componentStructure[0].component
    return (
      <div>
        <TheView
          componentStructure={componentStructure}
          pos={0}
        />
      </div>
    )
  }
}

export default WorkflowView
