import React from 'react'

const Image = ({field}) => {
const src = field.src;
const alt = field.title;
const title = field.title;
    return (
    <img  src={src} alt={alt} title={title} />
)
    }

export default Image
