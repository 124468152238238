import React from 'react'

const SubmitView = () => {
  return (
    <div>
      <h3>All done! Ready to submit...</h3>
      <button className='button float-center' type='submit'>Submit Loan</button>
    </div>
  )
}

export default SubmitView
