import React from 'react'
import TwoOption from './TwoOption'

const YesNo = ({
  onChange, field, managerActions, fieldActions, fieldData, listInfo,
}) => {
  let yesNoOptions = [
    {
      text: 'Yes',
      value: true,
    },
    {
      text: 'No',
      value: false,
    },
  ]

  yesNoOptions = (field.options ? field.options : yesNoOptions)

  return (
    <TwoOption
      defaultOptions={yesNoOptions}
      field={field}
      fieldActions={fieldActions}
      fieldData={fieldData}
      listInfo={listInfo}
      managerActions={managerActions}
      onChange={onChange}
    />
  )
}

export default YesNo
