import React from 'react'
import DSShuttle from '@elliemae/ds-shuttle'
import { runHandlerValue, runHandlerOptions } from 'common/utils/handlers'
import { getValue } from 'common/utils/field'

const mapOptionToShuttleItem = option => ({
  id: option.id || option.value,
  name: option.name || option.text,
  parent: option.parent || null,
  icon: option.icon || undefined,
  readOnly: option.readOnly || false,
})

const Shuttle = (
  {
    field,
    field: {
      id = '',
      container_props = {},
    },
    managerActions,
    fieldActions,
    fieldData,
    listInfo,
    onChange,
  },
) => {
  let selectedItems = field.value_handler ? runHandlerValue(field, fieldData) : getValue(fieldData, field.id)
  selectedItems = selectedItems || []
  const optionsFromJson = field.options || []
  const options = field.options_handler ? runHandlerOptions(field, {}, fieldData, [], {}) : optionsFromJson
  const onItemsChange = value => onChange({
    id: field.id,
    value,
    field,
    fieldActions,
    managerActions,
    listInfo,
  })

  return (
    <DSShuttle
      containerProps={{
        id,
        ...container_props,
      }}
      items={options.map(mapOptionToShuttleItem)}
      onChange={onItemsChange}
      selectedItems={selectedItems}
      showIcons={false}
    />
  )
}

export default Shuttle
