import React from 'react'
import { getVisibleFields } from 'common/utils/field'
import { DSTabs, DSTab } from '@elliemae/ds-basic/Tabs'
import Field from './Field'

const Tabs = ({
  field,
  field: {
    id = '',
    container_props = {},
    animated = true,
    enableMouseEvents = false,
    type = 'normal',
    tabs = [],
  },
  managerActions,
  fieldActions,
  managerData,
  fieldData,
  listInfo,
  state,
  onChange = () => {},
}) => {
  const visibleTabs = getVisibleFields(tabs, managerData, fieldData, listInfo, state)

  if (!tabs.length || !visibleTabs.length) return null

  const onTabChange = (activeTab) => {
    onChange({
      id,
      value: activeTab,
      listInfo,
      field,
      fieldActions,
      managerActions,
    })
  }

  return (
    <DSTabs
      animated={animated}
      containerProps={{
        id,
        ...container_props,
      }}
      enableMouseEvents={enableMouseEvents}
      onTabChange={onTabChange}
      type={type}
    >
      {
        visibleTabs.map((tab) => {
          const { label = '', id: tabId } = tab
          return (
            <DSTab key={tabId} tabId={tabId} title={label}>
              {tab.fields.map(tabField => (
                <Field
                  key={tabField.id}
                  field={tabField}
                  fieldActions={fieldActions}
                  fieldData={fieldData}
                  fieldGroup={tabField}
                  listInfo={listInfo}
                  managerActions={managerActions}
                  managerData={managerData}
                  state={state}
                />
              ))}
            </DSTab>
          )
        })
      }
    </DSTabs>
  )
}

export default Tabs
