import { combineReducers } from 'redux'
import AspectReducer from 'aspectsService/AspectReducer'
import DataReducer from 'app/DataReducer'
import PageReducer from 'app/PageReducer'
import {
  fieldReducer, managerReducer, metaNamespaceable, metaResettable,
} from '@elliemae/pui-meta'

export const rootReducers = {
  aspects: AspectReducer,
  page: PageReducer,
  data: DataReducer,
  field: metaNamespaceable(metaResettable(fieldReducer({}))),
  manager: metaNamespaceable(metaResettable(managerReducer({ lists: {}, isFormDirty: false }))),
}

export default function combineReducersForApp() {
  return combineReducers(rootReducers)
}
