/* eslint-disable max-lines,guard-for-in,no-restricted-syntax */
import { runHandlerVisibleCheck, runDecoratorValue } from 'common/utils/handlers'
import { applyOverlays } from 'common/utils/overlayUtils'
import {
  getFieldListId, getFieldId, isSet, getVisibleFields as getVisibleOptions,
} from 'common/utils/field'
import WorkflowEngineService from './WorkflowEngineService'
import ScreenEngineService from './ScreenEngineService'

class ManagerService {
  getTheme() {
    return this.theme
  }
  getWorkflowObj() {
    return this.definition
  }
  loadWorkflowObj(workflowObj, state, includeCustomFields = true) {
    const definition = workflowObj.definition || workflowObj
    const customization = workflowObj.customization || null
    this.theme = workflowObj.theme || {}
    ScreenEngineService.load(definition)
    if (customization) {
      const idCache = this.getIdCache()
      // applyMigrationOverlays(idCache, state)
      applyOverlays(idCache, customization.overlays || customization.overlay || [])
      if (includeCustomFields) {
        this.applyCustomFields(idCache, customization.customFields)
      }
    }
    ScreenEngineService.linkRids()
    WorkflowEngineService.load(definition.workflow)
    this.definition = definition
    this.id = WorkflowEngineService.transitionto('start')
  }
  getAutoSetFields(state) {
    const autoSetFields = ScreenEngineService.getAutoSetFields()
    Object.keys(autoSetFields).forEach((autoSetFieldKey) => {
      const managerData = state.manager
      const fieldData = state.field
      const field = autoSetFields[autoSetFieldKey]
      const opts = getVisibleOptions(field.options || [], managerData, fieldData, {}) || []
      if (opts.length === 1 && !isSet(fieldData, field)) {
        autoSetFields[autoSetFieldKey] = {
          id: field.id,
          value: opts[0].value,
          errors: [],
        }
      } else {
        delete autoSetFields[autoSetFieldKey]
      }
    })
    return autoSetFields
  }
  runFieldChecks(getState, clearHidden, skipRequired, isSubmitAction, fieldIds) {
    return ScreenEngineService.runFieldValidators(getState, clearHidden, skipRequired, isSubmitAction, fieldIds)
  }
  walkto(id, state) {
    this.id = WorkflowEngineService.transitionto('walk_to', { id, state })
    return this.id
  }
  transitionto(id, state) {
    this.id = WorkflowEngineService.transitionto(id, { state })
    return this.id
  }
  getNext(state) {
    // eslint-disable-next-line no-loops/no-loops
    do {
      this.id = WorkflowEngineService.transitionto('next_state', { state })
    } while (ScreenEngineService.checkIsEmptyScreen(this.id, state))
    return this.id
  }
  getPrev(state) {
    // eslint-disable-next-line no-loops/no-loops
    do {
      this.id = WorkflowEngineService.transitionto('prev_state', { state })
    } while (ScreenEngineService.checkIsEmptyScreen(this.id, state))
    return this.id
  }
  getPath() {
    const path = WorkflowEngineService.getPath()
    return path.map(id => ({
      id,
      step: WorkflowEngineService.getCurrentStep(),
      structure: this.getById(id),
    }))
  }
  getIdCache() {
    return ScreenEngineService.getIdCache()
  }
  getListIdCache() {
    return ScreenEngineService.getListIdCache()
  }
  getById(id) {
    return ScreenEngineService.getById(id) || { type: id.toLowerCase() }
  }
  getFieldsWithDefault() {
    return ScreenEngineService.getFieldsWithDefault()
  }
  getFieldsToClearValues(getState) {
    const state = getState()
    const managerData = state.manager
    const fieldData = state.field
    const fieldIdsToClear = []
    const listIdsToZero = {}
    Object.keys(fieldData).forEach((fieldId) => {
      const result = this.isHiddenField(fieldId, fieldData, managerData, state)
      // Note: use rid first if this is a rid field
      const fieldDefinition = (fieldData[fieldId] && fieldData[fieldId].field) || fieldData.definitions[fieldId]
      const { errors } = fieldData[fieldId]
      if (result.isHidden
        || (fieldDefinition && fieldDefinition.clear_invalid_value && errors && errors.length > 0)) {
        fieldIdsToClear.push(fieldId)
        if (result.inList) {
          listIdsToZero[getFieldListId(fieldId)] = true
        }
      }
    })
    return { fieldIdsToClear, listIdsToZero: Object.keys(listIdsToZero) }
  }
  isHiddenField(id, fieldData, managerData, state) {
    const handlersRan = {}
    const inList = new RegExp('-list')
    const isAnd = new RegExp('&&')
    const isNegate = new RegExp('^!')
    const result = { inList: false, isHidden: false }

    const handler = ScreenEngineService.getFieldsToAutoClearMap()[getFieldId(id)]
    if (!handler || id === 'definitions') {
      return result
    }

    // Use the visible check (default)
    const { listInfo = {} } = fieldData[id]
    const field = { visible_check: handler }
    if (isAnd.test(handler)) {
      field.visible_check = { handlers_and: handler.split('&&') }
    }
    this.isHiddenFieldRunHandler(handlersRan, handler, isNegate, field, managerData, fieldData, listInfo, state)
    this.isHiddenFieldGetResult(handlersRan, handler, result, inList, id, fieldData)
    return result
  }
  isHiddenFieldGetResult(handlersRan, handler, result, inList, id, fieldData) {
    if (handlersRan[handler]) {
      result.isHidden = true
      if (inList.test(id) && fieldData.definitions[getFieldId(id)].can_clear_list !== false) {
        result.inList = true
      }
    }
  }
  isHiddenFieldRunHandler(handlersRan, handler, isNegate, field, managerData, fieldData, listInfo, state) {
    if (typeof handlersRan[handler] === 'undefined') {
      if (isNegate.test(handler)) {
        field.visible_check.handler = field.visible_check.handler.replace('!', '')
        handlersRan[handler] = runHandlerVisibleCheck(field, managerData, fieldData, listInfo, state)
      } else {
        handlersRan[handler] = !runHandlerVisibleCheck(field, managerData, fieldData, listInfo, state)
      }
    }
  }
  autoNavNext(field) {
    if (field.ui && typeof (field.ui.auto_nav_next) !== 'undefined') {
      return field.ui.auto_nav_next
    }
    return (
      (field.type === 'enum'
      || field.type === 'boolean') && ScreenEngineService.getVisibleCount() <= 1
    )
  }
  applyCustomFields(idCache, customFields = []) {
    const fieldsByAddAfter = customFields.map((field) => {
      if (field.ui && field.ui.decorator) {
        return runDecoratorValue(field)
      }
      return field
    })
      .reduce((map, field) => {
        idCache[field.id] = field
        const fieldKey = field.addAfter ? field.addAfter : `start${field.screenId}`
        if (map[fieldKey]) {
          map[fieldKey].push(field)
        } else {
          map[fieldKey] = [field]
        }
        return map
      }, {})

    Object.entries(fieldsByAddAfter).forEach(([key, value]) => {
      const fieldGroup = idCache[value[0].screenId]
      this.addAfter(fieldGroup, key, value)
    })
  }
  addAfter(fieldGroup, targetFieldId = null, relatedCustomFields) {
    if (targetFieldId === null
      || (relatedCustomFields[0]
      && (targetFieldId === `start${relatedCustomFields[0].screenId}`))) {
      if (fieldGroup.fields[1] && fieldGroup.fields[1].ui && fieldGroup.fields[1].ui.type === 'description') {
        // we don't want our custom fields to display above the screen description if one exists
        fieldGroup.fields.splice(2, 0, ...relatedCustomFields)
      } else if (fieldGroup.fields[0] && fieldGroup.fields[0].ui && fieldGroup.fields[0].ui.type === 'title') {
        // we don't want our custom fields to display above the title if one exists
        fieldGroup.fields.splice(1, 0, ...relatedCustomFields)
      } else {
        // the field is the first one on the screen
        fieldGroup.fields.splice(0, 0, ...relatedCustomFields)
      }
    } else {
      // eslint-disable-next-line consistent-return
      fieldGroup.fields.forEach((field, index) => {
        if (field.id === targetFieldId) {
          if (index + 1 < fieldGroup.fields.length || fieldGroup.type !== 'field_group') {
            fieldGroup.fields.splice(index + 1, 0, ...relatedCustomFields)
            return true
          }
          // last field in the field group, so we are going to add it to the parent
          return false
        } if (field.fields) {
          const success = this.addAfter(field, targetFieldId, relatedCustomFields)
          if (success === false) {
            if (index + 1 < fieldGroup.fields.length || fieldGroup.type !== 'field_group') {
              fieldGroup.fields.splice(index + 1, 0, ...relatedCustomFields)
              return true
            }
            return false
          }
        }
      })
    }
  }
}

export default new ManagerService()
