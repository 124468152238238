import React from 'react'
import {
  DSToast, toast, ToastType,
} from '@elliemae/ds-toast'

const openToastIds = {}

class NotificationToast extends React.Component {
  dismiss() {
    toast.dismiss(this.toastId)
  }
  dismissAll() {
    toast.dismiss()
  }
  renderToast() {
    const {
      messageTitle, messageText, type, autoClose,
    } = this.props
    this.toastId = toast({
      messageTitle,
      messageText,
      type: type || ToastType.INFO,
      autoClose: (typeof autoClose !== 'undefined' ? autoClose : 5000),
      containerId: 'app_engine_toast_container_3',
    })
  }
  render() {
    const { id } = this.props
    if (id && !openToastIds[id]) {
      openToastIds[id] = true
      this.renderToast()
    }
    return (
      <DSToast
        {...this.props}
        containerId='app_engine_toast_container_3'
        enableMultiContainer
      />
    )
  }
}

export default NotificationToast
