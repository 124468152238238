/* eslint-disable no-underscore-dangle,guard-for-in,no-restricted-syntax */
import { get } from 'lodash'
import { formMessages } from '../../constants/Messages'

let messages = formMessages

const errorMessageHandlers = {
  current_year: () => 'the current year',
}

export const getErrorMessages = (field, errors, managerActions) => {
  let _messages = ''
  errors.forEach((error) => {
    let token = field[error] ? field[error] : get(field, `validation_handler.params.${error}`)

    if (typeof messages[error] === 'function') {
      _messages = messages[error]({ field, errors, managerActions })
    } else if (field.validation && field.validation.tokens) {
      let _message = messages[error]
      field.validation.tokens.forEach((fieldValidationToken, index) => {
        _message = _message.replace(`%${index}%`, fieldValidationToken)
      })
      _messages += `${_message} `
    } else {
      if (errorMessageHandlers[field[error]]) {
        token = errorMessageHandlers[field[error]](field)
      }
      if (messages[error]) {
        _messages += `${messages[error].replace(`%${error}%`, token)} `
      } else {
        _messages += `${error} `
      }
    }
  })
  return _messages
}

export const addMessages = (newMessages) => {
  messages = { ...messages, ...newMessages }
}
