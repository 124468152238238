import 'isomorphic-fetch'
import LoggerService from 'loggerService/LoggerService'
import AuthService from 'authenticationService/AuthenticationService'

let instance = null

class RESTService {
  constructor() {
    if (!instance) {
      instance = this
    }
    return instance
  }
  defaultOpts = {
    tokenType: 'Bearer',
    useAuthToken: true,
    ...window.emAppConfig.api.defaultOpts,
  }
  tokenize = (url, tokens) => {
    Object
      .keys(tokens)
      .forEach((k) => {
        if (Array.isArray(tokens[k])) {
          const regex = new RegExp(`(\\[(.*)=:${k}\\])`)
          const match = url.match(regex)
          if (match[2]) {
            const queryParams = tokens[k].reduce((str, token, idx) => {
              str += `${match[2]}=${token}${idx < tokens[k].length - 1 ? '&' : ''}`
              return str
            }, '')
            url = url.replace(regex, queryParams)
          } else {
            url = url.replace(`:${k}`, tokens[k])
          }
        } else {
          url = url.replace(`:${k}`, tokens[k])
        }
      })
    return url
  }
  buildAuthHeaders = async (opts, fetchInit) => {
    let token
    const tokenType = (opts.tokenType !== undefined ? opts.tokenType : this.defaultOpts.tokenType)

    if (!opts.requireToken) {
      token = await AuthService.fetchToken()
    }

    const authTokenString = `${tokenType} ${token.value}`
    if (fetchInit.body && typeof fetchInit.body === 'object') {
      fetchInit.body = JSON.stringify(fetchInit.body)
    }
    return {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...fetchInit.headers,
        Authorization: authTokenString,
      },
    }
  }
  async run({
    url, opts = this.defaultOpts, tokens = {}, fetchInit = {},
  }) {
    let requestHeaders = {}
    let fetchInitMerged = {}

    opts = { ...this.defaultOpts, ...opts }

    url = this.tokenize(url, (tokens))
    url = `${opts.origin}${url}`

    if (opts.useAuthToken !== false) {
      requestHeaders = await this.buildAuthHeaders(opts, fetchInit)
    }

    fetchInitMerged = Object.assign(fetchInit, requestHeaders)

    const result = await fetch(url, fetchInitMerged).catch((e) => {
      LoggerService.dispatch({
        logType: 'apiError',
        error: e,
      })
    })
    return result
  }
}

export default new RESTService()
