// decouple functions from libs so lib functions can be
// swapped out w/o breaking code
import { cloneDeep, isEqual } from 'lodash'

const isIframe = () => window.top !== window.self

export default {}
export {
  cloneDeep,
  isEqual,
  isIframe,
}
