import React from 'react'
import { Pill as DSPill } from '@elliemae/ds-basic/Pills'

const Pill = ({
  field: {
    id = '',
    container_props = {},
    ui: {
      label = '',
      leftAddon,
      rightAddon,
      variant = 'value',
      ...otherProps
    },
  },
}) => {
  return (
    <DSPill
      containerProps={{
        id,
        ...container_props,
      }}
      label={label}
      leftAddon={leftAddon}
      rightAddon={rightAddon}
      variant={variant}
      {...otherProps}
    />
  )
}

export default Pill
