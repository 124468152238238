import React from 'react'
import DSButtonGroup from '@elliemae/ds-basic/ButtonGroup'
import DSButton from '@elliemae/ds-basic/Button'
import {
  runHandlerClick,
} from 'common/utils/handlers'
import Icon from './Icon'

const ButtonGroup = ({
  field: {
    id = '',
    buttonType = 'primary',
    container_props = {},
    orientation = 'horizontal',
    size = 'm',
    buttons = [],
  },
  managerActions,
  fieldActions,
  managerData,
  fieldData,
  listInfo,
  state,
}) => {
  if (!buttons.length) return null
  return (
    <DSButtonGroup
      buttonType={buttonType}
      containerProps={{
        id,
        ...container_props,
      }}
      orientation={orientation}
      size={size}
    >
      {
        buttons.map((button, index) => {
          const {
            disabled,
            icon,
            id,
            label,
            on_click,
          } = button

          let clickHandler = () => {}
          if (on_click) {
            clickHandler = () => runHandlerClick(button, managerActions, fieldActions, fieldData, listInfo, managerData, state)
          }

          let iconField
          if (icon) {
            iconField = (<Icon field={icon} />)
          }

          return (
            <DSButton
              key={id || index}
              disabled={disabled}
              icon={iconField}
              id={id || index}
              labelText={label}
              onClick={clickHandler}
            />
          )
        })
      }
    </DSButtonGroup>
  )
}

export default ButtonGroup
