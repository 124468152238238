import { includes } from 'lodash'
import {
  LOAD,
  WALKTO,
  TRANSITIONTO,
  GET_NEXT,
  GET_PREV,
  INC_LIST,
  DEC_LIST,
  SET_LIST_SIZE,
  ENSURE_LIST_SIZE_GTE,
  ZERO_LISTS,
  SCREEN_ERRORS_COUNT_INCDEC,
  SET_NEXT_ENABLED,
  SET_FORM_DIRTY_FLAG,
  TOGGLE_SECTION_VALIDATION,
  SET_SCREEN_LOADING,
  TOGGLE_CLEAR_REMOVE_DIALOG,
  RESET,
  SET_SCREEN_ERRORS_COUNT,
  SET_NEW_FORM_MANAGER_STATE,
} from '../constants/ActionConstants'

const DEFAULT_STATE = { lists: {}, isFormDirty: false }
let INITIAL_STATE = {}

export default function manager(managerState = INITIAL_STATE) {
  INITIAL_STATE = managerState
  return (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case SET_NEW_FORM_MANAGER_STATE: {
        return {
          ...state,
          ...{
            screen: {},
            theme: {},
            path: [{ structure: action.payload }],
          },
        }
      }
      case RESET:
        return (action.payload.length && includes(action.payload, 'manager') ? DEFAULT_STATE : state)
      case ZERO_LISTS:
        action.payload.forEach(id => (state.lists[id] = 0))
        return {
          ...state,
        }
      case INC_LIST:
        state.lists[action.id] = (state.lists[action.id] ? state.lists[action.id] : 0) + 1
        return {
          ...state,
        }
      case SET_LIST_SIZE:
        state.lists[action.payload.id] = action.payload.size
        return {
          ...state,
        }
      case ENSURE_LIST_SIZE_GTE:
        if (!state.lists[action.payload.id] || state.lists[action.payload.id] < action.payload.size) {
          state.lists[action.payload.id] = action.payload.size
        }
        return {
          ...state,
        }
      case DEC_LIST:
        state.lists[action.id] = Math.max(0, (state.lists[action.id] ? state.lists[action.id] : 0) - 1)
        return {
          ...state,
        }
      case SET_NEXT_ENABLED:
        return {
          ...state,
          ...{ screen: { ...state.screen, ...{ nextEnabled: action.payload } } },
        }
      case SET_SCREEN_LOADING:
        return {
          ...state,
          ...{ screen: { ...state.screen, ...{ showLoading: action.payload } } },
        }
      case WALKTO:
      case TRANSITIONTO:
      case GET_NEXT:
      case GET_PREV:
        return {
          ...state,
          ...{ screen: { ...state.screen, ...{ nextEnabled: true } } },
          ...action.payload,
        }
      case SCREEN_ERRORS_COUNT_INCDEC:
        if (!state.screen[state.id]) {
          state.screen[state.id] = { errorCount: 0 }
        }
        state.screen[state.id].errorCount += action.payload.errorIncDec
        if (state.screen[state.id].errorCount < 0) {
          state.screen[state.id].errorCount = 0
        }
        return {
          ...state,
        }
      case SET_SCREEN_ERRORS_COUNT:
        if (!state.screen[action.payload.id]) {
          state.screen[action.payload.id] = { errorCount: 0 }
        }
        state.screen[action.payload.id].errorCount += action.payload.errorIncDec
        if (state.screen[action.payload.id].errorCount < 0) {
          state.screen[action.payload.id].errorCount = 0
        }
        return {
          ...state,
        }
      case SET_FORM_DIRTY_FLAG:
      case LOAD:
      case TOGGLE_SECTION_VALIDATION:
        return {
          ...state,
          ...action.payload,
        }
      case TOGGLE_CLEAR_REMOVE_DIALOG:
        return {
          ...state,
          ...{ toggleClearRemoveDialog: action.payload },
        }
      default:
        return state
    }
  }
}
