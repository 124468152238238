/* eslint-disable max-lines, import/no-cycle, max-len */
// Base Field Handlers
import fieldHandlers from './field'
import screenHandlers from './screen'
import listHandlers from './list'

let handlers = {
  ...fieldHandlers,
  ...screenHandlers,
  ...listHandlers,
}

export const addHandlers = (newHandlers) => {
  handlers = { ...handlers, ...newHandlers }
}

export const runHandler = ({
  handler,
  managerData,
  managerActions,
  fieldData,
  listInfo,
  state,
  definition,
  id,
  fieldActions,
  dispatch,
  getState,
  isBlur,
  value,
  transition,
  field,
}) => {
  if (handler in handlers) {
    return handlers[handler]({
      handler,
      managerData,
      managerActions,
      fieldData,
      listInfo,
      state,
      definition,
      id,
      fieldActions,
      dispatch,
      getState,
      isBlur,
      value,
      transition,
      field,
    })
  }
  return false
}

export const handlerExists = handler => handler in handlers

export const runHandlerVisibleCheck = (obj, managerData, fieldData, listInfo, state) => {
  const definition = obj
  const isOr = new RegExp('\\|\\|')
  if ('visible_check' in obj && 'handler' in obj.visible_check) {
    if (isOr.test(obj.visible_check.handler)) {
      const result = !obj.visible_check.handler.split('||').every(handler => !runHandler({
        handler,
        managerData,
        fieldData,
        listInfo,
        state,
        definition,
      }))
      return result
    }
    return !!runHandler({
      handler: obj.visible_check.handler, managerData, fieldData, listInfo, state, definition,
    })
  } if ('visible_check' in obj && 'handlers_and' in obj.visible_check) {
    const result = obj.visible_check.handlers_and.every((handler) => {
      const newObj = {
        ...obj,
        visible_check: {
          ...obj.visible_check,
          handler,
        },
      }
      delete newObj.visible_check.handlers_and
      return runHandlerVisibleCheck(newObj, managerData, fieldData, listInfo, state)
    })
    return result
  } if ('visible_check' in obj && 'handlers_or' in obj.visible_check) {
    const result = !obj.visible_check.handlers_or.every((handler) => {
      return !runHandler({
        handler, managerData, fieldData, listInfo, state, definition,
      })
    })
    return result
  }
  return true
}

export const runHandlerClick = (field, managerActions, fieldActions, fieldData, listInfo, managerData, state) => {
  if (field.on_click.handler in handlers) {
    return handlers[field.on_click.handler]({
      field, managerActions, fieldActions, fieldData, listInfo, managerData, state,
    })
  }
  return false
}

export const runHandlerValue = (field, fieldData, managerData, isHidden = false) => {
  if (field.value_handler in handlers) {
    return handlers[field.value_handler]({
      field, fieldData, managerData, isHidden,
    })
  }
  return false
}

export const runHandlerFieldTransform = (field, managerData, fieldData, listInfo, state) => {
  if (field.transform.handler in handlers) {
    return handlers[field.transform.handler]({
      field, managerData, fieldData, listInfo, state,
    })
  }
  return field
}

export const runHandlerOptions = (field, managerData, fieldData, defaultOpts = [], state) => {
  if (field.options_handler in handlers) {
    return handlers[field.options_handler]({
      field, managerData, fieldData, state,
    })
  }
  return defaultOpts
}

export const runDecoratorValue = (field, fieldData) => {
  if (field.ui.decorator in handlers) {
    return handlers[field.ui.decorator]({
      field, fieldData,
    })
  }
  return false
}

export const runHandlerDisableCheck = (section, managerActions, managerData, fieldData, state) => {
  if (section.disable_check.handler) {
    return runHandler({
      definition: section,
      handler: section.disable_check.handler,
      managerActions,
      managerData,
      fieldData,
      state,
    })
  } if (section.disable_check.handlers_or) {
    return section.disable_check.handlers_or.some((handler) => {
      return runHandler({
        definition: section,
        handler,
        managerData,
        fieldData,
      })
    })
  } if (section.disable_check.handlers_and) {
    return section.disable_check.handlers_and.every((handler) => {
      return runHandler({
        definition: section,
        handler,
        managerData,
        fieldData,
      })
    })
  }
  return false
}

export const callHandler = (handler, { field, ...rest }) => {
  if (field.handlers[handler] in handlers) {
    return handlers[field.handlers[handler]]({ field, ...rest })
  }
  return false
}
