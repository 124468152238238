/* eslint-disable camelcase, max-statements, max-lines */
import React, {
  useCallback,
  useEffect,
} from 'react'
import { debounce } from 'lodash'
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps'
import usePrevious from '@elliemae/ds-utilities/hooks/usePrevious'
import useShouldRecalculate from '@elliemae/ds-utilities/hooks/useShouldRecalculate'
import { isEqual } from '@elliemae/ds-utilities/utils'
import { getValue } from 'common/utils/field'
import { getErrorMessages } from 'common/utils/handlers/messages'
import { runHandlerValue } from 'common/utils/handlers'
import { getModule } from 'common/utils/handlers/modules'

import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import DSTextBox from '@elliemae/ds-basic/form/TextBox'
import DSInputMask, { MASK_TYPES } from '@elliemae/ds-basic/form/InputMask'

import InputProtected, {
  RIGHT_ADDON_OPTIONS,
} from '@elliemae/ds-basic/form/InputProtected'

const removeDecimalPoints = (rawValue = '') => rawValue.replace(/\.\d*$/, '')

const masks = {
  currency: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '$',
      allowDecimal: true,
      requireDecimal: false,
      integerLimit: 9,
    })
    const mask = numberMask(rawValue)
    return mask
  },
  negativeCurrency: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '$',
      allowDecimal: true,
      requireDecimal: false,
      integerLimit: 9,
      allowNegative: true,
    })
    const mask = numberMask(rawValue)
    return mask
  },
  currencyNoDecimal: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '$',
      allowDecimal: false,
      requireDecimal: false,
      integerLimit: 9,
    })
    const mask = numberMask(removeDecimalPoints(rawValue))
    return mask
  },
  negativeCurrencyNoDecimal: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '$',
      allowDecimal: false,
      requireDecimal: false,
      integerLimit: 9,
      allowNegative: true,
    })
    const mask = numberMask(removeDecimalPoints(rawValue))
    return mask
  },
  integer: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      includeThousandsSeparator: false,
      requireDecimal: false,
      integerLimit: 9,
      allowNegative: true,
    })
    const mask = numberMask(removeDecimalPoints(rawValue))
    return mask
  },
  unsignedInteger: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      includeThousandsSeparator: false,
      requireDecimal: false,
      integerLimit: 9,
      allowNegative: false,
    })
    const mask = numberMask(removeDecimalPoints(rawValue))
    return mask
  },
  thousandSeparatorInteger: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      includeThousandsSeparator: true,
      requireDecimal: false,
      integerLimit: 9,
      allowNegative: true,
    })
    const mask = numberMask(removeDecimalPoints(rawValue))
    return mask
  },
  unsignedThousandSeparatorInteger: (rawValue = '') => {
    const numberMask = MASK_TYPES.NUMBER({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      includeThousandsSeparator: true,
      requireDecimal: false,
      integerLimit: 9,
      allowNegative: false,
    })
    const mask = numberMask(removeDecimalPoints(rawValue))
    return mask
  },
  percent: MASK_TYPES.PERCENT({
    prefix: '',
    suffix: '%',
    allowDecimal: true,
    integerLimit: 3,
  }),
  percent3: MASK_TYPES.PERCENT({
    prefix: '',
    suffix: '%',
    allowDecimal: true,
    integerLimit: 3,
    decimalLimit: 3,
  }),
  decimal1: MASK_TYPES.NUMBER({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 1,
    includeThousandsSeparator: false,
    requireDecimal: false,
  }),
  decimal2: MASK_TYPES.NUMBER({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
    includeThousandsSeparator: false,
    requireDecimal: false,
  }),
  decimal3: MASK_TYPES.NUMBER({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 3,
    includeThousandsSeparator: false,
    requireDecimal: false,
  }),
  year: MASK_TYPES.NUMBER({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    includeThousandsSeparator: false,
    integerLimit: 4,
  }),
  units: MASK_TYPES.NUMBER({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    includeThousandsSeparator: false,
    integerLimit: 2,
  }),
  phone: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  workphone: (rawValue) => {
    if (rawValue.length <= 12) {
      return [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
    return [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
  },
  ssn: MASK_TYPES.SSN,
  zip: (rawValue) => {
    if (rawValue.length <= 5) {
      return [/\d/, /\d/, /\d/, /\d/, /\d/]
    }
    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  creditScore: MASK_TYPES.NUMBER({
    prefix: '',
    integerLimit: 3,
  }),
}

const maskGetRawValue = {
  currency: v => v.replace(/^\$|,/g, ''),
  negativeCurrency: v => v.replace(/\$|,/g, ''),
  currencyNoDecimal: v => v.replace(/\$|,/g, ''),
  negativeCurrencyNoDecimal: v => v.replace(/\$|,/g, ''),
  percent: v => v.replace(/%$/g, ''),
  percent3: v => v.replace(/%$/g, ''),
}

const TextInput = ({
  field, fieldActions, fieldData, onBlur, onChange, onFocus, managerActions, listInfo,
}) => {
  const propValue = field.value_handler ? runHandlerValue(field, fieldData) : getValue(fieldData, field.id)
  const [value, setValue] = useDerivedStateFromProps(propValue)
  const prevValue = usePrevious(value)
  const areValuesEqual = useShouldRecalculate(isEqual(prevValue, value))

  const {
    ui = {},
    disabled = false,
    label = '',
    required = false,
    placeholder = '',
    suffix = '',
    id = '',
    container_props = {},
    hideValue = false,
    protectedInput = false,
  } = field
  const actualField = fieldData[field.id] || {}

  const { auto_focus, disable_paste } = ui
  const type = ui.widget || field.type
  const name = ui.name || field.id
  const errors = actualField.errors || []
  const feedbackMessage = field.feedback_message || ''

  let { mask, pipe } = ui
  if (mask) {
    if (typeof mask === 'string') {
      mask = getModule(mask)
    }
  } else {
    mask = masks[type]
  }
  if (pipe) {
    pipe = getModule(pipe)
  }

  const fieldType = maskGetRawValue[type]

  const fireHandler = useCallback(debounce((handler, newValue, isBlur) => {
    return handler({
      id: field.id,
      value: newValue,
      field,
      fieldActions,
      managerActions,
      listInfo,
      isBlur,
    })
  }, 50), [])

  useEffect(() => {
    if (value) {
      fireHandler(onChange, value, true)
    }
  }, [areValuesEqual])

  const onChangeInput = (event) => {
    let newValue = event.target.value
    if (field.pattern) {
      const pattern = new RegExp(`^${field.pattern}*$`, 'g')
      if (newValue !== '' && !pattern.test(newValue)) {
        event.preventDefault()
        return
      }
    }
    if (field.regex) {
      const regex = new RegExp(`${field.regex}`, 'g')
      if (newValue !== '' && !regex.test(newValue)) {
        event.preventDefault()
        return
      }
    }
    if (fieldType) {
      newValue = fieldType(newValue)
    }

    setValue(newValue)

    fireHandler(onChange, newValue, true)
  }

  const onBlurInput = (event) => {
    let newValue = event.target.value
    if (fieldType) {
      newValue = fieldType(newValue)
    }
    fireHandler(onBlur, newValue)
  }

  const onFocusInput = (event) => {
    let newValue = event.target.value
    if (fieldType) {
      newValue = fieldType(newValue)
    }
    fireHandler(onFocus, newValue)
  }

  const onPaste = (event) => {
    if (disable_paste) {
      event.preventDefault(event)
    }
  }

  let inputComponent = (mask || pipe)
    ? <DSInputMask />
    : <DSTextBox />

  let rightAddon
  if (protectedInput) {
    inputComponent = InputProtected
    rightAddon = hideValue ? RIGHT_ADDON_OPTIONS.VisibleView : undefined
  }

  return (
    <DSFormItemLayout
      autoFocus={auto_focus}
      disabled={disabled}
      extraInputProps={{
        placeholder,
        maxLength: field.max_length,
        onPaste,
        onChange: onChangeInput,
        name,
        autoFocus: auto_focus,
        containerProps: {
          id,
          ...container_props,
        },
        rightAddon,
      }}
      feedbackMessage={feedbackMessage}
      floatingLabel
      hasError={errors.length > 0}
      inputComponent={inputComponent}
      labelText={label}
      mask={mask}
      name={name}
      onBlur={onBlurInput}
      onChange={onChangeInput}
      onFocus={onFocusInput}
      onPaste={onPaste}
      pipe={pipe}
      required={required}
      suffix={suffix}
      type={type}
      validationMessage={getErrorMessages(field, errors, managerActions)}
      value={value}
    />
  )
}

export default TextInput
