import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { incList, decList } from 'common/actions/ManagerActions'
import * as fieldActions from 'common/actions/FieldActions'
import ScreenGroupView from 'components/core/ScreenGroupView'

const managerActions = { incList, decList }

const mapDispatchToProps = (dispatch) => {
  return {
    fieldActions: bindActionCreators(fieldActions, dispatch),
    managerActions: bindActionCreators(managerActions, dispatch),
  }
}

const mapStateToProps = (state) => {
  return {
    managerData: state.manager,
    fieldData: state.field,
    state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenGroupView)
