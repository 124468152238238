import { applyMiddleware, createStore } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import storeLogger from './storeLogger'
import combineReducersForApp, { rootReducers } from './CombineReducers'

const reducers = combineReducersForApp()
const initialState = {}
const sagaMiddleware = createSagaMiddleware({})
const middleware = [sagaMiddleware, thunk]
if (process.env.NODE_ENV !== 'production') middleware.push(storeLogger)

export default function configureStore() {
  const store = createStore(
    enableBatching(reducers),
    initialState,
    applyMiddleware(...middleware),
  )
  // Extensions required for pui-app-sdk to work
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = rootReducers // Reducer registry
  store.injectedSagas = {} // Saga registry
  return store
}
