import React from 'react'
import { DSCard, DSCardHeader, DSCardBody } from '@elliemae/ds-basic/Card'
import Field from './Field'

const Card = ({
  field: {
    id = '',
    container_props = {},
    title,
    header_action,
    body,
  },
  managerActions,
  fieldActions,
  managerData,
  fieldData,
  listInfo,
  state,
}) => {
  const getActionHeaderField = () => {
    if (!header_action) return null

    return (
      <Field
        field={header_action}
        fieldActions={fieldActions}
        fieldData={fieldData}
        listInfo={listInfo}
        managerActions={managerActions}
        managerData={managerData}
        state={state}
      />
    )
  }

  const getBodyField = () => {
    if (!body) return null

    return (
      <Field
        field={body}
        fieldActions={fieldActions}
        fieldData={fieldData}
        fieldGroup={body}
        listInfo={listInfo}
        managerActions={managerActions}
        managerData={managerData}
        state={state}
      />
    )
  }

  return (
    <DSCard
      containerProps={{
        id,
        ...container_props,
      }}
    >
      {
        title && (
          <DSCardHeader
            action={getActionHeaderField()}
            title={title}
          />
        )
      }
      {
        body && (
          <DSCardBody>
            {getBodyField()}
          </DSCardBody>
        )
      }
    </DSCard>
  )
}

export default Card
