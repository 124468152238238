/* eslint-disable import/no-cycle */
import { isSet, getValue } from '../field'

const handlers = {
  skip: () => true,
  reloadSkip: ({ managerData, transition }) => {
    if (!managerData.loaded && managerData.id === transition.params.id) {
      return true
    }

    return false
  },
  hasFocus: ({ definition }) => document.activeElement
      && document.activeElement.nodeType
      && document.activeElement.getAttribute('id') === definition.visible_check.params.id,

  hasErrors: ({ definition, fieldData }) => fieldData[definition.visible_check.params.id]
    && Array.isArray(fieldData[definition.visible_check.params.id].errors)
    && fieldData[definition.visible_check.params.id].errors.length,

  hasErrorsAndSet: ({ definition, fieldData }) => handlers.hasErrors({ definition, fieldData })
    && isSet(fieldData, definition.visible_check.params.id)
    && getValue(fieldData, definition.visible_check.params.id).length,
}

export default handlers
