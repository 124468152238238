const { css } = require('styled-components');

module.exports = (breakpoints) =>
  Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return acc;
  }, {});
