import React from 'react'
import { RemovablePill as DSRemovablePill } from '@elliemae/ds-basic/Pills'

const RemovablePill = ({
  field: {
    id = '',
    container_props = {},
    ui: {
      label = '',
      onRemove,
      leftAddon,
    },
  },
}) => {
  return (
    <DSRemovablePill
      containerProps={{
        id,
        ...container_props,
      }}
      label={label}
      leftAddon={leftAddon}
      onRemove={onRemove}
    />
  )
}

export default RemovablePill
