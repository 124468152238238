/* eslint-disable consistent-return */
import React, { Component } from 'react'
import DSRadioGroup from '@elliemae/ds-basic/form/RadioGroup'
import DSRadio from '@elliemae/ds-basic/form/Radio'
import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import { getValue } from 'common/utils/field'
import Field from './Field'

export default class RadioGroup extends Component {
  constructor(props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
  }
  handleOptionChange(value) {
    const {
      field,
      field: {
        id,
      },
      listInfo,
      onChange,
    } = this.props
    onChange({
      id,
      value,
      listInfo,
      field,
    })
  }
  render() {
    const {
      fieldActions,
      fieldData,
      managerActions,
      managerData,
      state,
      field,
      field: {
        id = '',
        container_props = {},
        options = [],
        label = '',
        required = false,
        orientation = 'vertical',
        disabled = false,
        autoDisableChilds = false,
        ui: {
          label_class: labelClass,
        },
      },
    } = this.props

    const labelProps = {
      feedbackMessage: '',
      labelText: labelClass ? <span className={labelClass}>{label}</span> : label,
      required,
    }

    if (!options.length) return null

    const activeValue = getValue(fieldData, id) || field.default || field.value
    const availableOptions = options.filter(({ visible }) => visible !== false)

    return (
      <DSRadioGroup
        activeValue={activeValue?.toString()}
        containerProps={{
          id,
          ...container_props,
        }}
        disabled={disabled}
        labelProps={labelProps}
        onChange={this.handleOptionChange}
        orientation={orientation}
      >
        {
          availableOptions.map((radio, index) => {
            const {
              id: radioId,
              text,
              value,
              disabled: disabledRadio,
              container_props: container_child_props = {},
            } = radio
            return (
              <DSRadio
                key={radioId || index}
                containerProps={{
                  id: radioId,
                  ...container_child_props,
                }}
                disabled={disabledRadio}
                labelText={text}
                value={`${value}`}
              >
                {radio.fields && radio.fields.map((radioChildField, key) => {
                  const radioChildFieldDisabled = autoDisableChilds && activeValue !== `${value}`
                  const newRadioChildField = { ...radioChildField, disabled: radioChildFieldDisabled }
                  const innerRadioComponent = (
                    <Field
                      field={newRadioChildField}
                      fieldActions={fieldActions}
                      fieldData={fieldData}
                      managerActions={managerActions}
                      managerData={managerData}
                      state={state}
                    />
                  )
                  return (
                    <DSFormItemLayout
                      key={key}
                      inputComponent={innerRadioComponent}
                    />
                  )
                })}
              </DSRadio>
            )
          })
        }
      </DSRadioGroup>
    )
  }
}
