/* eslint-disable max-lines, guard-for-in, no-restricted-syntax, max-statements, max-nested-callbacks */
import { isEqual, cloneDeep } from 'lodash'
import {
  SET_FIELD,
  SCREEN_ERRORS_COUNT_INCDEC,
} from 'common/constants/ActionConstants'
import { getValue } from 'common/utils/field'
import { runHandlerVisibleCheck, runHandlerValue } from 'common/utils/handlers'
import { runFieldValidators } from 'common/utils/handlers/validators'
import { getListSize } from 'common/utils/list'
import { isEmptyScreen } from 'common/utils/screen'

class ScreenEngineService {
  constructor() {
    this.ids = {}
    this.autoSetFields = {}
    this.rids = []
    this.fieldsToAutoClearMap = {}
    this.listIds = {}
  }
  resetSelf() {
    this.ids = {}
    this.autoSetFields = {}
    this.rids = []
    this.fieldsToAutoClearMap = {}
  }
  load({ sections, screens, summary }) {
    if (sections) {
      Object.values(sections).forEach((section) => { this.cacheIds(section) })
    }
    if (screens) {
      Object.values(screens).forEach((screen) => { this.cacheIds(screen) })
    }
    if (summary) {
      Object.values(summary).forEach((summaryElement) => { this.cacheIds(summaryElement) })
    }
    if (screens) {
      Object.values(screens).forEach((screen) => { this.cacheListIds(screen) })
    }
    this.linkScreenGroups()
    // The order of these is very important...
    // 1. Auto clear field handling
    this.cacheFieldsToAutoClearMap()
  }
  linkScreenGroups() {
    Object.values(this.ids).forEach((id) => {
      if (id.type === 'screen_group' && !id.isScreensLoaded) {
        id.screens = id.screens.map((screen) => {
          let item
          if (screen.type && screen.type === 'screen_group') {
            item = screen
            screen.screens = screen.screens.map((subScreen) => {
              // these are necessary for the summary view
              const walkToId = subScreen.walk_to_id
              const displayOnLeftNav = subScreen.display_on_left_nav
              const isAdminOnly = subScreen.is_admin_only
              this.ids[subScreen.id].walk_to_id = walkToId
              this.ids[subScreen.id].display_on_left_nav = displayOnLeftNav
              this.ids[subScreen.id].is_admin_only = isAdminOnly
              return this.ids[subScreen.id]
            })
          } else {
            item = this.ids[screen.id]
          }
          const walkToId = screen.walk_to_id
          const displayOnLeftNav = screen.display_on_left_nav
          const isAdminOnly = screen.is_admin_only
          item.walk_to_id = walkToId
          item.display_on_left_nav = displayOnLeftNav
          item.is_admin_only = isAdminOnly
          return item
        })
        id.isScreensLoaded = true
      }
    })
  }
  getIdCache() {
    return this.ids
  }
  getListIdCache() {
    return this.listIds
  }
  checkIsEmptyScreen(id, state) {
    const screen = this.getById(id)
    return isEmptyScreen(screen, state)
  }
  runFieldValidators(getState, clearHidden = false, skipRequired = false, isSubmitAction = false, fieldIds = []) {
    const state = getState()
    const { path } = state.manager
    const screen = path[path.length - 1].structure
    let preTotalErrors = 0
    if (state.manager.screen[state.manager.id]) {
      preTotalErrors = state.manager.screen[state.manager.id].errorCount
    }
    let postTotalErrors = 0
    const actions = []
    this.visibleCount = 0

    const traverse = (obj, objIsHidden = false, listInfo = {}) => {
      const field = obj
      const { manager: managerData, field: fieldData } = state
      const traverseHelper = (helperObj, property, helperIsHidden, helperListInfo) => {
        if (!helperObj.list) {
          Object.values(helperObj[property])
            .forEach(helperObjPropObj => traverse(helperObjPropObj, helperIsHidden, helperListInfo))
        } else {
          let numList
          if (state.manager.lists[helperObj.id] && helperObj.list.min) {
            numList = state.manager.lists[helperObj.id] + helperObj.list.min
          } else if (state.manager.lists[helperObj.id]) {
            numList = state.manager.lists[helperObj.id]
          } else if (helperObj.list.min) {
            numList = helperObj.list.min
          } else {
            numList = 0
          }
          // eslint-disable-next-line no-loops/no-loops
          for (let i = 0; i < numList; i += 1) {
            helperListInfo.index = i
            helperListInfo.isLast = ((helperListInfo.size - 1) === i)
            helperListInfo.isFirst = i === 0
            if (helperObj.visible_check) {
              if (!runHandlerVisibleCheck(field, managerData, fieldData, helperListInfo, state)) {
                helperIsHidden = true
              }
            }
            Object.values(helperObj[property])
            // eslint-disable-next-line no-loop-func
              .forEach(helperObjPropObj => traverse(helperObjPropObj, helperIsHidden, helperListInfo))
          }
        }
      }

      const baseCase = (id) => {
        const errors = (
          state.field[id] && state.field[id].errors
            ? state.field[id].errors : [])
        const preErrorCount = (errors ? errors.length : 0)
        const payload = (state.field[id] ? state.field[id] : { id })
        if (objIsHidden && preErrorCount > 0) {
          actions.push(
            {
              type: SET_FIELD,
              payload: {
                ...payload,
                ...{ errors: [] },
              },
            },
          )
        } else if (!objIsHidden && (!clearHidden || obj.force_validation) && (fieldIds.length === 0 || fieldIds.indexOf(obj.id) !== -1)) {
          const definition = obj
          let value
          if (definition.value_handler) {
            value = runHandlerValue(definition, state.field, state.manager)
          } else {
            value = getValue(state.field, id)
          }
          const newErrors = runFieldValidators(definition, value, skipRequired, fieldData, id)
          const postErrorCount = newErrors.length
          if (definition.increase_screen_errors === false) {
            postTotalErrors += 0
          } else {
            postTotalErrors += postErrorCount
          }
          if (!isEqual(newErrors, errors)) {
            actions.push(
              {
                type: SET_FIELD,
                payload: {
                  ...payload,
                  ...{ errors: newErrors },
                  ...{ listInfo },
                },
              },
            )
          }
        } else {
          postTotalErrors += preErrorCount
        }
      }

      // NOTE: This change would require corresponding changes on econsent module as well.
      // Planning to pass entire state down the tree
      const custom = state // TODO: get generic customs from other modules, in future
      if (obj.list) {
        listInfo = { ...obj.list }
        if (obj.list.size_handler) {
          listInfo.size = getListSize({ field, managerData, fieldData })
        } else {
          listInfo.size = getListSize({ field, managerData })
        }
        listInfo.id = obj.id
      }
      if (obj.visible === false) {
        return
      } if (obj.visible_check) {
        if (!runHandlerVisibleCheck(field, managerData, fieldData, listInfo, getState(), custom)) {
          objIsHidden = true
        }
      }

      if (
        !objIsHidden
        && obj.type !== 'field_group'
        && obj.type !== 'screen'
        && obj.type !== 'screen_group'
        && obj.type !== 'null') {
        this.visibleCount += 1
      }

      if (obj.type === 'field_group' || obj.type === 'screen') {
        traverseHelper(obj, 'fields', objIsHidden, listInfo)
      } else if (obj.type === 'screen_group') {
        traverseHelper(obj, 'screens', objIsHidden, listInfo)
      } else if (obj.id && obj.type) {
        let { id } = obj
        // TODO: move this out to be useful for the Summary module
        // This is an edge case fix for submit off summary screens -- NGCC-6313
        if (isSubmitAction && obj.ui && obj.ui.widget === 'yesNo') {
          return
        }
        if ((listInfo.id !== undefined) && (listInfo.index !== undefined)) {
          id += `-list-${listInfo.id}-index-${listInfo.index}`
          baseCase(id)
        } else if ((listInfo.id !== undefined) && (listInfo.size !== undefined)) {
          // eslint-disable-next-line no-loops/no-loops
          for (let j = 0; j < listInfo.size; j += 1) {
            baseCase(`${id}-list-${listInfo.id}-index-${j}`)
          }
        } else {
          baseCase(id)
        }
      }
    }
    traverse(screen)
    if (postTotalErrors === preTotalErrors && actions.length === 0) {
      return false
    }
    actions.push(
      {
        type: SCREEN_ERRORS_COUNT_INCDEC,
        payload: { errorIncDec: postTotalErrors - preTotalErrors },
      },
    )
    return actions
  }
  getAutoSetFields() {
    return cloneDeep(this.autoSetFields)
  }
  getVisibleCount() {
    return this.visibleCount
  }
  getById(id) {
    return this.ids[id]
  }
  cacheIds(obj, listId) {
    if (obj.id && obj.type) {
      if (this.ids[obj.id]) {
        // eslint-disable-next-line no-console
        console.error('DUPLICATE UUID FOUND', obj.id)
      }
      const curListId = obj.list ? obj.id : listId
      obj.listId = curListId
      this.ids[obj.id] = obj
      if (obj.ui && obj.ui.auto_set) {
        this.autoSetFields[obj.id] = obj
      }
    } else if (obj.rid) {
      this.rids.push(obj)
    }
    if (obj.type === 'field_group' || obj.type === 'screen') {
      obj.fields.forEach((field) => {
        const curListId = obj.list ? obj.id : listId
        this.cacheIds(field, curListId)
        this.resetRequiredNullField(field)
      })
    }
  }
  cacheListIds(
    {
      list,
      id,
      rid,
      type,
      screens,
      fields,
    },
    listId,
  ) {
    listId = (list)
      ? id || rid
      : listId
    if (listId) {
      if (this.listIds[listId]) {
        this.listIds[listId].push(id || rid)
      } else {
        this.listIds[listId] = [id || rid]
      }
    }
    if (type === 'screen_group') {
      screens.forEach((screen) => {
        const screenId = screen.id || screen.rid
        if (screen && screenId) {
          const actualScreen = this.ids[screenId]
          this.cacheListIds(actualScreen, listId)
        }
      })
    } else if (type === 'field_group' || type === 'screen') {
      fields.forEach((field) => { this.cacheListIds(field, listId) })
    }
  }
  resetRequiredNullField(field) {
    if (field.type === 'null' && field.required) {
      field.required = false
    }
  }
  linkRids() {
    this.rids.forEach((obj) => {
      if (this.ids[obj.rid]) {
        Object.keys(this.ids[obj.rid]).forEach((p) => {
          if (typeof obj[p] === 'undefined') {
            obj[p] = this.ids[obj.rid][p]
          } else if (p === 'options') {
            this.ids[obj.rid][p].forEach((option, index) => {
              const ridOption = obj[p][index]
              if (!ridOption) return
              Object.keys(option).forEach((op) => {
                if (typeof ridOption[op] === 'undefined') {
                  ridOption[op] = option[op]
                }
              })
            })
          }
        })
        // Reference list of rids on the original definition
        // for access in handlers if needed
        if (!this.ids[obj.rid].rids) this.ids[obj.rid].rids = []
        this.ids[obj.rid].rids.push(obj)
      }
    })
  }
  getFieldsToAutoClearMap() {
    return this.fieldsToAutoClearMap
  }
  cacheFieldsToAutoClearMap() {
    const handlers = {}
    Object.values(this.ids).forEach((obj) => {
      if (obj.visible_check || obj.clear_check) {
        const clearCheckProp = (
          obj.clear_check
            ? 'clear_check'
            : 'visible_check'
        )
        let { handler } = obj[clearCheckProp]
        if (obj.type === 'null') {
          return
        }
        if (obj[clearCheckProp].handler_not) {
          handler = `!${obj[clearCheckProp].handler_not}`
        } else if (obj[clearCheckProp].handlers_and) {
          handler = obj[clearCheckProp].handlers_and.join('&&')
        } else if (obj[clearCheckProp].handlers_or) {
          handler = obj[clearCheckProp].handlers_or.join('||')
        }
        if (!handlers[handler]) {
          handlers[handler] = {}
        }
        handlers[handler] = {
          ...handlers[handler],
          ...this.getFields(obj),
        }
      }
    })
    Object.keys(handlers).forEach((handler) => {
      Object.keys(handlers[handler]).forEach((id) => {
        if (this.fieldsToAutoClearMap[id]) {
          this.fieldsToAutoClearMap[id] += `&&${handler}`
        } else {
          this.fieldsToAutoClearMap[id] = handler
        }
      })
    })
  }
  getFields(obj, fields = {}) {
    if (obj.type === 'field_group' || obj.type === 'screen') {
      fields = obj.fields.map(field => this.getFields(field))
    } else if (obj.type === 'screen_group') {
      fields = obj.screens.map(screen => this.getFields(this.ids[screen.id]))
    } else {
      fields[obj.id] = { ...obj }
    }
    return fields
  }
  getFieldsWithDefault() {
    const fields = []
    Object.values(this.ids).forEach((obj) => {
      if ((obj.value_handler || obj.default) && obj.type !== 'null') {
        fields.push(obj)
      }
    })
    return fields
  }
  getWalkToId(id) {
    if (this.ids[id]) {
      return this.ids[id].walk_to_id
    }
    return id
  }
}

export default new ScreenEngineService()
