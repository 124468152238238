import React from 'react'
import { DropdownPill as DSDropdownPill } from '@elliemae/ds-basic/Pills'

const DropdownPill = ({
  field: {
    id = '',
    container_props = {},
    ui: {
      label = '',
      variant,
      tabIndex = -1,
      closeMenuOnItemSelection = false,
      ...dropdownProps
    },
  },
}) => {
  return (
    <DSDropdownPill
      closeMenuOnItemSelection={closeMenuOnItemSelection}
      containerProps={{
        id,
        ...container_props,
      }}
      label={label}
      tabIndex={tabIndex}
      variant={variant}
      {...dropdownProps}
    />
  )
}

export default DropdownPill
