import { runHandler } from 'common/utils/handlers'

const end = { id: 'END' }

class WorkflowEngineService {
  constructor() {
    this.stack = []
    this.ids = {}
  }
  load(workflow) {
    this.workflow = workflow
    this.cacheIds(this.workflow, undefined, 0)
  }
  runHandler(transition, { state }) {
    const { handler } = transition
    return runHandler({
      handler, fieldData: state.field, managerData: state.manager, state, transition,
    })
  }
  cacheIds(workflow, parent, index) {
    this.ids[workflow.id] = workflow
    workflow.parent = parent
    workflow.index = index
    if (Array.isArray(workflow.steps)) {
      workflow.steps.forEach((step, i) => {
        this.cacheIds(step, workflow, i)
      })
    }
  }
  crawlUp(step, opts) {
    const { parent } = step
    const nextIndex = step.index + 1
    if (!parent) {
      this.stack.push(end)
      this.step = end
    } else if (parent.steps[nextIndex]) {
      this.stack.push(parent.steps[nextIndex])
      this.step = parent.steps[nextIndex]
    } else {
      this.crawlUp(parent, opts)
    }
  }
  transitionToStart() {
    this.stack = []
    this.stack.push(this.workflow)
    this.step = this.workflow
    return this.step.id
  }
  transitionToNextState(opts) {
    if (this.stack.length) {
      if (this.step.post_transitions) {
        let transitionto
        this.step.post_transitions.every((t) => {
          const result = this.runHandler(t, opts)
          if (result) {
            transitionto = t.transitions.forward
          }
          return !result
        })
        if (transitionto && transitionto !== 'next_state') {
          return this.transitionto(transitionto, opts)
        }
      }
      // Continue depth first until reach the bottom
      this.nextStepCheckSteps(opts)
      if (this.step.pre_transitions) {
        let transitionto
        this.step.pre_transitions.every((t) => {
          const result = this.runHandler(t, opts)
          if (result) {
            transitionto = t.transitions.forward
          }
          return !result
        })
        if (transitionto) {
          return this.transitionto(transitionto, opts)
        }
      }
      return this.step.id
    }
    return this.transitionto('start', opts)
  }
  nextStepCheckSteps(opts) {
    if (Array.isArray(this.step.steps) && this.step.steps.length) {
      const firstStep = this.step.steps[0]
      this.stack.push(firstStep)
      this.step = firstStep
    } else {
      this.crawlUp(this.step, opts)
    }
  }
  transitionToPrevState(opts) {
    if (this.stack.length > 1) {
      this.stack.pop()
      this.step = this.stack[this.stack.length - 1]
      // TODO: post_transition support (if needed)
      if (this.step.pre_transitions) {
        let transitionto
        this.step.pre_transitions.every((t) => {
          const result = this.runHandler(t, opts)
          if (result) {
            transitionto = t.transitions.backward
          }
          return !result
        })
        if (transitionto) {
          return this.transitionto(transitionto, opts)
        }
      }
      return this.step.id
    }
    return this.step.id
  }
  transitionToWalkTo(opts) {
    // specific id that must be ahead of where we are at
    this.stack = []
    // eslint-disable-next-line no-loops/no-loops
    while (this.transitionto('next_state', opts) !== opts.id) {
      // do nothing
    }
    // eslint-disable-next-line no-loops/no-loops
    while (this.step.pre_walkto) {
      let transitionto
      this.step.pre_walkto.every((t) => {
        const result = this.runHandler(t, opts)
        if (result) {
          transitionto = t.transition
        }
        return !result
      })
      if (transitionto) {
        this.transitionto(transitionto, opts)
      } else {
        break
      }
    }
    return this.step.id
  }
  transitionToDefault(to, opts) {
    // specific id
    this.stack.push(this.ids[to])
    this.step = this.ids[to]
    if (this.step.pre_transitions) {
      let transitionto
      this.step.pre_transitions.every((t) => {
        const result = this.runHandler(t, opts)
        if (result) {
          transitionto = t.transitions.forward
        }
        return !result
      })
      if (transitionto) {
        return this.transitionto(transitionto, opts)
      }
    }
    return this.step.id
  }
  transitionto(to, opts) {
    switch (to) {
      case 'start': return this.transitionToStart()
      case 'next_state': return this.transitionToNextState(opts)
      case 'prev_state': return this.transitionToPrevState(opts)
      case 'walk_to': return this.transitionToWalkTo(opts)
      default: return this.transitionToDefault(to, opts)
    }
  }
  getCurrentStep() {
    return this.step
  }
  getPath() {
    const path = []
    let { step } = this
    // eslint-disable-next-line no-loops/no-loops
    while (step) {
      path.unshift(step.id)
      step = step.parent
    }
    return path
  }
}

export default new WorkflowEngineService()
