import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { TooltipTextProvider } from '@elliemae/ds-basic/TruncatedTooltipText'

import AspectsConnector from 'aspectUI/AspectsConnector'
import buildRoutes from './AppRoutes'

const { router } = window.emAppConfig
const basename = (router && router.basename) || '/'

const App = () => (
  <BrowserRouter basename={basename}>
    <TooltipTextProvider>
      <AspectsConnector />
      {buildRoutes()}
    </TooltipTextProvider>
  </BrowserRouter>
)

export default App
