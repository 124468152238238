module.exports = {
  breakpoints: ['32rem', '48rem', '64rem'],
  space: [
    0,
    '4px',
    '8px',
    '12px',
    '16px',
    '32px',
    '48px',
    '56px',
    '64px',
    '72px',
  ],
  fontSizes: [
    '0.8461rem',
    '0.9230rem',
    '1rem',
    '1.0769rem',
    '1.2307rem',
    '1.3846rem',
    '1.8461rem',
    '2.7692rem',
  ],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: ['1rem', '1.15rem', '1.25rem', '1.5rem'],
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1rem',
    tight: '-0.05rem',
    mega: '0.25rem',
  },
  fonts: {
    default: 'proxima-nova, Arial, sans-serif',
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
  ],
  radii: [0, '2px', '4px', '16px', '9999px', '100%'],
  width: ['16px', '32px', '64px', '128px', '256px'],
  heights: ['16px', '32px', '64px', '128px', '256px'],
  maxWidths: [
    '16px',
    '32px',
    '64px',
    '128px',
    '256px',
    '512px',
    '768px',
    '1024px',
    '1536px',
  ],
  colors: {
    neutral: [
      '#FFFFFF',
      '#F6F7F9',
      '#EBEDF0',
      '#E0E3E8',
      '#CBCFD7',
      '#B0B9C8',
      '#A9B1BE',
      '#697489',
      '#464F5C',
      '#353C46',
      '#25292F',
    ],
    brand: [
      '#F6FBFF',
      '#EBF6FF',
      '#A3D6FF',
      '#52A6EC',
      '#1394E5',
      '#1E79C2',
      '#006AA9',
      '#005181',
    ],
    success: ['#D4F0E5', '#207E56'],
    warning: ['#FBDE31', '#FBB431', '#FF9400'],
    danger: ['#F7CDD2', '#C64252'],
  },
};
