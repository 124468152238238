/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

const Ref = ({
  field: {
    ui: {
      name = '',
    },
  },
}) => {
  return (
    <Link name={name} to={''} />
  )
}

export default Ref
