import React from 'react'
import DSGroupBox from '@elliemae/ds-basic/GroupBox'
import { isString } from 'lodash'
import Field from './Field'

const GroupBox = ({
  field: {
    id = '',
    container_props = {},
    ui: {
      title = '',
      title_border: titleBorder = false,
      box_type: type = 'category-box',
    },
    fields = [],
    centerActions = [],
    rightActions = [],
    generateChilds = true,
  },
  fieldActions,
  fieldData,
  managerActions,
  managerData,
  state,
}) => {
  const labelProps = {
    label: title,
    borderBottom: titleBorder,
  }

  const renderFields = components => (
    components.map((component, key) => {
      return (
        <Field
          key={key}
          field={component}
          fieldActions={fieldActions}
          fieldData={fieldData}
          managerActions={managerActions}
          managerData={managerData}
          state={state}
        />
      )
    })
  )

  const newLabelProps = {
    ...labelProps,
    labelText: isString(title) ? title : renderFields(title),
  }

  return (
    <DSGroupBox
      centerActions={generateChilds ? renderFields(centerActions) : centerActions}
      containerProps={{
        id,
        ...container_props,
      }}
      labelProps={newLabelProps}
      rightActions={generateChilds ? renderFields(rightActions) : rightActions}
      type={type}
    >
      { generateChilds ? renderFields(fields) : fields }
    </DSGroupBox>
  )
}

export default GroupBox
