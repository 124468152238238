import 'core-js'

import 'regenerator-runtime/runtime'

(function (arr) {
  arr.forEach((item) => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      // eslint-disable-next-line func-names
      value: function remove() {
        if (this.parentNode === null) {
          return
        }
        this.parentNode.removeChild(this)
      },
    })
  })
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]))
