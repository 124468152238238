import { cloneDeep } from 'lodash'
import {
  SET_STATE_INIT,
  RESET_STATE_TO_INIT,
} from '../constants/ActionConstants'

export default function resettable(reducer) {
  const initialState = {
    init: {},
    ...reducer(),
  }
  return function (state = initialState, action) {
    const { init, ...rest } = state
    switch (action.type) {
      case SET_STATE_INIT:
        return {
          init: cloneDeep(rest),
          ...rest,
        }
      case RESET_STATE_TO_INIT:
        return {
          init,
          ...init,
        }
      default:
        return {
          init,
          ...reducer(rest, action),
        }
    }
  }
}
