import { MODAL_TYPE } from '@elliemae/ds-modal'

export default function ({ aspectsData, execActionGroup }) {
  const { modal: { opts } } = aspectsData
  return {
    modalType: MODAL_TYPE.ERROR,
    onClose: () => execActionGroup('closeModal'),
    onConfirm: null,
    showFooter: false,
    showHeader: opts.canClose || false,
    body: opts.message || '',
  }
}
