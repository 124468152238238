import React from 'react'

class ToggleMaskLabel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mask: true,
    }
  }
  getCustomMaskedValue(value, maskedValue) {
    if (value.length > 4) {
      const unmaskedvalue = value.substr(value.length - 4)
      return `${maskedValue.substring(0, maskedValue.length - 4)}${unmaskedvalue}`
    }
    return value
  }
  getPseudoMaskedValue() {
    const { field, value } = this.props
    const { mask } = this.state
    const { mask_value: maskedValue } = field.ui
    return mask ? maskedValue : this.getCustomMaskedValue(value, maskedValue)
  }
  toggleMask() {
    this.setState(prevState => ({
      mask: !prevState.mask,
    }))
  }
  render() {
    const { mask } = this.state
    return (
      <div className='toggle-eye-mask-label'>
        <span>{this.getPseudoMaskedValue()}</span>
        <span
          aria-checked={mask}
          className={`toggle-eye-icon ${mask ? 'masked' : ''}`}
          onClick={() => this.toggleMask()}
          onKeyPress={() => {}}
          role='switch'
          tabIndex={0}
        />
      </div>
    )
  }
}

export default ToggleMaskLabel
