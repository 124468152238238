import React from 'react'
import { Redirect as RedirectRouter } from 'react-router'

const redirectIds = {}

const Redirect = ({
  ...props
}) => {
  const { run, to, id } = props
  if (run !== true) {
    return null
  }
  if (redirectIds[id] === true) {
    return null
  }

  redirectIds[id] = true

  if (/^http[s]?:\/\//.test(to)) {
    window.open(to)
    return null
  }

  return (
    <RedirectRouter
      push
      to={to}
    />
  )
}

export default Redirect
