import React from 'react'
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps'
import { getValue, getVisibleFields as getVisibleOptions } from 'common/utils/field'
import { getErrorMessages } from 'common/utils/handlers/messages'
import { runHandlerValue, runHandlerOptions, callHandler } from 'common/utils/handlers'


import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import DSZipCodeSearch from '@elliemae/ds-basic/ZipCodeSearch'

const ZipCodeSearch = ({
  field,
  fieldActions,
  fieldData,
  managerActions,
  managerData,
  listInfo,
  state,
}) => {
  const {
    id = '',
    container_props = {},
    label,
    handlers,
    disabled,
    required,
    placeholder,
    mask,
    options,
    feedback_message: feedbackMessage,
    select_right_options: selectRightOptions,
    active_count: activeCount,
  } = field

  const propValue = field.value_handler ? runHandlerValue(field, fieldData) : getValue(fieldData, field.id)
  const [value, setValue] = useDerivedStateFromProps(propValue)

  const ui = field.ui || {}
  const actualField = fieldData[field.id] || {}
  const name = ui.name || field.id
  const errors = actualField.errors || []

  const args = {
    field,
    managerActions,
    fieldActions,
    fieldData,
    listInfo,
    managerData,
  }

  const getAvailableOptions = () => (field.options_handler
    ? runHandlerOptions(field, managerData, fieldData, [], state) || []
    : options)

  const opts = getAvailableOptions()

  const onSearchZipCodeSearch = (handlers && handlers.on_search)
    ? ({ ...params }) => callHandler('on_search', { ...args, ...params })
    : () => null

  const onChangeZipCodeSearch = (handlers && handlers.on_change)
    ? ({ ...params }) => callHandler('on_change', { ...args, ...params })
    : () => null

  return (
    <DSFormItemLayout
      disabled={disabled}
      extraInputProps={{
        activeCount,
        selectRightOptions,
        placeholder,
        onSearch: onSearchZipCodeSearch,
        options: opts,
        containerProps: {
          id,
          ...container_props,
        },
      }}
      feedbackMessage={feedbackMessage}
      floatingLabel
      hasError={errors.length > 0}
      inputComponent={DSZipCodeSearch}
      labelText={label}
      mask={mask}
      name={name}
      onChange={onChangeZipCodeSearch}
      required={required}
      validationMessage={getErrorMessages(field, errors, managerActions)}
      value={value}
    />
  )
}

export default ZipCodeSearch
