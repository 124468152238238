/* eslint-disable import/no-cycle */
import React from 'react'
import { computeFieldCols, getVisibleFields } from 'common/utils/field'
import Field from './Field'

const FieldGroup = ({
  fieldGroup, managerActions, fieldActions, managerData, fieldData, listInfo, state,
}) => {
  const fieldGroupId = fieldGroup.id || ''
  const visibleFields = getVisibleFields(fieldGroup.fields, managerData, fieldData, listInfo, state)
  const columnClasses = computeFieldCols(visibleFields, fieldGroup)
  const inFieldGroup = true
  const oneColumn = fieldGroup.ui && fieldGroup.ui.one_column

  const getFields = () => (
    visibleFields.map((field, idx) => {
      let wrapperClasses = (fieldGroup.ui && fieldGroup.ui.wrapper === false && ' ') || columnClasses[idx]
      if (field.ui && !!field.ui.class) {
        const uiClass = field.ui.wrapper === 'ignore_class' ? '' : field.ui.class
        wrapperClasses = `${wrapperClasses} ${uiClass}`
      }

      let Wrapper = ({ children }) => <div className={wrapperClasses}>{children}</div>
      if (oneColumn) {
        Wrapper = React.Fragment
        return (
          <Wrapper key={idx}>
            <Field
              field={field}
              fieldActions={fieldActions}
              fieldData={fieldData}
              inFieldGroup={inFieldGroup}
              listInfo={listInfo}
              managerActions={managerActions}
              managerData={managerData}
              state={state}
            />
          </Wrapper>
        )
      }
      return (
        <div key={idx} className={wrapperClasses}>
          <Field
            field={field}
            fieldActions={fieldActions}
            fieldData={fieldData}
            inFieldGroup={inFieldGroup}
            listInfo={listInfo}
            managerActions={managerActions}
            managerData={managerData}
            state={state}
          />
        </div>
      )
    })
  )

  if (visibleFields.length === 0) {
    return null
  }

  let wrapperClasses = (fieldGroup.ui && !fieldGroup.ui.wrapper === false && ' ')
    || 'grid-x grid-margin-x field-group'
  if (fieldGroup.ui && !!fieldGroup.ui.class) {
    wrapperClasses = `${wrapperClasses} ${fieldGroup.ui.class}`
  }
  return (
    <div className={wrapperClasses} id={fieldGroupId}>
      { oneColumn && <div className='cell one-column'>{getFields()}</div>}
      { !oneColumn && <React.Fragment>{getFields()}</React.Fragment>}
    </div>
  )
}

export default FieldGroup
