import { MODAL_TYPE } from '@elliemae/ds-modal'

export default function ({ aspectsData, execActionGroup }) {
  const { modal: { opts } } = aspectsData
  const { confirmCb, cancelCb } = opts
  return {
    confirmLabel: 'Save & Continue',
    modalType: MODAL_TYPE.CONFIRM,
    onConfirm: () => opts.onConfirm({ execActionGroup, confirmCb }),
    onReject: () => opts.onCancel({ execActionGroup, cancelCb }),
    onClose: () => opts.onCancel({ execActionGroup, cancelCb }),
    rejectLabel: 'Continue Without Saving',
    showHeader: opts.canClose || false,
    body: 'You currently have unsaved changes. Are you sure you want to proceed without saving?',
  }
}
