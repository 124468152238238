import React, { Component } from 'react'
import DSCheckboxGroup from '@elliemae/ds-basic/form/CheckboxGroup'
import DSCheckbox from '@elliemae/ds-basic/form/Checkbox'
import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import { getValue } from 'common/utils/field'
import Field from './Field'

class CheckboxGroup extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(value) {
    const {
      onChange,
      field,
      listInfo,
    } = this.props
    onChange({
      id: field.id,
      value,
      listInfo,
      field,
    })
  }
  render() {
    const {
      fieldActions,
      fieldData,
      managerActions,
      managerData,
      state,
      field,
      field: {
        id = '',
        disabled = false,
        label = '',
        required = false,
        orientation = 'vertical',
        checkboxes = [],
        autoDisableChilds = false,
        ui: {
          label_class: labelClass,
        },
        container_props = {},
      },
    } = this.props

    const labelProps = {
      feedbackMessage: '',
      labelText: labelClass ? <span className={labelClass}>{label}</span> : label,
      required,
    }

    if (!checkboxes.length) return null

    const activeValue = getValue(fieldData, id) || field.default
    const availableCheckboxes = checkboxes.filter(({ visible }) => visible !== false)

    return (
      <DSCheckboxGroup
        activeValue={activeValue}
        containerProps={{
          id,
          ...container_props,
        }}
        disabled={disabled}
        labelProps={labelProps}
        onChange={this.handleChange}
        orientation={orientation}
      >
        {
          availableCheckboxes.map((checkbox, index) => {
            const {
              id: checkboxId,
              label: labelText,
              value,
              disabled: disabledCheckbox,
              container_props: container_child_props = {},
            } = checkbox
            return (
              <DSCheckbox
                key={checkboxId || index}
                containerProps={{
                  id: checkboxId,
                  ...container_child_props,
                }}
                disabled={disabledCheckbox}
                labelText={labelText}
                value={value}
              >
                {checkbox.fields && checkbox.fields.map((checkboxChildField, key) => {
                  const checkboxChildFieldDisabled = autoDisableChilds && !activeValue.includes(checkbox.value)
                  const newCheckboxChildField = { ...checkboxChildField, disabled: checkboxChildFieldDisabled }
                  const innerCheckboxComponent = (
                    <Field
                      field={newCheckboxChildField}
                      fieldActions={fieldActions}
                      fieldData={fieldData}
                      managerActions={managerActions}
                      managerData={managerData}
                      state={state}
                    />
                  )
                  return (
                    <DSFormItemLayout
                      key={key}
                      inputComponent={innerCheckboxComponent}
                    />
                  )
                })}
              </DSCheckbox>
            )
          })
        }
      </DSCheckboxGroup>
    )
  }
}

export default CheckboxGroup
