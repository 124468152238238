import React from 'react'
import { isSet, getValue } from 'common/utils/field'
import { runHandlerClick } from 'common/utils/handlers'
import Label from './Label'

const TwoOption = ({
  onChange, defaultOptions, field, managerActions, fieldActions, fieldData, listInfo, managerData,
}) => {
  const handleChange = (e) => {
    const uniqueId = e.target.id
    const cancel = onChange({
      id: uniqueId.substring(0, uniqueId.length - 2),
      value: e.target.getAttribute('value'),
    })

    if (cancel !== false && field.on_click) {
      runHandlerClick(field, managerActions, fieldActions, fieldData, listInfo, managerData)
    }
  }

  const options = field.options || defaultOptions
  const value = (isSet(fieldData, field.id) ? getValue(fieldData, field.id) : field.default)

  const buttons = options.map((opt, idx) => {
    let classes = 'button hollow two-option'
    if (opt.value === value) {
      classes += ' selected'
    }

    return (
      <button
        key={idx}
        className={classes}
        disabled={field.disabled}
        id={`${field.id}-${String(idx)}`}
        onClick={handleChange}
        type='button'
        value={opt.value}
      >
        {opt.text}
      </button>
    )
  })

  const getErrors = () => {
    const f = fieldData[field.id]
    return (f && f.errors) || []
  }
  const errors = getErrors()

  if (field.ui && field.ui.layout === 'row') {
    return (
      <div className='grid-x grid-margin-x'>
        <div className='cell small-12 medium-8'>
          <Label
            field={field}
            fieldData={fieldData}
            onChange={onChange}
            type={(errors.length ? 'error' : '')}
          />
        </div>
        <div className='cell small-4 medium-4' style={{ textAlign: 'right', minWidth: '242px' }}>
          {buttons}
        </div>
      </div>
    )
  }
  return (
    <div>
      <div>
        <Label
          field={field}
          fieldData={fieldData}
          onChange={onChange}
          type={(errors.length ? 'error' : '')}
        />
      </div>
      <div>
        {buttons}
      </div>
    </div>
  )
}

export default TwoOption
