import React from 'react'
import { getFieldFullId } from 'common/utils/field'
import parseGoogleMapsAddress from 'common/utils/parseGoogleMapsAddress'
import loadGoogleMaps from 'common/utils/loadGoogleMaps'
import { handlerExists, runHandler } from 'common/utils/handlers'
import TextInput from './TextInput'

class GoogleAddress extends React.Component {
  constructor(props) {
    super()
    this.setupGoogleAddress(props)
  }
  setupGoogleAddress(props) {
    const {
      field,
      fieldActions,
      managerData,
      fieldData,
      listInfo,
      state,
    } = props

    let gPlace
    const initAddress = () => {
      const element = document.getElementById(field.id)
      if (!element) {
        return
      }
      const options = {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      }
      gPlace = new window.google.maps.places.Autocomplete(element, options)
      window.google.maps.event.addListener(gPlace, 'place_changed', () => {
        const parsedAddress = parseGoogleMapsAddress(gPlace.getPlace())
        const isStatePresent = (handlerExists('isStatePresentInStatesList')
          ? runHandler({
            handler: 'isStatePresentInStatesList',
            id: field.ui.fields.stateId,
            value: parsedAddress.state,
            managerData,
            fieldData,
            state,
          })
          : true)
        fieldActions.setField({
          id: getFieldFullId(field, field.ui.fields.line1Id),
          value: parsedAddress.line1,
          listInfo,
        })
        fieldActions.setField({
          id: getFieldFullId(field, field.ui.fields.line2Id),
          value: '',
          listInfo,
        })
        fieldActions.setField({
          id: getFieldFullId(field, field.ui.fields.cityId),
          value: parsedAddress.city,
          listInfo,
        })
        fieldActions.setField({
          id: getFieldFullId(field, field.ui.fields.stateId),
          value: isStatePresent ? parsedAddress.state : '',
          listInfo,
        })
        fieldActions.setField({
          id: getFieldFullId(field, field.ui.fields.zipId),
          value: parsedAddress.zip,
          listInfo,
        })
      })
    }
    setTimeout(() => loadGoogleMaps(initAddress, managerData.config.GOOGLE_MAPS_API_KEY))
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { field } = this.props
    if (nextProps.field.id !== field.id) {
      this.setupGoogleAddress(nextProps)
    }
  }
  render() {
    const {
      field,
      managerActions,
      fieldActions,
      managerData,
      fieldData,
      listInfo,
    } = this.props

    return (
      <TextInput
        field={field}
        fieldActions={fieldActions}
        fieldData={fieldData}
        listInfo={listInfo}
        managerActions={managerActions}
        managerData={managerData}
        onBlur={fieldActions.onBlur}
        onChange={fieldActions.setField}
        onFocus={fieldActions.onFocus}
        type='text'
      />
    )
  }
}

export default GoogleAddress
