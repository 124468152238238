import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import LoadingIndicator from 'aspectUI/loadingIndicator/LoadingIndicator'
import Modal from 'aspectUI/modal/Modal'
import NotificationToast from 'aspectUI/notificationToast/NotificationToast'
import Redirect from 'aspectUI/redirect/Redirect'

const mapStateToProps = ({
  aspects, data, manager, field,
}) => {
  return {
    aspectsData: aspects,
    appData: data,
    fieldData: field,
    managerData: manager,
  }
}

const AspectsConnector = ({ ...props }) => {
  const { aspectsData } = props
  const {
    loadingIndicator,
    modal,
    notificationToast,
    redirect,
  } = aspectsData
  return (
    <>
      <LoadingIndicator isOpen={loadingIndicator.isOpen} spinner={loadingIndicator.spinner} />
      <Modal isOpen={modal.isOpen} name={modal.name} {...props} />
      <NotificationToast
        autoClose={notificationToast.autoClose}
        id={notificationToast.id}
        messageText={notificationToast.messageText}
        messageTitle={notificationToast.messageTitle}
        position={notificationToast.position}
        type={notificationToast.type}
      />
      <Redirect
        id={redirect.id}
        run={redirect.run}
        to={redirect.to}
      />
    </>
  )
}

export default withRouter(connect(
  mapStateToProps,
  {}, // Actions (we do not have any as of now)
)(AspectsConnector))
