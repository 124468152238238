/* eslint-disable max-statements */
import { store } from 'AppEngine'

const isSequence = (name) => {
  return name === 'sequence'
}

class ActionsService {
  async execActionGroup(name, opts) {
    await this.fetchAndRunGroup(name, opts)
  }
  async fetchAndRunGroup(name, opts = {}) {
    const { data: appData, field: fieldData, manager: managerData } = store.getState()
    if (!name) {
      return
    }
    if (isSequence(name)) {
      const { router, pageName } = opts
      delete opts.router
      delete opts.pageName
      /* eslint-disable */
      for (let i in opts) {
        opts[i].pageName = pageName || undefined
        opts[i].router = router || undefined
        opts[i].opts = opts[i].opts || {}
        opts[i].opts.managerData = managerData
        opts[i].opts.fieldData = fieldData
        opts[i].opts.appData = appData
        const actionGroupGetter = await import(/* webpackChunkName: "[request]" */`actionsService/actionGroups/${opts[i].name}`)
        const actionGroup = actionGroupGetter.default(opts[i].opts)
        await this.runGroup(actionGroup)
      }
      /* eslint-enable */
    } else {
      opts.managerData = managerData
      opts.fieldData = fieldData
      opts.appData = appData
      const actionGroupGetter = await import(/* webpackChunkName: "[request]" */ `actionsService/actionGroups/${name}`)
      const actionGroup = actionGroupGetter.default(opts)
      await this.runGroup(actionGroup)
    }
  }
  async runGroup(actions) {
    /* eslint-disable */
    for (let i in actions) {
      const action = actions[i]
      const ActionClass = await import(/* webpackChunkName: "[request]" */ `./actions/${action.type}`)
      await ActionClass.default.run(action.payload)
    }
    /* eslint-enable */
  }
}

export default new ActionsService()
