import React from 'react'
import DSButton from '@elliemae/ds-basic/Button'
import { runHandlerClick } from 'common/utils/handlers'
import { getIcon } from 'common/utils/handlers/icons'
import { DSIconSizes, DSIconColors } from '@elliemae/ds-basic/Icon'

const IconButton = ({
  field, managerActions, fieldActions, fieldData, managerData, listInfo, state,
}) => {
  let clickHandler = () => {}
  if (field.on_click) {
    clickHandler = () => runHandlerClick(field, managerActions, fieldActions, fieldData, listInfo, managerData, state)
  }
  const classes = `${field.ui.class} custom-btn`

  const {
    id = '',
    container_props = {},
    disabled = false,
    ui: {
      color = '',
      icon = '',
      size = '',
    },
  } = field
  const finalColor = DSIconColors[color] || DSIconColors.NEUTRAL
  const finalSize = DSIconSizes[size] || DSIconSizes.M
  const DSIcon = getIcon(icon)

  return (
    <DSButton
      additionalcssclass={classes}
      buttonType={(field.ui && field.ui.button_type) || 'primary'}
      containerProps={{
        id,
        ...container_props,
      }}
      disabled={disabled}
      icon={DSIcon && <DSIcon color={finalColor} size={finalSize} />}
      id={field.id}
      onClick={clickHandler}
      size={finalSize}
    />
  )
}

export default IconButton
