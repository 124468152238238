/* eslint-disable import/no-cycle */
import React from 'react'
import DSInputGroup from '@elliemae/ds-basic/form/InputGroup'
import Field from './Field'

const InputGroup = ({
  field, fieldActions, fieldData, managerActions, managerData, state,
}) => {
  const props = {
    fieldActions,
    fieldData,
    managerActions,
    managerData,
    state,
  }
  return (
    <DSInputGroup
      leftAddon={field.leftAddon && (<Field field={field.leftAddon} {...props} />)}
      rightAddon={field.rightAddon && (<Field field={field.rightAddon} {...props} />)}
    >
      <Field field={field.centerField} {...props} />
    </DSInputGroup>
  )
}

export default InputGroup
