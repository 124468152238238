/* eslint-disable max-statements */
import React, { useState } from 'react'
import { callHandler } from 'common/utils/handlers'
import { getValue } from 'common/utils/field'
import DSDatePicker from '@elliemae/ds-date-picker'
import DSFormItemLayout from '@elliemae/ds-basic/form/FormItem'
import { getErrorMessages } from 'common/utils/handlers/messages'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'

const DatePicker = (props) => {
  const {
    managerActions,
    fieldActions,
    fieldData,
    managerData,
    listInfo,
    state,
    onChange,
    onBlur,
    onFocus,
    field,
    field: {
      id = '',
      handlers,
      disabled = false,
      required = false,
      value = '',
      placeholder = '',
      container_props = {},
      ui: {
        appendToBody = true,
        className = '',
        readOnly = false,
        hasError = false,
        screenReaderInputMessage = 'Select date',
        enableOutsideDays = false,
        initialVisibleMonth = moment(),
        firstDayOfWeek = 0,
        keepOpenOnDateSelect = true,
        hideKeyboardShortcutsPanel = true,
        displayFormat = 'L',
        displayFormatDay = 'D',
        transitionDuration = 0,
        verticalSpacing = 0,
        numberOfMonths = 1,
        openDirection = 'down',
      },
    },
  } = props

  const args = {
    field,
    managerActions,
    fieldActions,
    fieldData,
    listInfo,
    managerData,
    state,
  }

  const actualFieldData = fieldData[field.id] || {}
  const errors = actualFieldData.errors || []

  const onDateBlur = (newValue = '') => {
    onBlur({
      id,
      value: newValue ? newValue.format() : '',
      ...args,
    })
  }

  const onDateFocus = (event) => {
    onFocus({
      id,
      value: event.target.value,
      ...args,
    })
  }

  const onDateChange = (newValue) => {
    setDate(newValue)
    onChange({
      id,
      value: newValue.format(),
      ...args,
    })
  }

  const onPrevMonthClick = (handlers && handlers.on_prev_month_click)
    ? ({ ...params }) => callHandler('on_prev_month_click', { ...args, ...params })
    : () => null

  const onNextMonthClick = (handlers && handlers.on_next_month_click)
    ? ({ ...params }) => callHandler('on_next_month_click', { ...args, ...params })
    : () => null

  const onClose = (handlers && handlers.on_close)
    ? ({ ...params }) => callHandler('on_close', { ...args, ...params })
    : () => null

  const isDayBlocked = (handlers && handlers.is_day_blocked)
    ? ({ ...params }) => callHandler('is_day_blocked', { ...args, ...params })
    : () => false

  const isOutsideRange = (handlers && handlers.is_outside_range)
    ? ({ ...params }) => callHandler('is_outside_range', { ...args, ...params })
    : () => false

  const isDayHighlighted = (handlers && handlers.is_day_highlighted)
    ? ({ ...params }) => callHandler('is_day_highlighted', { ...args, ...params })
    : () => false

  const initialValue = (value && moment(value, 'MM/DD/YYYY').isValid()) ? moment(value) : null
  const dateValue = getValue(fieldData, id) || initialValue
  const [date, setDate] = useState(dateValue)

  const inputComponent = (
    <DSDatePicker
      appendToBody={appendToBody}
      className={className}
      date={date}
      displayFormat={displayFormat}
      displayFormatDay={displayFormatDay}
      enableOutsideDays={enableOutsideDays}
      firstDayOfWeek={firstDayOfWeek}
      hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel}
      id={id}
      initialVisibleMonth={initialVisibleMonth}
      isDayBlocked={isDayBlocked}
      isDayHighlighted={isDayHighlighted}
      isOutsideRange={isOutsideRange}
      keepOpenOnDateSelect={keepOpenOnDateSelect}
      numberOfMonths={numberOfMonths}
      onClose={onClose}
      onDateChange={onDateChange}
      onBlur={onDateBlur}
      onFocus={onFocus}
      onNextMonthClick={onNextMonthClick}
      onPrevMonthClick={onPrevMonthClick}
      openDirection={openDirection}
      placeholder={placeholder}
      screenReaderInputMessage={screenReaderInputMessage}
      transitionDuration={transitionDuration}
      verticalSpacing={verticalSpacing}
    />
  )

  return (
    <DSFormItemLayout
      disabled={disabled}
      extraInputProps={{
        containerProps: {
          id,
          ...container_props,
        },
      }}
      feedbackMessage={field.helptext}
      floatingLabel
      hasError={hasError}
      inputComponent={inputComponent}
      labelText={field.label || ''}
      onBlur={onDateBlur}
      onFocus={onDateFocus}
      readOnly={readOnly}
      required={required}
      validationMessage={getErrorMessages(field, errors)}
      value={value}
    />
  )
}

export default DatePicker
