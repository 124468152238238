import { get } from 'lodash'

const {
  emAppConfig: {
    session: {
      beforeUnload: {
        stateItemName: STATE_ITEM_NAME,
        saveState: isSaveStateOnunloadEnabled,
      },
    },
  },
} = window

let savedState = {}
if (isSaveStateOnunloadEnabled) {
  const savedStateStr = sessionStorage.getItem(STATE_ITEM_NAME)
  sessionStorage.removeItem(STATE_ITEM_NAME)
  if (savedStateStr) {
    savedState = JSON.parse(savedStateStr)
  }
}

export default (slicePath, defaultValue = {}) => (
  savedState && get(savedState, slicePath) ? get(savedState, slicePath) : defaultValue
)
