/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable max-lines */
import { some, includes } from 'lodash'
import {
  LOAD_FIELDS,
  REMOVE_LIST_ITEMS_FIELDS,
  REMOVE_LIST_ITEM_FIELDS,
  CLEAR_LIST_ITEM_FIELDS,
  CLEAR_GROUPED_FIELDS,
  SET_FIELD,
  SET_FIELDS,
  DISABLE_FIELDS,
  CLEAR_HIDDEN_FIELDS,
  CLEAR_FIELD,
  CLEAR_FIELDS,
  ENABLE_FIELDS,
  SET_FIELD_DEFINITION,
  RESET,
  FOCUS_FIELD,
  LOAD_DEFAULT_FIELDS,
  SET_NEW_FORM_FIELD_STATE,
  CLEAR_FIELD_VALUE,
  CLEAR_FIELDS_VALUES,
  UPDATE_OPTIONS,
}
  from '../constants/ActionConstants'

const DEFAULT_STATE = {}
let INITIAL_STATE = {}

const removeListItemFields = (state, action) => {
  const fields = { ...state }
  const listInfo = action.payload
  const regex = new RegExp(`-list-${listInfo.id}-index-${listInfo.index}$`)
  // Remove the fields that belong to
  // the item to remove
  Object.keys(fields).filter(fieldKey => regex.test(fieldKey)).forEach(fieldKey => delete fields[fieldKey])
  // Move the remaining items up one index
  let currIndex = listInfo.index + 1

  const moveRemainingItemIndex = (fieldKey) => {
    const tempField = fields[fieldKey]
    const newId = fieldKey.replace(/[0-9]+$/, currIndex - 1)
    tempField.id = newId
    fields[newId] = tempField
    tempField.listInfo.index -= 1
    tempField.listInfo.size -= 1
    delete fields[fieldKey]
  }

  // eslint-disable-next-line no-loops/no-loops
  while (currIndex < listInfo.size) {
    const regexIndex = new RegExp(`-list-${listInfo.id}-index-${currIndex}$`)
    Object.keys(fields).filter(fieldKey => regexIndex.test(fieldKey)).forEach(moveRemainingItemIndex)
    currIndex += 1
  }
  return fields
}

const findFieldWithinFieldGroups = (fields, fieldId) => {
  if (!fields) return
  if (fields[fieldId]) return fields[fieldId]
  let result
  if (Array.isArray(fields)) {
    fields.some(function recursive(field) {
      if (field.id === fieldId) {
        result = field
        return true
      }
      return Array.isArray(field.fields) && field.fields.some(recursive)
    })
  } else {
    const fieldsValues = Object.values(fields)
    return findFieldWithinFieldGroups(fieldsValues, fieldId)
  }
  return result
}

export default function field(state = INITIAL_STATE) {
  INITIAL_STATE = state
  return (innerState = INITIAL_STATE, action = {}) => { // eslint-disable-line max-statements
    switch (action.type) {
      case UPDATE_OPTIONS:
        const { fieldId, newOptions } = action.payload
        const updatedField = { ...innerState }
        const fieldToUpdate = findFieldWithinFieldGroups(updatedField.definitions, fieldId)
        if (fieldToUpdate) fieldToUpdate.options = newOptions
        return {
          ...innerState,
          ...updatedField,
        }
      case SET_NEW_FORM_FIELD_STATE: {
        const definitions = {}
        action.payload.fields.forEach((payloadField) => {
          definitions[payloadField.id] = payloadField
          if (payloadField && payloadField.type === 'field_group') {
            payloadField.fields.forEach((groupField) => {
              definitions[groupField.id] = groupField
            })
          }
        })
        return {
          ...innerState,
          definitions,
        }
      }
      case RESET:
        return (action.payload.length && includes(action.payload, 'field') ? { ...DEFAULT_STATE } : innerState)
      case LOAD_FIELDS:
        return {
          ...innerState,
          definitions: { ...action.payload.idCache },
        }

      // Remove the list items at the respective indexes
      case REMOVE_LIST_ITEMS_FIELDS: {
        let newState = innerState
        Object.values(action.payload).forEach((actionPayload) => {
          newState = removeListItemFields(newState, { payload: actionPayload })
        })
        return newState
      }
      // Remove the list item at the given index
      case REMOVE_LIST_ITEM_FIELDS:
        return removeListItemFields(innerState, action)

      // Clear the field values at a given index (not removing it)
      case CLEAR_LIST_ITEM_FIELDS: {
        const { id: listId, index } = action.payload.listInfo
        const { listIds } = action.payload
        return (() => {
          const fields = { ...innerState }
          Object.values(listIds[listId]).forEach((id) => {
            const itemId = `${id}-list-${listId}-index-${index}`
            if (fields[itemId]) {
              delete fields[itemId]
            }
            if (fields.fullFieldsToDefValMap[itemId]) {
              fields[itemId] = {
                id: itemId,
                value: '',
                errors: [],
                listInfo: {
                  id: listId,
                  index,
                },
              }
            }
          })
          return fields
        })()
      }
      case CLEAR_GROUPED_FIELDS: {
        const isGroupedField = (id, groupedFields) => some(groupedFields, (groupedField) => {
          if (groupedField.type === 'field_group') {
            return some(groupedField.fields, tempField => tempField.id === id)
          }
          return groupedField.id === id
        })
        return (() => {
          const fields = { ...innerState }
          const groupedFields = action.payload
          Object.keys(fields).filter(fieldKey => isGroupedField(fieldKey, groupedFields)).forEach((fieldKey) => {
            delete fields[fieldKey]
          })
          return fields
        })()
      }
      case SET_FIELDS:
        return {
          ...innerState,
          ...action.payload,
        }
      case SET_FIELD:
        innerState[action.payload.id] = {
          ...innerState[action.payload.id],
          ...action.payload,
        }
        return {
          ...innerState,
        }
      case CLEAR_HIDDEN_FIELDS:
        action.payload.forEach(id => (delete innerState[id]))
        return {
          ...innerState,
        }
      case CLEAR_FIELD:
        delete innerState[action.payload.id]
        return {
          ...innerState,
        }
      case CLEAR_FIELDS: {
        const updatedFieldState = { ...innerState }
        Object.values(action.payload).forEach(id => delete updatedFieldState[id])
        return updatedFieldState
      }
      case CLEAR_FIELD_VALUE: {
        const fieldElement = innerState[action.payload.id]
        if (fieldElement) fieldElement.value = ''
        return {
          ...innerState,
        }
      }
      case CLEAR_FIELDS_VALUES: {
        const updatedFieldState = { ...innerState }
        Object.values(action.payload).forEach(id => innerState[id].value = '')
        return updatedFieldState
      }
      case DISABLE_FIELDS:
        Object.values(action.payload).forEach(id => innerState[id] = { ...innerState[id], disabled: true })
        return { ...innerState }
      case ENABLE_FIELDS:
        Object.values(action.payload).forEach(id => innerState[id] = { ...innerState[id], disabled: false })
        return { ...innerState }
      case SET_FIELD_DEFINITION:
        innerState.definitions[action.payload.id] = action.payload
        return {
          ...innerState,
        }
      case FOCUS_FIELD:
        return {
          ...innerState,
        }
      case LOAD_DEFAULT_FIELDS: {
        const fields = {}
        Object.keys(action.payload).forEach((defaultFieldKey) => {
          fields[defaultFieldKey] = {
            id: defaultFieldKey,
            field: innerState.definitions[defaultFieldKey],
            value: action.payload[defaultFieldKey],
            errors: [],
          }
        })
        return {
          ...innerState,
          ...fields,
        }
      }
      default:
        return innerState
    }
  }
}
