import React from 'react'
import { isIframe } from 'utilityFunctions'
import DSModal from '@elliemae/ds-modal'
import ActionsService from 'actionsService/ActionsService'

// Built-in modals, let's just import directly for now
import engineErrorModal from 'internalAspects/modals/engineErrorModal'
import engineSessionExpireWarn from 'internalAspects/modals/engineSessionExpireWarn'
import engineNavPrompt from 'internalAspects/modals/engineNavPrompt'

const internal = {
  engineErrorModal,
  engineSessionExpireWarn,
  engineNavPrompt,
}

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loadedModals: {} }
  }
  componentDidUpdate() {
    const { isOpen, name } = this.props
    const { loadedModals } = this.state
    if (isOpen && internal[name] && !loadedModals[name]) {
      const modalConfig = internal[name]
      const newState = {
        loadedModals: {},
      }
      newState.loadedModals[name] = modalConfig
      setTimeout(() => this.setState(newState))
    } else if (isOpen && name && !loadedModals[name]) {
      import(/* webpackChunkName: "[request]" */ `customUI/aspects/modal/${name}`).then((modalConfig) => {
        const newState = {
          loadedModals: {},
        }
        newState.loadedModals[name] = modalConfig.default
        this.setState(newState)
      })
    }
  }
  execActionGroup(name, opts) {
    const { opts: inOpts } = this.props
    ActionsService.fetchAndRunGroup(name, { ...inOpts, ...opts })
  }
  render() {
    const { name, isOpen } = this.props
    const { loadedModals } = this.state
    if (!isOpen || !loadedModals[name]) {
      return null
    }
    const modal = loadedModals[name]({
      execActionGroup: (name1, opts) => { this.execActionGroup(name1, opts) },
      ...this.props,
    })

    const rest = modal
    const { body } = modal
    return (
      <DSModal
        centered={!isIframe()} // Purposeful hack for iframe usage
        isOpen={isOpen}
        {...rest}
      >
        {body}
      </DSModal>
    )
  }
}

export default Modal
