import SessionService from 'sessionService/SessionService'

export default function ({ execActionGroup }) {
  return {
    confirmLabel: 'Logout',
    rejectLabel: 'Reset',
    modalType: 'confirm',
    size: 'medium',
    // onAfterOpen: () => 'onAfterOpen',
    onConfirm: () => SessionService.redirectToLogout(),
    onReject: () => {
      SessionService.resetActivityState()
      execActionGroup('closeModal')
    },
    onClose: () => {
      SessionService.resetActivityState()
      execActionGroup('closeModal')
    },
    modalTitle: 'Your session is about to expire',
    body: 'Do you want to stay logged in?',
  }
}
