/* eslint-disable no-restricted-globals*/
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
// import 'customUI/node_modules/react-dates/initialize'
// import 'customUI/node_modules/react-dates/lib/css/_datepicker.css' // eslint-disable-line
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { getDefaultTheme } from '@elliemae/pui-theme'
import App from 'app/App'
import SessionService from 'sessionService/SessionService'
import configureStore from './config/CreateStore'

export const store = configureStore()

SessionService.attachWindowUnloadEvent()
SessionService.startInactivityState()

const { globalShell } = window.emCustomAppConfig || {}

function renderApp(element) {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={getDefaultTheme()}>
        <App />
      </ThemeProvider>
    </Provider>,
    element,
  )
}

function localMount() {
  renderApp(document.getElementById('root') || document.createElement('div'))
}

function GlobalShellMount() {
  const { appName } = globalShell

  const appOptions = {
    appId: appName,
    elementTagName: 'div',
    appDOMElement: null,
  }

  function changeUrl() {
    const url = `${location.origin}`
    history.pushState({}, null, url)
  }

  /* eslint-disable camelcase,no-undef */
  function initApp(args) {
    const { hostUrl, localAppState } = args
    window.emCustomAppConfig.appStateData = localAppState
    __webpack_public_path__ = hostUrl
      ? new URL(__webpack_public_path__, hostUrl).href
      : __webpack_public_path__
    __webpack_public_path__ = __webpack_public_path__.replace(/\/?$/, '/')
    changeUrl()
  }

  function createDOMElements(containerId) {
    const { appDOMElement, elementTagName, appId } = appOptions
    // crate the application DOM element and attach to the host container
    let appEle = appDOMElement
    if (!appEle) {
      appEle = document.createElement(elementTagName)
      appEle.id = appId
    }
    const containerEle = document.getElementById(containerId)
    if (!containerEle) {
      return null
    }
    containerEle.appendChild(appEle)
    return appEle
  }

  function unmountApp() {
    const appEle = document.getElementById(appOptions.appId)
    if (appEle) ReactDOM.unmountComponentAtNode(appEle)

    const { appStateData } = window.emCustomAppConfig
    if (appStateData) {
      try {
        return Promise.resolve(JSON.parse(JSON.stringify(appStateData)))
      } catch (e) {
        return Promise.reject(e)
      }
    }
    return Promise.resolve()
  }

  function mountApp({ containerId }) {
    const appEle = createDOMElements(containerId)
    renderApp(appEle)
    return Promise.resolve()
  }

  // eslint-disable-next-line no-multi-assign
  const emui = window.top.emui = window.top.emui || {}

  emui[appName] = emui[appName] || {}
  emui[appName].init = initApp.bind(this)
  emui[appName].mount = mountApp.bind(this)
  emui[appName].unmount = unmountApp.bind(this)
}

if (globalShell && globalShell.enabled) {
  GlobalShellMount()
} else {
  localMount()
}
