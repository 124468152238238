export const aspectActions = {
  ASPECT_SET: 'ASPECT_SET',
}

const initialState = {
  loadingIndicator: {
    isOpen: false,
  },
  notificationToast: {
    isOpen: false,
  },
  modal: {
    isOpen: false,
  },
  redirect: {
    run: false,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case aspectActions.ASPECT_SET:
      return {
        ...state,
        ...action.payload,
        loadingIndicator: {
          ...state.loadingIndicator,
          ...action.payload.loadingIndicator,
        },
      }
    default:
      return state
  }
}
