(() => {
  const emAppConfig = {
    appName: null,
    appInitActionGroup: null,
    theme: {
      name: 'em',
      config: null,
    },
    menu: null, // menu OR routes
    routes: null,
    apis: {
      introspectToken: {
        url: '/oauth2/v1/token/introspection',
        opts: {
          fetchErrorActionGroup: {
            name: 'logger',
            opts: {
              type: 'error',
              message: 'Introspection call failed. Network error.',
            },
          },
          codeErrorActionGroup: {
            name: 'logger',
            opts: {
              type: 'error',
              message: 'Introspection call failed. Code error.',
            },
          },
          useAuthToken: false,
          origin: window.emCustomAppConfig.authentication.authOrigin,
        },
        fetchInit: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            accept: 'application/json',
          },
        },
      },
      getOpenAPIToken: {
        url: '/oauth2/v1/token',
        opts: {
          useAuthToken: false,
          origin: window.emCustomAppConfig.authentication.authOrigin,
        },
        fetchInit: {
          method: 'POST',
          body: window.emCustomAppConfig.authentication.grantAndScope,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            accept: 'application/json',
          },
        },
      },
      invalidateToken: {
        url: '/oauth2/v1/token/revocation',
        opts: {
          fetchErrorActionGroup: {
            name: 'logger',
            opts: {
              type: 'error',
              message: 'Invalidation call failed. Network error.',
            },
          },
          codeErrorActionGroup: {
            name: 'logger',
            opts: {
              type: 'error',
              message: 'Invalidation call failed. Code error.',
            },
          },
          origin: window.emCustomAppConfig.authentication.authOrigin,
        },
        fetchInit: {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      },
    },
    session: {
      beforeUnload: {
        saveState: true,
        stateItemName: 'APP-ENGINE-STATE',
        slicesToSave: {
          data: true,
          field: true,
          manager: true,
        },
      },
      timeout: {
        isEnabled: true,
        secondsOk: 600,
        secondsWarn: 60,
      },
      tokenRefreshInterval: 600,
    },
    pages: null,
    aspects: {
      loadingIndicator: {
        label: 'please wait...',
      },
      modals: null,
      nodificationToasts: null,
    },
  }
  window.emAppConfig = {
    ...emAppConfig,
    ...window.emCustomAppConfig,
  }

  // Special merges
  window.emAppConfig.apis = {
    ...emAppConfig.apis,
    ...window.emCustomAppConfig.apis,
  }
})()
