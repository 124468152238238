/* eslint-disable camelcase */
import React from 'react'
import DSTimePicker from '@elliemae/ds-basic/TimePicker'
import { callHandler } from 'common/utils/handlers'

const TimePicker = ({
  field: {
    id = '',
    container_props = {},
    isOpen = true,
    format = 'hh:mm A',
    value = null,
    handlers,
  },
  field,
  listInfo,
  managerActions,
  fieldActions,
  fieldData,
  managerData,
  state,
  onChange = () => {},
}) => {
  const args = {
    field,
    managerActions,
    fieldActions,
    fieldData,
    listInfo,
    managerData,
    state,
  }

  const handleChange = (newTime) => {
    onChange({
      id: field.id,
      value: newTime,
      listInfo,
      field,
    })
  }

  const onOpenHandler = (handlers && handlers.on_open)
    ? () => callHandler('on_open', { ...args })
    : () => {}

  return (
    <DSTimePicker
      containerProps={{
        id,
        ...container_props,
      }}
      format={format}
      isOpen={isOpen}
      onChange={handleChange}
      onOpen={onOpenHandler}
      value={value}
    />
  )
}

export default TimePicker
